import * as CANNON from 'cannon';
import * as THREE from 'three';
import {emitter, EVENTS} from '../utils/Dispatcher';
import {Globals} from '../utils/Globals';
import Time from '../utils/Time';
import {gsap} from 'gsap';
import ForceFieldAlpha from '../../assets/images/force-field-greyscale.jpg';
import ForceField from '../../assets/images/force-field.jpg';

class Physics {
	public world;
	private ground: any;
	public ground_ufo_cm;
	public groundMaterial: CANNON.Material;
	private solver: CANNON.GSSolver;
	private scene: THREE.Scene;
	public ufoMaterial: CANNON.Material;
	public staticMaterial: CANNON.Material;
	public cloudMaterial: CANNON.Material;
	private ufo_static_cm: CANNON.ContactMaterial;
	// private ufo_cloud_cm: CANNON.ContactMaterial;
	public showFrame: boolean;
	// private cloud_cloud_cm: CANNON.ContactMaterial;
	private time: Time;
	private position = new CANNON.Vec3();
	private ground_assets_cm: CANNON.ContactMaterial;
	public assetsMaterial: CANNON.Material;
	private shadowTexture: THREE.Texture;
	private uniforms;
	private forceMaterial: THREE.ShaderMaterial;
	private forceAlphaTexture: THREE.Texture;
	private forceTexture: THREE.Texture;

	constructor(scene: THREE.Scene) {
		this.scene = scene;
		this.time = new Time();
		this.showFrame = Globals.SHOW_PHYSICS;

		this.setWorld();
		this.setFloor();
		this.createBounds();

		var loader = new THREE.TextureLoader();
		let textures = [ForceField, ForceFieldAlpha];
		let promises = [];

		textures.forEach(texture => {
			promises.push(
				new Promise((resolve, reject) => {
					loader.load(texture, texture => {
						resolve(texture);
					});
				})
			);
		});

		Promise.all(promises).then(textures => {
			this.createWallCollisionVisual(textures);
		});

		this.bindEvents();

		this.raf();
	}

	private bindEvents() {
		emitter.on(EVENTS.tick, this.raf);
	}

	private setWorld() {
		this.world = new CANNON.World();
		this.world.gravity.set(0, -9.82, 0);
		this.world.broadphase = new CANNON.NaiveBroadphase();
		this.solver = new CANNON.GSSolver();
		this.solver.iterations = 7;
		this.solver.tolerance = 0.1;

		this.world.defaultContactMaterial.friction = 0.5;
		this.world.defaultContactMaterial.restitution = 0;

		this.world.solver = new CANNON.SplitSolver(this.solver);

		this.groundMaterial = new CANNON.Material('groundMaterial');
		this.ufoMaterial = new CANNON.Material('ufoMaterial');
		this.staticMaterial = new CANNON.Material('staticMaterial');
		// this.cloudMaterial = new CANNON.Material('cloudMaterial');
		this.assetsMaterial = new CANNON.Material('assetsMaterial');

		this.ground_ufo_cm = new CANNON.ContactMaterial(this.groundMaterial, this.ufoMaterial, {
			friction: 0,
			restitution: 0,
			contactEquationStiffness: 1e8,
			contactEquationRelaxation: 3,
			frictionEquationStiffness: 1e8
		});

		this.ufo_static_cm = new CANNON.ContactMaterial(this.ufoMaterial, this.staticMaterial, {
			friction: 1,
			restitution: 0
		});

		/*		this.ufo_cloud_cm = new CANNON.ContactMaterial(this.ufoMaterial, this.cloudMaterial, {
			friction: 0,
			restitution: 1
		});

		this.cloud_cloud_cm = new CANNON.ContactMaterial(this.cloudMaterial, this.cloudMaterial, {
			friction: 0.5,
			restitution: 4
		});*/

		this.ground_assets_cm = new CANNON.ContactMaterial(this.groundMaterial, this.assetsMaterial, {
			friction: 0,
			restitution: 0,
			contactEquationStiffness: 1e8,
			contactEquationRelaxation: 3,
			frictionEquationStiffness: 1e8
		});

		this.world.addContactMaterial(this.ground_ufo_cm);
		this.world.addContactMaterial(this.ufo_static_cm);
		// this.world.addContactMaterial(this.ufo_cloud_cm);
		// this.world.addContactMaterial(this.cloud_cloud_cm);
		this.world.addContactMaterial(this.ground_assets_cm);
	}

	private setFloor() {
		this.ground = new CANNON.Body({
			collisionFilterGroup: 1,
			mass: 0,
			shape: new CANNON.Plane(),
			material: this.groundMaterial
		});
		this.ground.quaternion.setFromAxisAngle(new CANNON.Vec3(1, 0, 0), -Math.PI / 2);
		(this.ground as any).name = 'ground';

		this.world.addBody(this.ground);
	}

	bounds = [];

	private createBounds() {
		const walls = [
			{
				sizes: {x: 230, y: 15, z: 2},
				// position: {x: 93.32, y: 0, z: 64.22},
				position: {x: 43.22, y: 0, z: 52.04},
				rotation: 1.01
			},
			{
				sizes: {x: 65, y: 15, z: 2},
				position: {x: 3.32, y: 0, z: 98.22},
				rotation: 0.08
			},
			{
				sizes: {x: 100, y: 15, z: 2},
				position: {x: -16.88, y: 0, z: 83.18},
				rotation: -0.76
			},
			{
				sizes: {x: 220, y: 15, z: 2},
				position: {x: -47.06, y: 0, z: 16.32},
				rotation: -1.33
			},
			{
				sizes: {x: 180, y: 15, z: 2},
				position: {x: -21.1, y: 0, z: -57.82},
				rotation: 0.53
			},
			{
				sizes: {x: 128, y: 15, z: 2},
				position: {x: 68.46, y: 0, z: -23.36},
				rotation: -1.38
			},
			{
				sizes: {x: 128, y: 15, z: 2},
				position: {x: 44.24, y: 0, z: -63.46},
				rotation: -0.54
			}
		];
		walls.forEach((wall, i) => {
			const wallPhysics = new CANNON.Body({
				mass: 0,
				shape: new CANNON.Box(new CANNON.Vec3(wall.sizes.x * 0.5 * 0.5, wall.sizes.y * 0.5, wall.sizes.z * 0.5)),
				material: this.staticMaterial
			});

			wallPhysics.allowSleep = true;
			wallPhysics.sleep();

			// @ts-ignore
			wallPhysics.name = 'wall';

			const wallVisual = new THREE.Mesh(
				new THREE.BoxGeometry(wall.sizes.x * 0.5, wall.sizes.y, wall.sizes.z),
				new THREE.MeshNormalMaterial({
					wireframe: true,
					visible: this.showFrame
				})
			);

			wallVisual.name = 'wall' + i;

			this.position.set(wall.position.x, wall.position.y + wall.sizes.y * 0.5, wall.position.z);

			wallPhysics.position.copy(this.position);
			wallPhysics.quaternion.setFromAxisAngle(new CANNON.Vec3(0, 1, 0), wall.rotation);

			wallVisual.position.copy(wallPhysics.position as any);
			wallVisual.quaternion.copy(wallPhysics.quaternion as any);

			wallPhysics.addEventListener('collide', this.onCollide);

			this.bounds.push({
				visual: wallVisual,
				physics: wallPhysics
			});

			this.scene.add(wallVisual);
			this.world.addBody(wallPhysics);
		});
	}

	private objects = [];

	private hintGroup: THREE.Group;
	private hitHint: THREE.Mesh;

	private createWallCollisionVisual = textures => {
		this.forceTexture = textures[0];
		this.forceAlphaTexture = textures[1];
		const amount = 10;

		// create a texture on canvas and apply it on material
		var canvas = document.createElement('canvas');
		canvas.style.position = 'relative';
		canvas.width = 200;
		canvas.height = 200;
		var ctx = canvas.getContext('2d');

		var x = 100,
			y = 100,
			innerRadius = 0,
			outerRadius = 200,
			radius = 200;

		var gradient = ctx.createRadialGradient(x, y, innerRadius, x, y, outerRadius);
		gradient.addColorStop(0, '#ADD8E6');
		gradient.addColorStop(0.2, '#def1f7');
		gradient.addColorStop(0.4, '#ADD8E6');
		gradient.addColorStop(0.6, '#def1f7');
		gradient.addColorStop(0.8, '#ADD8E6');
		gradient.addColorStop(1, '#def1f7');
		// document.body.appendChild(canvas)

		ctx.arc(x, y, radius, 0, 2 * Math.PI);

		ctx.fillStyle = gradient;
		ctx.fill();

		var shadowTexture = new THREE.Texture(canvas);
		this.shadowTexture = shadowTexture;

		shadowTexture.wrapS = shadowTexture.wrapT = THREE.RepeatWrapping;
		shadowTexture.minFilter = THREE.LinearFilter;
		shadowTexture.premultiplyAlpha = true;

		// this.forceAlphaTexture.wrapS = texture.wrapT = THREE.RepeatWrapping;
		// this.forceAlphaTexture.minFilter = THREE.NearestFilter;
		// this.forceAlphaTexture.needsUpdate = true

		const vertexShader = `
			varying vec2 vUv;

			void main() {
				vUv = uv;

				vec4 mvPosition = modelViewMatrix * vec4( position, 1.0 );
				gl_Position = projectionMatrix * mvPosition;
			}
		`;

		const fragmentShader = `
			uniform sampler2D u_texture;
			uniform sampler2D u_textureAlpha;
			varying vec2 vUv;
			uniform float globalAlpha;

			void main() {
				vec2 uvs = vUv;
				vec4 beam = texture2D( u_texture, uvs );
				float alpha = texture2D( u_textureAlpha, vUv).r;
				gl_FragColor = vec4(beam.rgb, alpha);
			}
		`;

		this.uniforms = {
			u_texture: {type: 't', value: shadowTexture},
			u_textureAlpha: {type: 't', value: this.forceAlphaTexture},
			globalAlpha: {type: 'f', value: 1}
		};

		const geometry = new THREE.CylinderBufferGeometry(1.5, 1.5, 0.01, 64);
		// const geometry = new THREE.CircleGeometry(1.5, 32);
		const material = new THREE.MeshBasicMaterial({
			transparent: true,
			opacity: 0,
			color: 0xdef1f7,
			visible: false,
			map: this.forceTexture,
			alphaMap: this.forceAlphaTexture,
			side: THREE.DoubleSide,
			blending: THREE.NormalBlending
		});

		// this.forceMaterial = new THREE.ShaderMaterial({
		// 	// @ts-ignore
		// 	uniforms: this.uniforms,
		// 	fragmentShader,
		// 	vertexShader,
		// 	transparent: true,
		// 	side: THREE.DoubleSide,
		// 	depthWrite: true
		// });

		this.hintGroup = new THREE.Group();
		this.hitHint = new THREE.Mesh(geometry, material);
		this.hintGroup.name = 'hint';

		for (let i = 0; i < 10; i++) {
			const axis = new THREE.Group();
			const meshCopy = this.hitHint.clone();
			const materialCopy = this.hitHint.material.clone();
			meshCopy.material = materialCopy;
			meshCopy.rotation.x = Math.PI / 2;

			axis.add(meshCopy);

			this.hintGroup.add(axis);
		}

		// this.hintGroup.position.set(23.66, 1.5, -17.38)
		this.scene.add(this.hintGroup);
	};

	currentHint = 0;

	private onCollide = e => {
		if (e.body.name !== 'ufo') return;

		Globals.AUDIO_ENGINE.triggerCollision();

		const ufoPosition = e.body.position;
		const ri = e.contact.ri;

		const hinPosition = ufoPosition.vadd(ri);
		const target = this.bounds.find(w => w.physics === e.target);

		const index = this.currentHint % this.hintGroup.children.length;
		const hintAxis = this.hintGroup.children[index];
		const hint = hintAxis.children[0] as THREE.Mesh;

		hintAxis.position.set(hinPosition.x + 0.05 * index, 6, hinPosition.z + 0.05 * index);
		hintAxis.rotation.y = target.visual.rotation.y;

		let material = hint.material as THREE.ShaderMaterial;
		gsap.set(material, {opacity: 0.8});
		material.visible = true;
		gsap.set(hint.scale, {x: 1, z: 1});

		gsap.to(hint.material, {
			opacity: 0,
			ease: 'sine.in',
			duration: 0.4,
			onComplete: () => {
				material.visible = false;
			}
		});
		const scale = 2.5;
		gsap.to(hint.scale, {
			x: scale,
			z: scale,
			ease: 'sine.out',
			duration: 0.3
		});

		this.currentHint++;
	};

	public registerObject = (visual, physics, offset = {x: 0, y: 0, z: 0}) => {
		this.objects.push({
			visual,
			physics,
			offset
		});
	};

	public getBounds(object: THREE.Mesh) {
		const box = new THREE.Box3();
		object.geometry.computeBoundingBox();
		box.copy(object.geometry.boundingBox);
		object.updateMatrixWorld(true);
		box.applyMatrix4(object.matrixWorld);

		const sizes = {
			x: box.max.x - box.min.x,
			y: box.max.y - box.min.y,
			z: box.max.z - box.min.z
		};

		return sizes;
	}

	public syncVisualAndPhysics(visual, physics) {
		if (Array.isArray(visual)) {
			visual.forEach(object => {
				object.position.copy(physics.position);
				object.quaternion.copy(physics.quaternion as any);
			});
		} else {
			visual.position.copy(physics.position);
			visual.quaternion.copy(physics.quaternion as any);
		}
	}

	objectOffset = new CANNON.Vec3();

	private timestep = 1 / 60;
	private raf = () => {
		// this.world.step(this.timestep, this.time.delta / 1000, 7);
		//@todo: figure out a proper way to compensate for lower than 60 fps, for example ios low batteyr mode:
		//https://github.com/react-spring/cannon-es/issues/16
		this.world.step(this.timestep, undefined, 10);

		/*		this.bounds.forEach(bound => {
			bound.visual.position.copy(bound.physics.position);
			bound.visual.quaternion.copy(bound.physics.quaternion as any);
		});*/

		// if(this.forceMaterial) {
		// 	this.forceMaterial.needsUpdate = true
		// 	this.forceAlphaTexture.needsUpdate = true
		// 	this.shadowTexture.needsUpdate = true
		// }

		this.objects.forEach(object => {
			this.objectOffset.set(object.offset.x, object.offset.y, object.offset.z);
			object.visual.position.copy(object.physics.position.vadd(this.objectOffset));
			object.visual.quaternion.copy(object.physics.quaternion as any);
		});
	};
}

export default Physics;
