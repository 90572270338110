import {Globals} from '../../../utils/Globals';
import Sizes from '../../../utils/Sizes';
import {BurgerButton} from '../../BurgerButton';
import {gsap} from 'gsap';
import {TweenMax, Power0} from 'gsap';

import './Navigation.scss';
import {emitter, EVENTS} from '../../../utils/Dispatcher';

class Navigation {
	static SELECTORS = {
		LAYER: '.opaque-layer',
		NAVIGATION: '.navigation',
		NAVIGATION_MAIN: '.navigation-main',
		NAVIGATION_MODULE: '.navigation-module',
		NAVIGATION_MODULE_WRAPPER: '.navigation-module__wrapper',
		NAVIGATION_ITEM: '.navigation-menu__item',
		NAVIGATION_TITLE: '.title',
		NAVIGATION_SIDEBAR: '.navigation-side-bar a',
		BURGER: '.burger-icon',
		BURGER_BARS: '.burger-icon__bar',
		LOGO: '.logo',
		HEADER: '.header'
	};

	// Dom variables
	private element_: HTMLElement;
	private app_;
	private layer_;
	private navigationMain_;
	private navigationModule_;
	private navigationModuleWrapper_;
	private navigationItems_;
	private navigationTitle_;
	private navigationSidebar_;
	private DOMBurger: Element;
	private DOMLogo: Element;
	private isOpened = false;
	private navigation_: any;
	private sizes: Sizes;
	private menuTimelineIn = null;
	private menuTimelineOut = null;
	private DOMNavigationInner: Element;
	private navigationModuleRect: any;
	private burgerButton: BurgerButton;
	private DOMHeader: Element;

	constructor(app) {
		const {BURGER, HEADER, LOGO, LAYER, NAVIGATION_MAIN, NAVIGATION, NAVIGATION_MODULE, NAVIGATION_MODULE_WRAPPER, NAVIGATION_ITEM, NAVIGATION_TITLE, NAVIGATION_SIDEBAR} = Navigation.SELECTORS;

		// Dom
		this.element_ = document.getElementById('MainNavigation');
		this.app_ = app;
		this.layer_ = this.element_.querySelector(LAYER);
		this.navigation_ = this.element_.querySelector(NAVIGATION);
		this.navigationMain_ = this.element_.querySelector(NAVIGATION_MAIN);
		this.navigationModule_ = this.element_.querySelector(NAVIGATION_MODULE);
		this.navigationModuleWrapper_ = this.element_.querySelector(NAVIGATION_MODULE_WRAPPER);
		this.navigationItems_ = this.element_.querySelectorAll(NAVIGATION_ITEM);
		this.navigationTitle_ = this.element_.querySelector(NAVIGATION_TITLE);
		this.navigationSidebar_ = this.element_.querySelectorAll(NAVIGATION_SIDEBAR);
		this.DOMBurger = this.element_.querySelector(BURGER);
		this.DOMHeader = this.element_.querySelector(HEADER);

		this.DOMLogo = this.element_.querySelector(LOGO);
		this.DOMNavigationInner = this.element_.querySelector('.navigation__inner');
		this.sizes = new Sizes();

		this.burgerButton = new BurgerButton(this.DOMBurger, this.open, this.close);

		this.bindEvents();
		this.resize();

		this.setState();
	}

	public animateIn = () => {
		gsap.set(this.DOMBurger, {autoAlpha: 1});
	};

	bindEvents() {
		this.layer_.addEventListener('click', this.onLayerClick);
		emitter.on(EVENTS.fullScreen, this.hide);
		emitter.on(EVENTS.resize, this.resize);
	}

	setState = () => {
		gsap.set(this.layer_, {opacity: 0});
		gsap.set(this.navigationMain_, {x: '100%'});
		gsap.set(this.navigationModule_, {x: this.navigationModuleRect.width + this.sizes.viewport.width * 0.5});
		gsap.set(this.navigationTitle_, {opacity: 0});
		gsap.set(this.navigationItems_, {x: 40, opacity: 0});
		gsap.set(this.navigationSidebar_, {y: this.sizes.isMobile ? 0 : -32, opacity: 0});
		gsap.set(this.navigationModuleWrapper_, {opacity: 0});
	};

	navigationIn = () => {
		if (this.menuTimelineOut) {
			this.menuTimelineOut.kill();
		}

		this.menuTimelineIn = gsap.timeline();
		this.menuTimelineIn.set(this.element_.querySelector('.navigation'), {autoAlpha: 1});
		this.menuTimelineIn.to(this.layer_, 0.6, {opacity: 0.8, ease: 'power0.none'}, 0);
		this.menuTimelineIn.to(this.navigationMain_, 0.6, {x: '0%', force3D: true, ease: 'power3.inOut'}, 0);
		// White header background on mobile
		if (this.sizes.isMobile) {
			this.menuTimelineIn.to(this.DOMHeader, {background: '#fff', duration: 0.2, ease: 'power0.none'}, 0);
		}
		this.menuTimelineIn.to(this.navigationModule_, 0.6, {x: '0%', opacity: 1, force3D: true, ease: 'power3.inOut'}, 0);
		this.menuTimelineIn.to(this.navigationTitle_, 1, {opacity: 1}, 0.4);
		this.menuTimelineIn.to(this.navigationItems_, 0.7, {opacity: 1, force3D: true, stagger: 0.08, ease: 'power0.none'}, 0.4);
		this.menuTimelineIn.to(this.navigationItems_, 1, {x: 0, force3D: true, stagger: 0.08, ease: 'power3.out'}, 0.4);
		this.menuTimelineIn.to(this.navigationSidebar_, 0.8, {y: 0, force3D: true, ease: 'power3.out'}, 0.6);
		this.menuTimelineIn.to(this.navigationSidebar_, 0.8, {opacity: 1, ease: 'power0.none'}, 0.6);
		this.menuTimelineIn.to(this.navigationModuleWrapper_, 0.8, {opacity: 1, ease: 'power3.inOut'}, 0.6);
		// White background on mobile to compensate elastic scroll
		if (this.sizes.isMobile) {
			this.menuTimelineIn.set(this.DOMNavigationInner, {backgroundColor: '#fff'});
		}

		this.menuTimelineIn.timeScale(this.sizes.isMobile ? 1.5 : 1.2);
	};

	navigationOut = () => {
		if (this.menuTimelineIn) {
			this.menuTimelineIn.kill();
		}
		this.menuTimelineOut = gsap.timeline();
		if (this.sizes.isMobile) {
			this.menuTimelineOut.set(this.DOMNavigationInner, {backgroundColor: 'transparent'}, 0);
		}
		this.menuTimelineOut.to(this.navigationItems_, 1, {x: 100, force3D: true, stagger: 0, ease: 'power3.out'}, 0);
		this.menuTimelineOut.to(this.navigationItems_, 0.8, {opacity: 0, force3D: true, stagger: 0, ease: 'power0.none'}, 0);
		this.menuTimelineOut.to(this.navigationModuleWrapper_, 0.8, {opacity: 0, ease: 'power3.out'}, 0);
		this.menuTimelineOut.to(this.navigationSidebar_, 0.8, {y: this.sizes.isMobile ? 0 : -32, opacity: 0, force3D: true, ease: 'power3.out'}, 0);
		this.menuTimelineOut.to(this.navigationTitle_, 1, {opacity: 0}, 0.4);
		this.menuTimelineOut.to(this.navigationModule_, 0.4, {opacity: 0, force3D: true, ease: 'power0.none'}, 0);
		this.menuTimelineOut.to(this.navigationModule_, 0.6, {x: this.navigationModuleRect.width + this.sizes.viewport.width * 0.5, force3D: true, ease: 'power3.inOut'}, 0);
		if (this.sizes.isMobile) {
			this.menuTimelineOut.to(this.DOMHeader, {backgroundColor: 'transparent', duration: 0.2, ease: 'power0.none'}, 0.5);
		}
		this.menuTimelineOut.to(this.navigationMain_, 0.6, {x: '100%', force3D: true, ease: 'power3.inOut'}, 0);
		this.menuTimelineOut.to(this.layer_, 0.6, {opacity: 0, ease: 'power0.none'}, 0);
		this.menuTimelineOut.set(this.element_.querySelector('.navigation'), {autoAlpha: 0});
	};

	hide = toggled => {
		TweenMax.to(this.DOMBurger, 0.3, {autoAlpha: toggled.value ? 0 : 1, ease: Power0.easeNone});
	};

	onLayerClick = () => {
		if (!this.isOpened) return;
		this.burgerButton.close();
		this.close();
	};

	open = () => {
		Globals.DISABLE_CONTROLS = true;
		this.DOMHeader.classList.add('menu-is-open');
		this.isOpened = true;
		this.navigationIn();
	};

	close = () => {
		Globals.DISABLE_CONTROLS = false;
		this.DOMHeader.classList.remove('menu-is-open');
		this.isOpened = false;
		this.navigationOut();
	};

	resize = () => {
		this.navigationModuleRect = this.navigationModule_.getBoundingClientRect();
	};
}

export default Navigation;
