import * as THREE from 'three';
import {WebGLRendererParameters} from 'three/src/renderers/WebGLRenderer';
import Sizes from '../utils/Sizes';
import {gsap} from 'gsap';
import {Globals} from '../utils/Globals';

export class WorldRenderer extends THREE.WebGLRenderer {
	private sizes: Sizes;

	constructor(parameters?: WebGLRendererParameters) {
		super(parameters);

		this.sizes = new Sizes();

		this.setSize(this.sizes.viewport.width, this.sizes.viewport.height);
		this.updatePixelRatio();
		this.setClearColor(new THREE.Color(0xdcbeb7));
		this.outputEncoding = THREE.sRGBEncoding;
	}

	public updateColor = (color: THREE.Color) => {
		this.setClearColor(color);
	};

	private updatePixelRatio() {
		const limitPixelRatio = !this.sizes.isLargeDesktop || Globals.I_OS ? gsap.utils.clamp(1, 3, window.devicePixelRatio) : 1;
		const pixelRatio = gsap.utils.clamp(1, limitPixelRatio, window.devicePixelRatio);

		this.setPixelRatio(pixelRatio);
	}

	public resize() {
		this.updatePixelRatio();
		this.setSize(this.sizes.viewport.width, this.sizes.viewport.height);
	}
}
