import * as THREE from 'three';
import {Vector3} from 'three';
import {SituationLayers} from '../views/components/SituationLayers/SituationLayers';
import {Situation} from './Situation';
import Resources from '../Resources';
import {Globals} from '../utils/Globals';
import Sizes from '../utils/Sizes';
import {emitter, EVENTS} from '../utils/Dispatcher';

export interface ISituationConfig {
	slug: string;
	name: string;
	icon: string;
	position: Vector3;
	centerPosition: Vector3;
	currCameraTargetOffset: Vector3;
	cameraTargetOffset: Vector3;
	cameraTargetOffsetPortrait: Vector3;
	loopHeight: number;
	greetingGender: string;
	greetingDelay: number;
	scale: number;
	angle: number;
	voiceSamples: Array<string>;
	pitch: number;
	rotationY: number;
	plane: string;
	distance?: number;
	toModify?: {name: string; properties: {name: string; type: string; value: any}[]}[];
	color: THREE.Color;
	sceneObject: Situation;
	themeColor: string;
}

export class SituationManager {
	private resources: Resources;
	public group: THREE.Group;
	public zones: THREE.Group;
	public situations: ISituationConfig[];
	private app: HTMLElement;
	private layers: SituationLayers;

	private DEBUG_ZONES = Globals.DEBUG_ZONES;
	public situationDistancesToUfo: ISituationConfig[] = [];
	private distanceCalcVector = new THREE.Vector3();
	private debug: any;
	private sizes: Sizes;

	constructor(app: HTMLElement, debug) {
		this.app = app;
		this.debug = debug;
		this.sizes = new Sizes();
		this.group = new THREE.Group();
		this.group.name = 'Situations';
		this.zones = new THREE.Group();
		this.layers = new SituationLayers(this.app);
	}

	init(resources: Resources) {
		this.resources = resources;

		const offset = 85;

		this.situations = [
			{
				slug: 'toget',
				icon: 'icon_video',
				name: 'busstation' /*
				position: new THREE.Vector3(-23.65, 0, 16.38),
				centerPosition: new THREE.Vector3(-23.65, 0, 16.38),*/,
				position: new THREE.Vector3(-5.27, 0, 8.74),
				centerPosition: new THREE.Vector3(-9.01, 0, 6.86),
				currCameraTargetOffset: new THREE.Vector3(-8, 4, 5),
				cameraTargetOffset: new THREE.Vector3(-8, 4, 5),
				cameraTargetOffsetPortrait: new THREE.Vector3(-5, -5, 1),
				scale: 1,
				angle: null,
				voiceSamples: ['01', '02', '03'],
				pitch: 1.05,
				greetingGender: 'female',
				greetingDelay: 0.6,
				loopHeight: 5.7,
				rotationY: -2.05,
				plane: 'shadow',
				color: new THREE.Color(0xf6b178),
				sceneObject: null,
				themeColor: 'light-theme'
			},
			{
				slug: 'landet',
				icon: 'icon_podcast',
				name: 'remotefence',
				position: new THREE.Vector3(-41.25, 0, -20.36),
				centerPosition: new THREE.Vector3(-41.25, 0, -20.36),
				currCameraTargetOffset: new THREE.Vector3(3, 4, 3),
				cameraTargetOffset: new THREE.Vector3(3, 4, 3),
				cameraTargetOffsetPortrait: new THREE.Vector3(-10, -1, 1),
				loopHeight: 5,
				scale: 1,
				angle: null,
				voiceSamples: ['01', '02'],
				pitch: 0.9,
				greetingGender: 'male',
				greetingDelay: 0.5,
				rotationY: -1.95,
				plane: 'shadow',
				distance: 0,
				toModify: [
					{
						name: 'grass',
						properties: [
							{
								name: 'material',
								type: 'blending',
								value: THREE.MultiplyBlending
							},
							{
								name: 'position',
								type: 'y',
								value: 0.001
							},
							{
								name: 'material',
								type: 'emissive',
								value: new THREE.Color('#769E59')
							},
							{
								name: 'material',
								type: 'depthWrite',
								value: false
							}
						]
					}
				],
				color: new THREE.Color(0xf5de99),
				sceneObject: null,
				themeColor: 'light-theme'
			},
			{
				slug: 'kontoret',
				icon: 'icon_video',
				name: 'career',
				loopHeight: 5.7,
				position: new THREE.Vector3(7.66, 0, -50.84),
				centerPosition: new THREE.Vector3(3.7, 0, -52.32),
				currCameraTargetOffset: new THREE.Vector3(3, 4, 3),
				cameraTargetOffset: new THREE.Vector3(3, 2, 3),
				cameraTargetOffsetPortrait: new THREE.Vector3(-5, -5, -1.2),
				scale: 1,
				angle: null,
				voiceSamples: ['03'],
				pitch: 1.05,
				greetingGender: 'male',
				greetingDelay: 0.55,
				rotationY: -1.2,
				plane: 'shadow',
				color: new THREE.Color(0xdec3bc),
				sceneObject: null,
				themeColor: 'light-theme'
			},

			{
				slug: 'skrivebordet',
				icon: 'icon_video',
				name: 'desk',
				loopHeight: 6.5,
				position: new THREE.Vector3(54.28, 0, -39.61),
				centerPosition: new THREE.Vector3(56.06, 0, -35.13),
				currCameraTargetOffset: new THREE.Vector3(3, 4, 9),
				cameraTargetOffset: new THREE.Vector3(3, 3, 9),
				cameraTargetOffsetPortrait: new THREE.Vector3(-5, -4, 4.5),
				scale: 1,
				angle: null,
				voiceSamples: ['04'],
				pitch: Globals.IS_ENGLISH ? 1 : 0.9,
				greetingGender: 'male',
				greetingDelay: 0.35,
				rotationY: -0.46,
				plane: 'shadow',
				color: new THREE.Color(0xe4d4b3),
				sceneObject: null,
				themeColor: 'light-theme'
			},
			{
				slug: 'landevejen',
				icon: 'icon_podcast',
				name: 'jogger',
				loopHeight: 5,
				position: new THREE.Vector3(56.7, 0, 3.26),
				centerPosition: new THREE.Vector3(58.84, 0, 5.72),
				currCameraTargetOffset: new THREE.Vector3(3, 4, 5.5),
				cameraTargetOffset: new THREE.Vector3(3, 3, 5.5),
				cameraTargetOffsetPortrait: new THREE.Vector3(-5, -5, 1),
				scale: 1,
				angle: null,
				voiceSamples: Globals.IS_ENGLISH ? ['10', '11', '12', '13', '14', '15'] : ['04', '06', '07', '08'],
				pitch: 1.1,
				greetingGender: 'female',
				greetingDelay: 0.35,
				rotationY: -0.53,
				plane: 'shadow',
				color: new THREE.Color(0xcfdac9),
				sceneObject: null,
				themeColor: 'light-theme'
			},
			{
				slug: 'liggestolen',
				icon: 'icon_questions',
				name: 'remote',
				loopHeight: 4,
				position: new THREE.Vector3(27.36, 0, 39.15 /*0.73 * offset, 0, 0.64 * offset*/),
				centerPosition: new THREE.Vector3(29.78, 0, 41.27),
				currCameraTargetOffset: new THREE.Vector3(0, 2.3, 6.5),
				cameraTargetOffset: new THREE.Vector3(0, 2.3, 6.5),
				cameraTargetOffsetPortrait: new THREE.Vector3(-5, -5, 2.5),
				scale: 1,
				angle: null,
				voiceSamples: Globals.IS_ENGLISH ? ['12', '13', '14', '15', '16'] : ['12', '15'],
				pitch: 1.05,
				greetingGender: 'male',
				greetingDelay: 0.4,
				rotationY: -0.62,
				plane: 'shadow',
				color: new THREE.Color(0xf5de9a),
				sceneObject: null,
				themeColor: 'light-theme'
			},
			{
				slug: 'opvasken',
				icon: 'icon_video',
				name: 'kitchen',
				loopHeight: 5,
				position: new THREE.Vector3(8.74, 0, 80.18),
				centerPosition: new THREE.Vector3(14.42, 0, 82.8),
				currCameraTargetOffset: new THREE.Vector3(3, 4, 8.8),
				cameraTargetOffset: new THREE.Vector3(3, 4, 8.8),
				cameraTargetOffsetPortrait: new THREE.Vector3(3, -7, 1),
				scale: 1,
				angle: null,
				voiceSamples: ['07', '08'],
				pitch: 0.9,
				greetingGender: 'male',
				greetingDelay: 0.2,
				rotationY: -0.68,
				plane: 'shadow',
				color: new THREE.Color(0xf6b178),
				sceneObject: null,
				themeColor: 'light-theme'
			},
			{
				slug: 'sofaen',
				icon: 'icon_interview',
				name: 'sofa',
				loopHeight: 1.5,
				position: new THREE.Vector3(-24.04, 0.1, 47.94),
				centerPosition: new THREE.Vector3(-24.04, 0.1, 47.94),
				currCameraTargetOffset: new THREE.Vector3(3, 3, 5.2),
				cameraTargetOffset: new THREE.Vector3(3, 0, 3.2),
				cameraTargetOffsetPortrait: new THREE.Vector3(-5, -5, 1),
				scale: 1,
				angle: null,
				voiceSamples: Globals.IS_ENGLISH ? ['04', '06', '07', '08'] : ['10', '11', '12', '13', '14', '15'],
				pitch: 1.1,
				greetingGender: 'female',
				greetingDelay: 0.2,
				rotationY: -0.34,
				plane: 'shadow',
				color: new THREE.Color(0x100c24),
				sceneObject: null,
				themeColor: 'dark-theme'
			}
		];

		let situationsDebugFolder = null;

		if (this.debug) {
			// situationsDebugFolder = this.debug.addFolder('Situations');
		}

		let shadows = this.resources.items['shadows'];
		let shadowSetup = false;
		this.situations.forEach((situation, index) => {
			const situationScene = new Situation(this.resources, situation.name, situationsDebugFolder);

			situationScene.container.position.copy(situation.position);
			situationScene.container.scale.set(situation.scale, situation.scale, situation.scale);
			situationScene.container.rotation.y = situation.rotationY;
			this.group.add(situationScene.container);
			if (this.DEBUG_ZONES) {
				this.zones.add(this.createDebugArea(situation.centerPosition, situation.name));
			}

			situation.sceneObject = situationScene;

			// let plane = situationScene.container.getObjectByName(situation.plane) as THREE.Mesh;
			let logged = false;
			// if (!plane) {
			let plane = shadows.scene.getObjectByName('shadow_' + situation.name + '_MM') as THREE.Mesh;
			situationScene.container.add(plane);
			// }
			if (plane && !shadowSetup) {
				//TODO: @lasse, check if we can achieve a bit more in performance by switching to meshbasicmaterial - currently messes with floors not cutting off excess geometry.
				shadowSetup = true;
				let material = plane.material as THREE.MeshStandardMaterial;
				material.alphaMap = (material as THREE.MeshStandardMaterial).emissiveMap;
				material.emissiveMap = null;
				material.emissiveIntensity = 0;
				material.premultipliedAlpha = true;
				material.fog = true;
				material.color.set(0x000000);
				material.flatShading = true;
				material.transparent = true;
				material.opacity = 0.5;
				material.depthWrite = false;
				material.needsUpdate = true;
				// material.visible = false;

				/*				if (!logged) {
					logged = true;
					material.onBeforeCompile = function (shader) {
						// shader.vertexShader = shader.vertexShader.replace('#include <common>', colorParsChunk).replace('#include <begin_vertex>', instanceOpacityChunk);

						// shader.fragmentShader = shader.fragmentShader.replace('#include <common>', fragmentParsChunk).replace('vec4 diffuseColor = vec4( diffuse, opacity );', colorChunk);

						//console.log( shader.uniforms );
						console.log( shader.vertexShader );
						console.log( shader.fragmentShader );
					};
				}*/
				/*				const shadowOverrideMaterial = new THREE.MeshStandardMaterial({
									alphaMap: (plane.material as THREE.MeshStandardMaterial).emissiveMap,
									premultipliedAlpha: true,
									fog: true,
									color: 0x000000,
									// depthWrite: false,
									// alphaTest: 0.5,
									// blending: THREE.MultiplyBlending,
									flatShading: true,
									transparent: true,
									opacity: 0.5
									// colorWrite: false,
									// depthTest: false
								});

								// @ts-ignore
								plane.material.dispose();
								plane.material = shadowOverrideMaterial;*/

				// @ts-ignore
				// plane.material.blending = THREE.MultiplyBlending;

				// plane.material.visible = false;
			}

			//@todo, lets maybe refactor this out so the models are loaded correctly from the get go.
			situation.toModify &&
				situation.toModify.forEach(el => {
					const object = situationScene.container.getObjectByName(el.name) as THREE.Mesh;
					el.properties.forEach(p => {
						object[p.name][p.type] = p.value;
					});
				});
			this.situationDistancesToUfo.push(situation);
		});

		this.resize();
		emitter.on(EVENTS.resize, this.resize);
	}

	public showLayer(name) {
		this.layers.animateIn(name);
	}

	public hideLayer() {
		this.layers.animateOut();
	}

	createDebugArea(position: THREE.Vector3, name: string) {
		const group = new THREE.Group();
		group.position.copy(position);
		group.name = `debug_area_${name}`;
		const zone = new THREE.Mesh(new THREE.CircleBufferGeometry(20, 32), new THREE.MeshBasicMaterial({color: 0x247ba0}));
		zone.rotation.x = -Math.PI / 2;
		group.add(zone);

		const zoneCenter = new THREE.Mesh(new THREE.CircleBufferGeometry(12, 32), new THREE.MeshBasicMaterial({color: 0x70c1b3}));
		zoneCenter.rotation.x = -Math.PI / 2;
		zoneCenter.position.y = 0.2;
		group.add(zoneCenter);

		const centerMesh = new THREE.Mesh(new THREE.CylinderBufferGeometry(0.1, 0.1, 30), new THREE.MeshNormalMaterial());
		centerMesh.rotation.x = Math.PI / 2;
		zoneCenter.add(centerMesh);

		return group;
	}

	public getIndex(slug) {
		return this.situations.findIndex(situation => situation.slug === slug);
	}

	public getLength() {
		return this.situations.length;
	}

	public getSituation(slug) {
		return this.situationDistancesToUfo.find(situation => situation.slug === slug);
	}

	public getSituationByIndex(index) {
		return this.situations[index];
	}

	public updateSituationsDistancesToUfoArray(vector: Vector3) {
		this.distanceCalcVector.copy(vector);
		this.distanceCalcVector.y = 0;
		this.situationDistancesToUfo.forEach(situation => (situation.distance = situation.centerPosition.distanceTo(this.distanceCalcVector)));
		this.situationDistancesToUfo.sort((a, b) => a.distance - b.distance);
	}

	private resize = () => {
		this.situations.forEach(s => {
			s.currCameraTargetOffset = this.sizes.isPortrait ? s.cameraTargetOffsetPortrait : s.cameraTargetOffset;
		});
	};
}
