// import {GLTFExporter} from "three/examples/jsm/exporters/GLTFExporter";

export class ActiveStateController {
	private _pageVisible = true;
	private overridenVisiblity = false;

	get pageVisible(): boolean {
		return this.overridenVisiblity === false ? this._pageVisible : true;
	}

	set pageVisible(value: boolean) {
		this._pageVisible = value;
	}

	constructor() {
		//Disable rendering when window not in focus, useful when developing locally:
		document.addEventListener('visibilitychange', () => {
			this.pageVisible = !document.hidden;
		});
		document.addEventListener('keyup', event => {
			if (event.key === 'p') {
				/*				// Instantiate a exporter
				var exporter = new GLTFExporter();

// Parse the input and generate the glTF output
				// @ts-ignore
				exporter.parse( window.scene, function ( gltf ) {
					console.log( JSON.stringify(gltf) );
					// downloadJSON( gltf );
				}, {binary: false, embedImages: true, truncateDrawRange: false, onlyVisible: false, trs: true} );*/
				this.overridenVisiblity = !this.overridenVisiblity;
			}
		});
		window.addEventListener('focus', () => {
			if (!document.hidden) {
				this.pageVisible = true;
			}
		});
		window.addEventListener('blur', () => {
			this.pageVisible = false;
		});
	}
}
