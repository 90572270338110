import Dat from 'dat.gui';
import {emitter, EVENTS} from '../utils/Dispatcher';
import * as THREE from 'three';
import {AnimationAction, AnimationClip} from 'three';
import Resources from '../Resources';
import {JoggerGroundAnimation} from './JoggerGroundAnimation';
import Time from '../utils/Time';

export class Situation {
	private resources: Resources;
	private mixer: THREE.AnimationMixer;
	private actions: AnimationAction[];
	private idleAction: THREE.AnimationAction;
	private armUpAction: THREE.AnimationAction;
	public container = new THREE.Group();
	private debug: Dat.GUI;
	private time = new Time();
	private armUpDisabled: boolean = false;

	constructor(resources: Resources, name: string, debug: Dat.GUI) {
		this.resources = resources;
		this.debug = debug;

		let gltf = this.resources.items[name];
		let findInSharedScenesFile = gltf === undefined;
		if (findInSharedScenesFile) {
			// console.error(`no scene with name: ${name}`, this.resources.items[name]);
			gltf = this.resources.items['scenes'];
		}
		const modelGroup = findInSharedScenesFile ? gltf.scenes.find(group => group.name === name) : gltf.scene;
		const animations = findInSharedScenesFile ? gltf.animations.flatMap(clip => (clip.name.startsWith(name + '_') ? clip : [])) : gltf.animations;

		let debugFolder = null;
		if (this.debug) {
			debugFolder = this.debug.addFolder(name);
		}

		modelGroup.traverse((object: THREE.Mesh) => {
			if (object.isMesh) {
				debugFolder && debugFolder.addMaterial(`${object.name} material`, object.material);
				this.updateMaterial(object);
			}
		});

		if (name === 'jogger') {
			this.armUpDisabled = true;
			const joggerGroundAnimation = new JoggerGroundAnimation(this.resources, this.container);
		}

		this.container.add(modelGroup);
		// gltf.scene.visible = false;
		this.container.name = name;

		this.setActions(modelGroup, animations);
		this.bindEvents();
	}

	bindEvents() {
		emitter.on(EVENTS.tick, this.raf);
	}

	/**
	 * Update material properties
	 */
	updateMaterial(object) {
		if (!object.material) return;

		object.material.roughness = 0.55;
		object.material.metalness = 0;
		object.material.needsUpdate = true;
		object.material.visible = true;
	}

	/**
	 * Init all the models animation
	 */
	setActions(modelGroup: THREE.Group, animations: AnimationClip[]) {
		if (animations.length == 0) {
			console.warn('Situation has no animations', modelGroup);
			return;
		}
		this.mixer = new THREE.AnimationMixer(modelGroup);

		this.idleAction = this.mixer.clipAction(animations[0]);
		if (!this.armUpDisabled) {
			this.armUpAction = this.mixer.clipAction(animations[1]);
			this.armUpAction.loop = THREE.LoopOnce;
			this.actions = [this.idleAction, this.armUpAction];
		} else {
			this.actions = [this.idleAction];
		}

		this.setWeight(this.idleAction, 1);
		if (!this.armUpDisabled) {
			this.setWeight(this.armUpAction, 0);
		}

		this.idleAction.play();
	}

	/**
	 * Set action weight
	 */
	setWeight(action: AnimationAction, weight) {
		action.enabled = true;
		action.setEffectiveTimeScale(1);
		action.setEffectiveWeight(weight);
	}

	/**
	 * Play arm up action
	 */
	public armUp() {
		if (!this.armUpDisabled) {
			this.mixer.addEventListener('finished', this.idle);
			this.playAction(this.idleAction, this.armUpAction);
		}
	}

	/**
	 * Play idle action
	 */
	public idle = () => {
		if (!this.armUpDisabled) {
			this.mixer.removeEventListener('finished', this.idle);
			this.playAction(this.armUpAction, this.idleAction);
		}
	};

	/**
	 * Fade new action from current action
	 */
	private playAction(startAction: AnimationAction, endAction: AnimationAction) {
		endAction.play();
		endAction.time = 0;
		this.setWeight(endAction, 1);
		startAction.crossFadeTo(endAction, 1, true);
	}

	/**
	 * Request animation function
	 */
	private raf = () => {
		//@todo, this function causes memory leaks
		this.mixer.update(this.time.clockDelta);
	};
}
