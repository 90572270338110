import * as THREE from 'three';
import Resources from '../Resources';
import {SituationManager} from './SituationManager';
import {gsap} from 'gsap';
import {CircleBufferGeometry, RingBufferGeometry} from 'three';

export class UFODock {
	private situationManager: SituationManager;
	private scene: THREE.Scene;
	private resources: Resources;
	private shadow: THREE.Mesh;
	private dock: THREE.Mesh;
	private dockGroup: THREE.Group;

	constructor(resources: Resources, situationManager: SituationManager, scene: THREE.Scene) {
		this.resources = resources;
		this.scene = scene;
		this.situationManager = situationManager;

		this.initDock();
		this.initRings();
		this.initArrows();
	}

	initDock() {
		const dock = this.resources.items.ufo.scene.getObjectByName('dock_wrapper') as THREE.Group;
		dock.name = 'dock';

		dock.traverse((object: any) => {
			if (object.isMesh) {
				if (object.name === 'dock_shadow') {
					let material = object.material.clone() as THREE.MeshStandardMaterial;
					material.alphaMap = (material as THREE.MeshStandardMaterial).emissiveMap;
					material.emissiveMap = null;
					material.color = new THREE.Color(0x000000);
					material.emissive = new THREE.Color(0x000000);
					material.fog = true;
					material.flatShading = true;
					material.transparent = true;
					material.opacity = 0.5;
					material.depthWrite = false;
					material.needsUpdate = true;
					object.material = material;
					this.shadow = object;
				} else {
					let mat = object.material.clone();
					mat.color.setHex(0x353535);
					object.material = mat;
					this.dock = object;
					this.dock.geometry.computeVertexNormals();
				}
			}
		});
		dock.position.set(23.66, 0, -17.38);
		// dock.children[0].position.y = 1.22;
		dock.rotation.y = 1.79;
		// dock.scale.set(1.52, 1.52, 1.52);
		gsap.to(this.dock.position, {y: '+=0.4', repeat: -1, yoyo: true, duration: 2, ease: 'sine.inOut'});
		gsap.to(this.shadow.scale, {y: 0.8, x: 0.8, z: 0.8, repeat: -1, yoyo: true, duration: 2, ease: 'sine.inOut'});
		this.dockGroup = dock;
		this.scene.add(dock);
	}

	initRings() {
		const ringGroups = new THREE.Group();
		let rings = [10, 20, 30, 40];
		const geom = new CircleBufferGeometry(6.5, 64);
		geom.rotateX(-Math.PI * 0.5);
		let mat = new THREE.MeshBasicMaterial({color: 0xffffff, transparent: true, opacity: 0.06, depthWrite: false, fog: true});

		rings.forEach((value, index) => {
			let mesh = new THREE.Mesh(geom, mat);
			mesh.position.y = index * 0.02;
			mesh.scale.set(value / 18, value / 18, value / 18);
			ringGroups.add(mesh);
		});

		ringGroups.name = 'RingGroups';
		// ringGroups.rotation.x = -Math.PI * 0.5;
		ringGroups.position.copy(this.dockGroup.position);
		ringGroups.position.y = 0.14;
		this.scene.add(ringGroups);
	}

	initArrows() {
		const arrow = this.resources.items.ufo.scene.getObjectByName('arrow');
		arrow.parent.remove(arrow);
		arrow.name = 'arrow';

		for (let i = 0; i < this.situationManager.situations.length; i++) {
			const group = this.createArrow(arrow);
			group.rotation.y = (Math.PI / 4) * i;
			group.position.copy(this.dockGroup.position);
			this.scene.add(group);
		}
	}

	createArrow(model) {
		const group = new THREE.Group();
		const tl = gsap.timeline({repeat: -1, repeatDelay: 1});

		for (let j = 0; j < 2; j++) {
			const arrowCopy = model.clone();
			arrowCopy.material = new THREE.MeshBasicMaterial({color: 0xffffff, opacity: 0.2, transparent: true});
			group.add(arrowCopy);
			arrowCopy.rotateY(-Math.PI / 2);
			arrowCopy.position.set(0, 0, 5 + 0.5 * j);
			group.add(arrowCopy);

			tl.to(
				arrowCopy.material,
				{
					opacity: 1,
					duration: 0.4,
					ease: 'sine.inOut'
				},
				j * 0.2
			);
			tl.to(
				arrowCopy.material,
				{
					opacity: 0.2,
					duration: 0.4,
					ease: 'sine.inOut'
				},
				j * 0.4 + 0.4
			);
		}

		return group;
	}

	distanceValcVector = new THREE.Vector3();

	getDistanceFromUfo = (position: THREE.Vector3) => {
		this.distanceValcVector.copy(position);
		this.distanceValcVector.y = 0;
		return this.dockGroup.position.distanceTo(this.distanceValcVector);
	};
}
