import './theme/styles.scss';

import {gsap} from 'gsap';

import Resources from './src/Resources';
import {emitter, EVENTS} from './src/utils/Dispatcher';
import Sizes from './src/utils/Sizes';
import Navigation from './src/views/components/Navigation/Navigation';
import Time from './src/utils/Time';
import {Intro} from './src/views/pages/Intro/Intro';

import {PhysicsPropsPlugin} from 'gsap/PhysicsPropsPlugin.js';
import {SplitText} from 'gsap/SplitText.js';
import {MotionPathPlugin} from 'gsap/MotionPathPlugin.js';
import {Experience} from './src/views/pages/Experience/Experience';
import {Globals} from './src/utils/Globals';
import {CookieOptOut} from './src/views/components/CookieOptOut/CookieOptOut';
import {WEBGL} from 'three/examples/jsm/WebGL';
import {AudioEngine} from './src/audio/AudioEngine';

class Main {
	private experience: Experience;
	private intro: Intro;
	private navigation: Navigation;
	private resources: Resources;
	private time: Time;
	public DOMAppElement: HTMLElement;

	private introDone = false;
	private experienceReady = false;
	static SELECTORS = {
		APP: '#app'
	};
	private DOMLoadingElement: HTMLElement;
	private loadingText: Element;
	private sizes: Sizes;

	constructor() {
		Globals.AUDIO_ENGINE = new AudioEngine();

		new CookieOptOut();
		gsap.registerPlugin(PhysicsPropsPlugin);
		gsap.registerPlugin(SplitText);
		gsap.registerPlugin(MotionPathPlugin);
		const {APP} = Main.SELECTORS;
		this.time = new Time();
		this.sizes = new Sizes();

		this.DOMAppElement = document.querySelector(APP);
		this.DOMLoadingElement = document.getElementById('LoadingIndicator');
		this.loadingText = this.DOMLoadingElement.querySelector('.percentage');
		this.intro = new Intro(this.DOMAppElement);
		this.navigation = new Navigation(this.DOMAppElement);

		if (!WEBGL.isWebGLAvailable()) {
			console.log('no WEBGL');
			document.body.appendChild(WEBGL.getWebGLErrorMessage());
		} else {
			this.experience = new Experience(this.DOMAppElement);
			this.resources = new Resources(this.experience);
		}
		this.bindEvents();
		this.animateIn();
	}

	bindEvents() {
		emitter.on(EVENTS.enterIntro, this.videoStarted);
		emitter.on(EVENTS.loaded, this.resourcesLoaded);
		emitter.on(EVENTS.introEnded, this.introEnded);
		emitter.on(EVENTS.progress, this.onProgress);
		if (!Globals.SKIP_INTRO) {
			emitter.on(EVENTS.loadingAnimationEnded, this.loadingAnimationEnded);
		}
	}

	onProgress = progress => {
		const perc = Math.round(progress * 100);

		// this.DOMProgressBar.style.transform = `translateX(${-100 + perc}%)`;

		this.loadingText.textContent = perc + '%';
		if (perc === 100) {
			emitter.off(EVENTS.progress, this.onProgress);
			gsap.set(this.DOMLoadingElement, {opacity: 0});
			gsap.delayedCall(0.2, () => {
				this.DOMLoadingElement.parentNode.removeChild(this.DOMLoadingElement);
			});
			emitter.emit(EVENTS.loadingAnimationEnded);
		}
	};

	animateIn() {
		this.navigation.animateIn();
		if (this.resources) {
			this.resources.loadResources();
			if (!Globals.SKIP_INTRO) {
				this.intro.animateIn();
			}
		}
	}

	videoStarted = () => {
		//this.resources.loadResources();
	};

	resourcesLoaded = () => {
		this.experience.populateWorld(this.resources);
		this.experienceReady = true;
		if (this.introDone || Globals.SKIP_INTRO) {
			this.introDone = true;
			this.loadingAnimationEnded();
		}

		//Global cancel UI bubble clicks to not interfere with movement controls:
		const all = document.querySelectorAll('.cancel-bubble');
		all.forEach(item => item.addEventListener('touchstart', event => (event.cancelBubble = true)));
		all.forEach(item => item.addEventListener('mousedown', event => (event.cancelBubble = true)));
		all.forEach(item => item.addEventListener('mouseup', event => (event.cancelBubble = true)));

		if (Globals.SKIP_INTRO) {
			this.intro.skipIntro();
		}
	};

	loadingAnimationEnded = () => {
		if (this.experienceReady && this.introDone) {
			if (Globals.IS_SAFARI) {
				this.resources.loader.uploadTexture();
			}
			this.intro.animateOut();
			this.experience.animateIn();
		}
	};

	introEnded = () => {
		this.introDone = true;

		if (!this.experienceReady) {
			gsap.set(this.DOMLoadingElement, {opacity: 1});
		}
		if (!Globals.SKIP_INTRO && this.experienceReady) {
			this.loadingAnimationEnded();
		}
	};
}

new Main();
