type EventHandler = (event?: any) => void;
type WildCardHandler = (type: string, event?: any) => void;
type EventHandlerList = EventHandler[];
type WildCardHandlerList = WildCardHandler[];
type EventHandlerMap = {
	'*'?: WildCardHandlerList;
	[type: string]: EventHandlerList;
};

export class Dispatcher {
	private all: EventHandlerMap;

	constructor(all: EventHandlerMap = Object.create(null)) {
		this.all = all;
	}

	/**
	 * Register an event handler for the given type.
	 *
	 * @param  {String} type	Type of event to listen for, or `"*"` for all events
	 * @param  {Function} handler Function to call in response to given event
	 */
	public on(type: string, handler: EventHandler) {
		(this.all[type] || (this.all[type] = [])).push(handler);
	}

	/**
	 * Remove an event handler for the given type.
	 *
	 * @param  {String} type	Type of event to unregister `handler` from, or `"*"`
	 * @param  {Function} handler Handler function to remove
	 */
	public off(type: string, handler: EventHandler) {
		if (this.all[type]) {
			this.all[type].splice(this.all[type].indexOf(handler) >>> 0, 1);
		}
	}

	/**
	 * Invoke all handlers for the given type.
	 * If present, `"*"` handlers are invoked after type-matched handlers.
	 *
	 * @param {String} type  The event type to invoke
	 * @param {Any} [evt]  Any value (object is recommended and powerful), passed to each handler
	 */
	public emit(type: string, evt?: any) {
		(this.all[type] || []).slice().map(handler => handler(evt));
		(this.all['*'] || []).slice().map(handler => handler(type, evt));
	}
}

// Define events here to avoid magic strings
export const EVENTS = {
	loaded: 'event_loaded',
	resize: 'event_resize',
	scroll: 'event_scroll',
	wheel: 'event_wheel',
	select: 'select',
	mouseOver: 'event_mouse_over',
	mouseOut: 'event_mouse_out',
	mouse: 'event_mouse',
	tick: 'event_update_tick',
	stateChange: 'event_state_change',
	play: 'event_play',
	pause: 'event_pause',
	fullScreen: 'event_full_screen',
	fileLoaded: 'file_loaded',
	loaderProgress: 'loader_progress',
	debug: 'debug_mode',
	keyUp: 'key_up',
	keyDown: 'key_down',
	dropObject: 'drop_object',
	launch: 'launch_experience',
	progress: 'loader_progress',
	moveToCharacter: 'move_camera_to_character',
	toggleMenu: 'toggle_menu',
	enterIntro: 'enter_loader',
	ufoMove: 'ufo_move',
	ufoVertical: 'ufo_vertical',
	toggleUfoVertical: 'toggle_ufo_vertical',
	toggleUfoDirectional: 'toggle_ufo_directional',
	toggleUfoDrop: 'toggle_ufo_drop',
	toggleUfoAll: 'toggle_ufo_all',
	situationZoom: 'situation_zoom',
	travelFast: 'ufo_travel_fast',
	leaveSituationArea: 'ufo_left_situation',
	videoFullscreen: 'video_fullscreen_toggled',
	ufoIsReady: 'ufo_is_loaded',
	introEnded: 'intro_is_ended',
	loadingAnimationEnded: 'loading_animation_is_ended',
	audioAssetsLoaded: 'audio_assets_loaded',
	firstMove: 'ufo_started_to_move',
	fadeAssets: 'fade_assets',
	muteChange: 'mute',
	safariUpload: 'upload_texture_safari'
};

export const emitter = new Dispatcher();
