import {emitter, EVENTS} from './utils/Dispatcher';
import {Globals} from './utils/Globals';
import * as THREE from 'three';
import Sizes from './utils/Sizes';
import {gsap} from 'gsap';

class Controls {
	public actions: any = {};
	private debug: boolean = false;
	private keys: any;
	private angleDirection = '';
	private angleCounter = 0;
	private angleCurrent = 0;
	public anglePrevious = 0;
	public currentActions: any[];
	private sizes: Sizes;
	public isPressing: boolean;
	private mouseControl = false;
	private keysControl = false;
	public speed: number;

	constructor() {
		this.setActions();
		this.bindEvents();

		this.sizes = new Sizes();
		this.setAngle((-Math.PI / 2 + 0.28) * -1);
	}

	public setAngle = angle => {
		this.angle = angle;
		this.angleCurrent = this.angle;
		this.anglePrevious = this.angle;
	};

	private setActions() {
		this.debug = false;
		this.actions.up = false;
		this.actions.right = false;
		this.actions.down = false;
		this.actions.left = false;
		this.actions.brake = false;
		this.actions.boost = false;
		this.actions.upwards = false;
		this.actions.downwards = false;
		this.currentActions = [];

		this.keys = {
			z: 'podcast',
			x: 'video',
			c: 'news',
			v: 'exam',
			q: 'upwards',
			e: 'downwards',
			arrowup: 'forward',
			arrowright: 'right',
			arrowdown: 'backward',
			arrowleft: 'left',
			w: 'forward',
			d: 'right',
			s: 'backward',
			a: 'left'
		};
	}

	isTouching = false;
	startPosition = new THREE.Vector2();
	currentPosition = new THREE.Vector2();

	center = new THREE.Vector2();
	angle: any = 0;

	private firstMoveHappened = false;

	private bindEvents() {
		document.addEventListener('keydown', this.keyDown);
		document.addEventListener('keyup', this.keyUp);
		document.body.addEventListener('touchstart', this.touchStart);
		document.body.addEventListener('touchstart', this.touchstartFirst);

		document.body.addEventListener('mousedown', this.mouseDown);
		document.body.addEventListener('mousedown', this.pressStartFirst);
		document.body.addEventListener('mousemove', this.mouseMove);
		document.body.addEventListener('mouseup', this.mouseUp);

		window.addEventListener('blur', this.reset);
	}

	mouseUp = e => {
		this.isPressing = false;
		this.mouseControl = false;
		this.actions.forward = false;
		document.body.style.cursor = 'auto';
	};

	mouseDown = e => {
		if (Globals.DISABLE_CONTROLS) return;

		if (this.keysControl) return;

		this.mouseControl = true;
		this.isPressing = true;
		this.startPosition.x = e.clientX;
		this.startPosition.y = e.clientY;

		document.body.style.cursor = 'grabbing';

		this.actions.forward = true;

		this.center.set(this.sizes.viewport.width / 2, this.sizes.viewport.height / 2);
		// this.center.set(this.startPosition.x, this.startPosition.y);

		this.currentPosition.set(e.clientX, e.clientY);
		const newAngle = this.getAngle(this.center, this.currentPosition);

		if (this.isABigGap(newAngle, this.anglePrevious)) {
			this.angleDirection = newAngle < this.anglePrevious ? 'clockwise' : 'counterclockwise';
		}

		this.moveToward(e.clientX, e.clientY);
	};

	mouseMove = e => {
		if (!this.isPressing) return;

		this.moveToward(e.clientX, e.clientY);
	};

	moveToward = (x = 0, y = 0) => {
		this.currentPosition.set(x, y);
		this.angleCurrent = this.getAngle(this.center, this.currentPosition);
		const IsABigGap = this.isABigGap(this.angleCurrent, this.anglePrevious);

		const speedX = gsap.utils.clamp(0.1, 1, Math.abs((this.center.x - this.currentPosition.x) / (window.innerWidth * 0.4)));
		const speedY = gsap.utils.clamp(0.1, 1, Math.abs((this.center.y - this.currentPosition.y) / (window.innerHeight * 0.4)));

		this.speed = Math.max(speedX, speedY);

		if (!IsABigGap) {
			this.angleDirection = this.angleCurrent >= this.anglePrevious ? 'clockwise' : 'counterclockwise';
		}

		if (IsABigGap && this.angleDirection === 'clockwise') {
			this.angleCounter++;
		} else if (IsABigGap && this.angleDirection === 'counterclockwise') {
			this.angleCounter--;
		}

		this.anglePrevious = this.angleCurrent;
		const angle = this.angleCurrent + 360 * this.angleCounter;

		this.angle = angle * (Math.PI / 180);
	};

	public setAngleTest = angle => {
		this.angleCounter = Math.floor((angle * 180) / Math.PI / 360);
	};

	touchStart = e => {
		if (Globals.DISABLE_CONTROLS) return;

		const touch = e.touches[0];

		if (!touch) return;

		this.isTouching = true;
		this.startPosition.x = touch.clientX;
		this.startPosition.y = touch.clientY;

		this.actions.forward = true;
		this.center.set(this.startPosition.x, this.startPosition.y);
		// this.center.set(window.innerWidth / 2, window.innerHeight / 2);
		this.currentPosition.set(touch.clientX, touch.clientY);
		const newAngle = this.getAngle(this.center, this.currentPosition);

		if (this.isABigGap(newAngle, this.anglePrevious)) {
			this.angleDirection = newAngle < this.anglePrevious ? 'clockwise' : 'counterclockwise';
		}

		this.moveToward(touch.clientX, touch.clientY);

		e.target.addEventListener('touchend', this.touchEnd);
		e.target.addEventListener('touchmove', this.touchMove);
	};

	pressStartFirst = e => {
		if (!this.isTouching && !this.isPressing) return;

		this.checkFirstMove();
		document.body.removeEventListener('mousedown', this.pressStartFirst);
	};

	touchstartFirst = e => {
		if (!this.isTouching) return;
		this.checkFirstMove();
		document.body.removeEventListener('touchstart', this.touchstartFirst);
	};

	getAngle = (v0, v1) => {
		let theta = (Math.atan2(v1.y - v0.y, v1.x - v0.x) * 180) / Math.PI;
		if (theta < 0) theta = 360 + theta;
		return theta;
	};

	isABigGap = (a1, a2) => {
		return Math.abs(a1 - a2) > 180;
	};

	touchMove = e => {
		if (!this.isTouching) return;

		const touch = e.touches[0];
		this.moveToward(touch.clientX, touch.clientY);
	};

	touchEnd = e => {
		this.isTouching = false;
		this.actions.forward = false;
		e.target.removeEventListener('touchmove', this.touchMove);
	};

	private keyDown = _event => {
		if (Globals.DISABLE_CONTROLS) return;

		if (this.mouseControl) return;

		this.keysControl = true;

		let action;
		let code = _event.key.toLowerCase();

		switch (code) {
			case 'arrowup':
			case 'arrowright':
			case 'arrowdown':
			case 'arrowleft':
			case 'w':
			case 'a':
			case 's':
			case 'd':
				action = this.keys[code];
				this.checkFirstMove();
				break;
			case 'r':
				if (Globals.DEBUG) {
					this.debug = !this.debug;
					emitter.emit(EVENTS.debug, this.debug);
				}
				break;
			/*			case 'z':
			case 'x':
			case 'c':
			case 'v':
				//@TODO @sergeui, don't pass new objects here, pass the value instead
				emitter.emit(EVENTS.dropObject, this.keys[code]);
				break;

			case 'q':
			case 'e':
				action = this.keys[code];
				break;*/
		}

		this.actions[action] = true;

		const isCurrent = this.currentActions.filter(w => w === action);
		if (isCurrent.length === 0) {
			this.currentActions.push(action);
		}
	};

	private checkFirstMove() {
		if (!this.firstMoveHappened) {
			this.firstMoveHappened = true;
			emitter.emit(EVENTS.firstMove);
		}
	}

	private keyUp = _event => {
		if (Globals.DISABLE_CONTROLS) return;

		this.keysControl = false;

		let action;

		switch (_event.key) {
			case 'ArrowUp':
			case 'w':
				action = 'forward';
				break;

			case 'ArrowRight':
			case 'd':
				action = 'right';
				break;

			case 'ArrowDown':
			case 's':
				action = 'backward';
				break;

			case 'ArrowLeft':
			case 'a':
				action = 'left';
				break;

			case 'q':
				action = 'upwards';
				break;
			case 'e':
				action = 'downwards';
				break;
		}

		this.actions[action] = false;

		const isCurrent = this.currentActions.filter(w => w !== action);
		this.currentActions = isCurrent;
	};

	reset = () => {
		for (let action in this.actions) {
			this.actions[action] = false;
		}
	};
}

export default new Controls();
