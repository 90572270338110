import './SituationLayers.scss';
import {emitter, EVENTS} from '../../../utils/Dispatcher';
import {Globals} from '../../../utils/Globals';
import {partial} from '../../../utils/partial';
import Sizes from '../../../utils/Sizes';
import {MediaController} from '../../../MediaController';
import {gsap} from 'gsap';
import {MotionPathPlugin} from 'gsap/MotionPathPlugin.js';

interface LayerDom {
	element: HTMLElement;
	informationsItems: NodeListOf<Element>;
	title: Element;
	uptitle: Element;
	header: Element;
	description: Element;
	mainBar: Element;
	mainContent: Element;
	moduleText: Element;
	videoContainer?: Element;
	video?: HTMLVideoElement;
	opaqueLayer?: Element;
	mobileExpandLayer: Element;
	mobileExpandToggle: Element;
}

interface ILayer {
	name: string;
	type: string;
	dom: LayerDom;
	media: MediaController;
	timelineIn: gsap.core.Timeline;
	timelineOut: gsap.core.Timeline;
	fullscreen: gsap.core.Timeline;
	isExpandedOnMobile: boolean;
	expandValue: number;
}

export class SituationLayers {
	private app: HTMLElement;
	private domElement: HTMLElement;

	static SELECTORS = {
		ELEMENT: '.situations-layer-container',
		LAYER: '.situation-layer',
		INFORMATION_ITEMS: '.situation-informations',
		INFORMATION_ITEMS_VALUE: '.situation-informations__item-value',
		LAYER_NAME: '.situation-name',
		LAYER_UPTITLE: '.situation-uptitle',
		LAYER_HEADER: '.situation-layer__header',
		LAYER_DESCRIPTION: '.situation-description',
		LAYER_MAIN_BAR: '.situation-main-bar',
		LAYER_MAIN_CONTENT: '.situation-main-content',
		LAYER_VIDEO_CONTAINER: '.video-container',
		LAYER_VIDEO: '.video-container video',
		LAYER_VIDEO_BACKGROUND: '.situation-layer__fullscreen-layer',
		LAYER_MODULE_TEXT: '.module-text',
		MOBILE_EXPAND_LAYER: '.mobile-expand-layer',
		MOBILE_EXPAND_INDICATOR: '.mobile-expand-toggle'
	};
	private DOMlayers: NodeListOf<HTMLElement>;
	private layers = new Map<string, ILayer>();
	private currentLayer: any;
	private mediaController: MediaController;
	private medias: any[];
	private sizes: Sizes;

	constructor(app: HTMLElement) {
		const {
			ELEMENT,
			LAYER,
			LAYER_VIDEO,
			LAYER_VIDEO_CONTAINER,
			LAYER_VIDEO_BACKGROUND,
			INFORMATION_ITEMS,
			LAYER_NAME,
			LAYER_UPTITLE,
			LAYER_DESCRIPTION,
			LAYER_MAIN_BAR,
			LAYER_MAIN_CONTENT,
			LAYER_MODULE_TEXT,
			LAYER_HEADER,
			MOBILE_EXPAND_LAYER,
			MOBILE_EXPAND_INDICATOR
		} = SituationLayers.SELECTORS;

		this.app = app;
		this.domElement = this.app.querySelector(ELEMENT);

		this.DOMlayers = this.domElement.querySelectorAll(LAYER);
		this.medias = [];
		this.sizes = new Sizes();

		this.DOMlayers.forEach(layer => {
			const dom = {
				element: layer,
				informationsItems: layer.querySelectorAll(INFORMATION_ITEMS),
				title: layer.querySelector(LAYER_NAME),
				uptitle: layer.querySelector(LAYER_UPTITLE),
				description: layer.querySelector(LAYER_DESCRIPTION),
				header: layer.querySelector(LAYER_HEADER),
				mainBar: layer.querySelector(LAYER_MAIN_BAR),
				mainContent: layer.querySelector(LAYER_MAIN_CONTENT),
				moduleText: layer.querySelector(LAYER_MODULE_TEXT),
				mobileExpandLayer: layer.querySelector(MOBILE_EXPAND_LAYER),
				mobileExpandToggle: layer.querySelector(MOBILE_EXPAND_INDICATOR)
			} as LayerDom;

			if (layer.classList.contains('type-video')) {
				dom.videoContainer = layer.querySelector(LAYER_VIDEO_CONTAINER);
				dom.video = layer.querySelector(LAYER_VIDEO) as HTMLVideoElement;
				dom.opaqueLayer = layer.querySelector(LAYER_VIDEO_BACKGROUND);
			}

			this.layers.set(layer.dataset.layerName, {
				name: layer.dataset.layerName,
				type: layer.dataset.layerType,
				dom,
				media: layer.dataset.layerType === 'media' ? new MediaController(layer.dataset.layerName, layer) : null,
				timelineIn: null,
				timelineOut: null,
				fullscreen: null,
				isExpandedOnMobile: false,
				expandValue: 0
			});

			this.setAnimationState(dom);
		});

		this.bindEvents();
		this.resize();
	}

	bindEvents() {
		emitter.on(EVENTS.fullScreen, this.fullscreen);
		emitter.on(EVENTS.resize, this.resize);

		// if(this.sizes.isMobile) {
		this.layers.forEach(layer => {
			layer.dom.header.addEventListener('click', this.toggleInformationMobile);
			layer.dom.mobileExpandLayer.addEventListener('click', this.toggleInformationMobile);
		});
		// }
	}

	createTimelineIn = layer => {
		if (layer.timelineOut) {
			layer.timelineOut.kill();
		}

		const dom = layer.dom;
		const timeline = gsap.timeline();
		timeline.set(dom.element, {pointerEvents: 'all', visibility: 'visible'}, 0);
		timeline.to([dom.uptitle, dom.title, dom.informationsItems, dom.description], {duration: 1, stagger: 0.05, opacity: 1, y: 0, ease: 'power3.out', force3D: true}, this.sizes.isLaptop ? 0 : 0.1);
		timeline.to(
			this.sizes.isLaptop ? dom.mainBar : [dom.mainBar, dom.video, dom.mobileExpandToggle],
			{y: 0, duration: 0.6, opacity: 1, ease: 'power3.out', force3D: true},
			this.sizes.isLaptop ? 0.1 : 0
		);

		if (this.sizes.isLaptop) {
			timeline.to(dom.video ? [dom.video, dom.moduleText] : dom.moduleText, {y: 0, duration: 0.6, opacity: 1, stagger: -0.05, ease: 'power3.out', force3D: true}, 0.25);
		}
		layer.timelineIn = timeline;
	};

	createTimelineOut = layer => {
		if (layer.timelineIn) {
			layer.timelineIn.kill();
		}

		const dom = layer.dom;
		const timeline = gsap.timeline({
			onComplete: () => {
				this.setAnimationState(dom);
			}
		});

		const direction = this.sizes.isMobile ? 1 : -1;

		// timeline.to(dom.mobileExpandToggle, {opacity: 0, duration: 0.3, ease: 'power0.none'}, 0);
		timeline.to(
			[dom.uptitle, dom.title, dom.informationsItems, dom.description],
			{duration: 0.3, stagger: direction * -1 * 0.05, opacity: 0, y: direction * 20, ease: 'power3.out', force3D: true},
			0
		);

		timeline.to(
			this.sizes.isLaptop ? dom.mainBar : [dom.mainBar, dom.video, dom.mobileExpandToggle],
			{y: direction * 20, duration: 0.3, opacity: 0, ease: 'power3.out', force3D: true},
			this.sizes.isLaptop ? 0.1 : 0.1
		);

		if (this.sizes.isLaptop) {
			timeline.to(dom.video ? [dom.video, dom.moduleText] : dom.moduleText, {y: direction * 20, duration: 0.3, opacity: 0, ease: 'power3.out', force3D: true}, 0.15);
		}

		layer.timelineOut = timeline;
	};

	setAnimationState = dom => {
		gsap.set(dom.element, {pointerEvents: 'none', visibility: 'hidden'});
		gsap.set(dom.mobileExpandToggle, {opacity: 0});
		gsap.set([dom.uptitle, dom.title, dom.informationsItems, dom.description], {y: 20, opacity: 0});
		gsap.set(dom.video ? [dom.mainBar, dom.video] : [dom.mainBar], {opacity: 0, y: 20, force3D: true});

		if (this.sizes.isLaptop) {
			gsap.set(dom.moduleText, {opacity: 0, y: 20, force3D: true});
		} else {
			gsap.set(dom.mobileExpandToggle, {opacity: 0, y: 20, force3D: true});
		}
	};

	// Depending on the complexity of the animations it might also be more performant to define them in css only.
	public animateIn = (name: string) => {
		const layer = this.layers.get(name);
		if (!layer || layer === this.currentLayer) return;
		this.createTimelineIn(layer);
		this.currentLayer = layer;
	};

	public animateOut() {
		const layer = this.currentLayer;
		if (!layer) return;
		if (layer.media) {
			layer.media.reset();
		}
		if (this.currentLayer.isExpandedOnMobile) {
			this.toggleInformationMobile();
		}
		this.currentLayer = null;
		this.createTimelineOut(layer);
	}

	fullscreen = e => {
		let layer = this.currentLayer;

		const timeline = gsap.timeline({
			onStart: () => {
				if (!layer.fullscreen) {
					// layer.dom.element.style.overflow = 'hidden'
					// this.resize()
				}
			}
		});

		const r = layer.dom.video.getBoundingClientRect();
		layer.dom.element.classList.toggle('video-is-fullscreen');

		const c1 = layer.dom.element.querySelector('.video-container .main-bar-position');

		const r1 = layer.dom.video.getBoundingClientRect();

		const scale = r.width / r1.width;
		const x = r.left - r1.left - r1.width / 2 + r.width / 2;
		const y = r.top - r1.top - r1.height / 2 + r.height / 2;

		gsap.set(layer.dom.video, {scale, x, y});
		gsap.set(layer.dom.mainBar, {zIndex: 4});

		const offset = !layer.fullscreen ? 0 : 0;

		timeline.to(
			layer.dom.video,
			{
				x: 0,
				scale: 1,
				y: offset,
				duration: 0.6,
				ease: 'power3.out'
			},
			!layer.fullscreen ? 0.2 : 0
		);

		timeline.to(
			[layer.dom.uptitle, layer.dom.title, layer.dom.informationsItems, layer.dom.description],
			{
				y: !layer.fullscreen ? -20 : 0,
				opacity: !layer.fullscreen ? 0 : 1,
				force3D: true,
				stagger: 0.04,
				ease: !layer.fullscreen ? 'power3.in' : 'power3.out'
			},
			!layer.fullscreen ? 0 : 0.4
		);
		timeline.to(
			layer.dom.moduleText,
			{
				opacity: !layer.fullscreen ? 0 : 1,
				ease: 'power3.out'
			},
			!layer.fullscreen ? 0.2 : 0.6
		);

		// timeline.add(()=> {
		// 	const p = MotionPathPlugin.getRelativePosition(layer.dom.mainBar, c1, [1, 1], [1, 1]);
		// 	gsap.to(
		// 		layer.dom.mainBar,
		// 		{
		// 			x: !layer.fullscreen ? p.x : 0,
		// 			y: !layer.fullscreen ? p.y : 0,
		// 			duration: 0.5,
		// 			ease: 'power3.inOut'
		// 		},
		//
		// 	);
		//
		// }, !layer.fullscreen ? 0.2 : 0)
		const p = MotionPathPlugin.getRelativePosition(layer.dom.mainBar, c1, [1, 1], [1, 1]);

		timeline.to(
			layer.dom.mainBar,
			{
				x: !layer.fullscreen ? p.x : 0,
				y: !layer.fullscreen ? p.y : 0,
				duration: 0.6,
				ease: 'power3.out'
			},
			!layer.fullscreen ? 0.2 : 0
		);

		timeline.to(
			layer.dom.opaqueLayer,
			{
				autoAlpha: !layer.fullscreen ? 0.8 : 0,
				duration: 0.3,
				ease: 'none'
			},
			!layer.fullscreen ? 0.2 : 0.1
		);

		timeline.add(() => {
			layer.media.resize();
			layer.dom.mainBar.style.zIndex = !layer.fullscreen ? 8 : 4;

			// if(layer.fullscreen) {
			// 	layer.dom.element.style.overflow = 'auto'
			// }

			layer.fullscreen = !layer.fullscreen ? timeline : null;
		});

		timeline.timeScale(1.2);
	};

	toggleInformationMobile = () => {
		if (!this.currentLayer || !this.sizes.isMobile) return;

		const {header, mobileExpandToggle, mobileExpandLayer} = this.currentLayer.dom;

		this.currentLayer.dom.element.classList.toggle('is-mobile-toggled');

		if (!this.currentLayer.isExpandedOnMobile) {
			gsap.to(header, {y: 0, duration: 0.3, ease: 'sine.out'});
			// gsap.to(mobileExpandToggle, {opacity: 0})
			gsap.to(mobileExpandLayer, {autoAlpha: 0.6, duration: 0.6, ease: 'none'});
		} else {
			gsap.to(header, {y: this.currentLayer.expandValue, duration: 0.3, ease: 'sine.out'});
			// gsap.to(mobileExpandToggle, {opacity: 1})
			gsap.to(mobileExpandLayer, {autoAlpha: 0, duration: 0.6, ease: 'none'});
		}

		this.currentLayer.isExpandedOnMobile = !this.currentLayer.isExpandedOnMobile;
		// Globals.DISABLE_CONTROLS = this.currentLayer.isExpandedOnMobile;
	};

	resize = () => {
		if (this.sizes.isMobile) {
			this.layers.forEach((layer, index) => {
				const descriptionRect = layer.dom.description.getBoundingClientRect();
				const diff = descriptionRect.height - 10;

				layer.expandValue = diff;

				if (!layer.isExpandedOnMobile) {
					gsap.set(layer.dom.header, {y: diff});
				}
			});
		} else {
			this.layers.forEach((layer, index) => {
				if (!layer.isExpandedOnMobile) {
					gsap.to(layer.dom.header, {y: 0, duration: 0.6});
					layer.isExpandedOnMobile = false;
				}
			});
		}

		this.layers.forEach(layer => {
			if (layer.media) {
				layer.media.resize();
			}
		});

		if (!this.currentLayer) return;

		if (this.currentLayer.fullscreen) {
			const c1 = this.currentLayer.dom.element.querySelector('.video-container .main-bar-position');
			const p = MotionPathPlugin.getRelativePosition(this.currentLayer.dom.mainBar, c1, [1, 1], [1, 1]);

			gsap.set(this.currentLayer.dom.mainBar, {
				x: '+=' + p.x,
				y: '+=' + p.y,
				ease: 'sine.inOut'
			});

			// if(this.sizes.isPortrait) {
			// 	this.currentLayer.dom.element.classList.add('is-portrait')
			// } else {
			// 	this.currentLayer.dom.element.classList.remove('is-portrait')
			// }
		}
	};
}
