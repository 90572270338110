/**
 * Created by Lasse on 01/09/15.
 */
import './CookieOptOut.scss';
import {Globals} from '../../../utils/Globals';

export class CookieOptOut {
	disableStr: string;
	alreadyAccepted: string;
	private container: HTMLDivElement;

	constructor() {
		// Disable tracking if the opt-out cookie exists.
		this.disableStr = 'cookie-deny';
		if (document.cookie.indexOf(this.disableStr + '=true') > -1) {
			window[this.disableStr] = true;
		}
		this.alreadyAccepted = 'alreadyAcceptedCookies';
		if (document.cookie.indexOf(this.alreadyAccepted + '=true') == -1 && document.cookie.indexOf(this.disableStr + '=true') == -1) {
			this.showCookieAcceptDialogue();
		} else if (document.cookie.indexOf(this.alreadyAccepted + '=true') >= 0) {
			this.dispatchAcceptedEvent();
		}
	}

	showCookieAcceptDialogue() {
		const danishText = {
			text: 'Vi bruger cookies til målrettet markedsføring, læs mere om vores brug af ',
			link: 'cookies og behandling af persondata.',
			accept: 'Acceptér',
			decline: 'Afvis'
		};

		const englishText = {
			text: 'We use cookies for targeted marketing, read more about our use of',
			link: 'cookies and the processing of personal data.',
			accept: 'Accept',
			decline: 'Decline'
		};

		const currentText = Globals.IS_ENGLISH ? englishText : danishText;

		this.container = document.createElement('div');
		this.container.innerHTML = `<div id="CookieOptOut">
				<p class="cookie-message">${currentText.text}<a target="_blank" href="https://www.sdu.dk/da/om_sdu/om_dette_websted/brug_af_cookies">${currentText.link}</a></p>
				<div class="cookie-dialogue-actions">
					<a class="cookie-dialogue-button cookie-dialogue-accept-button">${currentText.accept}</a>
					<a class="cookie-dialogue-button cookie-dialogue-deny-button">${currentText.decline}</a>
				</div>
			</div>`;
		document.body.appendChild(this.container);
		let buttons = this.container.querySelectorAll('.cookie-dialogue-button');
		buttons[0].addEventListener('click', this.accept);
		buttons[1].addEventListener('click', this.reject);
	}

	accept = event => {
		event.preventDefault();
		document.cookie = this.alreadyAccepted + '=true; expires=Thu, 31 Dec 2099 23:59:59 UTC; path=/';
		this.removeDialog();
		this.dispatchAcceptedEvent();
		return false;
	};

	dispatchAcceptedEvent() {
		var cookieEvent = new CustomEvent('COOKIE_ACCEPTED');
		window.dispatchEvent(cookieEvent);
	}

	reject = event => {
		event.preventDefault();
		document.cookie = this.disableStr + '=true; expires=Thu, 31 Dec 2099 23:59:59 UTC; path=/';
		window[this.disableStr] = true;
		this.removeDialog();
		return false;
	};

	removeDialog() {
		document.body.removeChild(this.container);
	}
}
