import * as THREE from 'three';
import {AudioEngine} from '../audio/AudioEngine';
import Sizes from './Sizes';

//Keep typescript from complaining about touch test.
declare class DocumentTouch {}
new Sizes();
export class Globals {
	// public static DEBUG: boolean = true;
	// public static DEBUG_PAUSE_RENDER: boolean = true;
	// public static SHOW_STATS: boolean = false;
	// public static SKIP_INTRO: boolean = true;
	// public static SHOW_PHYSICS: boolean = true;
	// public static DEBUG_ZONES = false;
	// public static ENABLE_FOG: boolean = false;

	public static DEBUG: boolean = false;
	public static DEBUG_PAUSE_RENDER: boolean = false;
	public static SHOW_STATS: boolean = false;
	public static SKIP_INTRO: boolean = false;
	public static SHOW_PHYSICS: boolean = false;
	public static DEBUG_ZONES = false;
	public static ENABLE_FOG: boolean = true;

	public static I_OS: boolean = (/iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream) || (navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1);
	public static IS_ANDROID: boolean = /Android/i.test(navigator.userAgent);
	public static IS_SAFARI: boolean = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
	public static IS_ENGLISH: boolean = document.querySelector('#app').classList.contains('english-version');

	//@ts-ignore:
	public static IS_TOUCH_DEVICE: boolean = 'ontouchstart' in window || ((window as any).DocumentTouch && document instanceof DocumentTouch); // https://github.com/Modernizr/Modernizr/blob/master/feature-detects/touchevents.js
	public static CURR_COLOR = new THREE.Color();
	public static USE_LOW_RES_TEXTURES = Globals.I_OS || !Sizes.instance.isLargeDesktop;
	public static AUDIO_ENGINE: AudioEngine;
	public static DISABLE_CONTROLS = true;
	public static WEBP_IS_SUPPORTED: boolean = false;
}
