import {gsap} from 'gsap';

class Particle {
	private scale: any;
	public isDead: boolean;
	public object: THREE.Object3D;
	private initialScale: THREE.Vector3;
	private scaleFactor: number = 3;
	private durFactor = 2;
	public opacity = 0.3;
	constructor(model: THREE.Object3D) {
		this.object = model;
		this.isDead = true;
		this.initialScale = model.scale.clone();
	}

	private timeline: gsap.core.Timeline = null;

	emit(rotation: THREE.Quaternion, index: number, position: THREE.Vector3, x: number, z: number) {
		this.isDead = false;

		this.object.position.copy(position);
		this.object.setRotationFromQuaternion(rotation);

		// this.object.material.opacity = 1;
		//
		if (this.timeline) {
			this.timeline.kill();
		}

		this.timeline = gsap.timeline({
			paused: false,
			delay: 0,
			onComplete: () => {
				this.isDead = true;
			}
		});
		// timeline.to(this.object.position, {
		//   duration: 1,
		//   physicsProps: {
		//     y: {velocity: 0, acceleration: -10,friction: 0.05},
		//     x: {velocity: 0, acceleration: params.direction.x,friction: 0.05},
		//     z: {velocity: 0, acceleration: params.direction.z,friction: 0.05}
		//   },
		// }, 0)

		this.timeline.to(this.object.position, {duration: 1 * this.durFactor, y: -0.2, x: x, z: z}, 0);
		this.timeline.fromTo(
			this.object.scale,
			{x: this.initialScale.x, y: this.initialScale.y, z: this.initialScale.z},
			{
				duration: 0.6 * this.durFactor,
				x: this.initialScale.x * this.scaleFactor,
				y: this.initialScale.y * this.scaleFactor,
				z: this.initialScale.z * this.scaleFactor
			},
			0
		);
		this.timeline.fromTo(this, {opacity: 0.3}, {opacity: 0, duration: 1 * this.durFactor}, 0);

		//const a = {b: 1};
		// this.timeline.to(a, {
		//   b: 0,
		//   duration: 1,
		//   onUpdate: () => {
		//     this.object.material.opacity = a.b
		//   }
		// })

		// timeline.delay(params.delay)
	}
}

export default Particle;
