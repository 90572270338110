import ResourcesLoader, {IResourcesLoaderSource} from './ResourcesLoader';

import {GLTF} from 'three/examples/jsm/loaders/GLTFLoader';
import {emitter, EVENTS} from './utils/Dispatcher';

import {Experience} from 'pages/Experience/Experience';

import DotFile from '../assets/models/dotV2.glb';
import TreeFile from '../assets/models/environment/tree.glb';
import BeamTexture from '../assets/images/beam-tex.png';
import DotLinesFile from '../assets/models/environment/dots_spline.glb';

import {Globals} from './utils/Globals';

class Resources {
	public loader: ResourcesLoader;
	public items: {[key: string]: GLTF} = {};
	private datas: Array<IResourcesLoaderSource>;

	constructor(experience: Experience) {
		this.loader = new ResourcesLoader(experience);
		this.datas = [
			{
				name: 'ufo',
				source: 'static/merged/ufo_dock_blender-processed.glb'
			},
			{
				name: 'BeamTexture',
				source: BeamTexture
			},
			{
				name: 'tree',
				source: TreeFile
			},
			{
				name: 'scenes',
				source: 'static/merged/career_desk_busstation_jogger_remotefence_kitchen_sofa_remote-draco.glb'
			},
			{
				name: 'shadows',
				source: 'static/merged/shadows_merged_cleaned-processed.glb'
			},
			{
				name: 'dot',
				source: DotFile
			},
			{
				name: 'all_environment_assets',
				source: 'static/merged/all_assets_1_material_optimized-processed.glb'
			},
			{
				name: 'dotLines',
				source: DotLinesFile
			}
		];

		emitter.on(EVENTS.fileLoaded, this.resourceLoaded);
	}

	checkWebpSupport = callback => {
		if (!window.createImageBitmap) {
			callback(false);
			return;
		}
		var webpdata = 'data:image/webp;base64,UklGRiQAAABXRUJQVlA4IBgAAAAwAQCdASoCAAEAAQAcJaQAA3AA/v3AgAA=';
		fetch(webpdata)
			.then(function(response) {
				return response.blob();
			})
			.then(function(blob) {
				createImageBitmap(blob).then(
					function() {
						callback(true);
					},
					function() {
						callback(false);
					}
				);
			});
	};

	loadResources = () => {
		this.checkWebpSupport(is_supported => {
			if (is_supported) {
				Globals.WEBP_IS_SUPPORTED = true;
			}
			this.loader.load(this.datas);
		});

		// this.loader.load(this.datas);
	};

	private resourceLoaded = fileLoaded => {
		const resource = fileLoaded[0];
		const data = fileLoaded[1];
		this.items[resource.name] = data;

		const progress = this.loader.loaded / this.loader.toLoad;
		emitter.emit(EVENTS.progress, progress);
	};
}

export default Resources;
