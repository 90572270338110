import CANNON from 'cannon';
import Resources from '../Resources';
import * as THREE from 'three';
import Physics from './Physics';
import {GLTF} from 'three/examples/jsm/loaders/GLTFLoader';

interface SituationAssets {
	name: string;
	assets: {
		name: string;
		physics?: string;
		positions: [];
		rotationsY?: any;
		physicsAttributes?: any;
	}[];
}

export class EnvironmentAssets {
	private resources: Resources;
	private scene: THREE.Scene;
	private debug: dat.GUI;
	private assetsPerSituations = [
		{
			name: 'desk',
			assets: [
				{
					name: 'chair',
					positions: [[45.34, 0, -48.02]],
					rotationsY: [-0.42, 0]
				},
				{
					name: 'office_plant',
					positions: [[63.8, 0, -32.86]]
				}
			]
		},
		{
			name: 'kitchen',
			assets: [
				{
					name: 'toys',
					positions: [[27.64, 0, 18.9]]
				},
				{
					name: 'fridge',
					positions: [[20.78, 0, 73.18]],
					rotationsY: -0.74
				}
			]
		},
		{
			name: 'sofa',
			assets: [
				{
					name: 'tv',
					positions: [[-18.64, 0, 37.8]],
					rotationsY: -2.12
				}
			]
		},
		{
			name: 'bus-station',
			assets: [
				{
					name: 'streetlight',
					positions: [[-25.54, 0, -0.56]]
				}
			]
		},
		{
			name: 'career',
			assets: [
				{
					name: 'whiteboard',
					positions: [[14.16, 0, -60.6]],
					rotationsY: -0.44,
					physicsAttributes: {
						mass: 1,
						linearDamping: 0.8,
						angularDamping: 0.99
					}
				}
			]
		},
		{
			name: 'remote',
			assets: [
				{
					name: 'flamingo',
					positions: [[15.24, 0, 36.16]]
				}
			]
		},
		{
			name: 'jogger',
			assets: [
				{
					name: 'remote_rocks',
					positions: [
						[69.62, 0, 1.04],
						[81.7, 0, 5.52]
					],
					rotationsY: [5.14, 0]
				}
			]
		}
	];
	private physics: Physics;
	private assets = [];

	constructor(resources: Resources, scene: THREE.Scene, physics: Physics, debug: dat.GUI) {
		this.resources = resources;
		this.scene = scene;
		this.debug = debug;
		this.physics = physics;

		this.generateAssets();
		this.physics.world.addEventListener('postStep', this.updatePhysics);
		this.createSpecificPhysics();
	}

	private generateAssets() {
		let allAssets = (this.resources.items['all_environment_assets'] as GLTF).scene;
		this.assetsPerSituations.forEach((situation: SituationAssets, index) => {
			situation.assets.forEach(assets => {
				const object = allAssets.getObjectByName(assets.name);
				// object.position.set(0,0,0);
				const physics = assets.physicsAttributes ? (object.getObjectByName('physics') as THREE.Mesh) : null;

				let sizes = null;

				if (physics) {
					physics.material = new THREE.MeshBasicMaterial({wireframe: true, visible: this.physics.showFrame});
					sizes = this.physics.getBounds(physics);
				}
				this.updateMaterial(object);

				assets.positions.forEach((position, j) => {
					const objectCopy = object.clone();
					objectCopy.name = `${assets.name}_${j}`;
					objectCopy.position.set(position[0], position[1], position[2]);

					if (physics) {
						const attributes = assets.physicsAttributes ? assets.physicsAttributes : null;

						let physicsClone = this.createPhysics(sizes, physics, position, assets.rotationsY, attributes);

						this.scene.add(physicsClone.visual);
						this.physics.world.addBody(physicsClone.physics);
						this.physics.registerObject(physicsClone.visual, physicsClone.physics);
						this.physics.registerObject(objectCopy, physicsClone.physics, {x: 0, y: sizes.y * -0.5, z: 0});

						this.assets.push({
							object: object,
							physics: physicsClone.physics
						});
					} else {
						this.assets.push({
							object: object,
							physics: null
						});
					}

					if (assets.rotationsY) {
						if (Array.isArray(assets.rotationsY)) {
							objectCopy.rotation.y = assets.rotationsY[j];
						} else {
							objectCopy.rotation.y = assets.rotationsY;
						}
					}

					this.scene.add(objectCopy);
				});
			});
		});
	}

	private createSpecificPhysics() {
		const assets = [['fridge', 'fridge_2', [16.2, 4.4, 73.8], -0.74]];

		assets.forEach(asset => {
			let fridgeAssets = this.assets.find(w => w.object.name === asset[0]);
			let fridge = fridgeAssets.object.getObjectByName(asset[1]);
			const physics = fridge.clone();
			physics.material = new THREE.MeshBasicMaterial({wireframe: true, visible: this.physics.showFrame});
			const sizes = this.physics.getBounds(physics);
			let physicsClone = this.createPhysics(sizes, physics, asset[2], asset[3], null);
			physicsClone.visual.name = asset[0] + 'physics';
			physicsClone.visual.position.set(0, 0, 0);
			physicsClone.physics.position.y = asset[2][1];

			if (this.physics.showFrame) {
				this.scene.add(physicsClone.visual);
				this.physics.registerObject(physicsClone.visual, physicsClone.physics);
			}
			this.physics.world.addBody(physicsClone.physics);
		});
	}

	private createPhysics(sizes, model, position, rotation, attributes, useSpring = true) {
		const visual = model.clone();
		const physicsBody = new CANNON.Body({
			mass: attributes ? attributes.mass : 0,
			position: new CANNON.Vec3(position[0], sizes.y * 0.5, position[2]),
			shape: new CANNON.Box(new CANNON.Vec3(sizes.x * 0.5, sizes.y * 0.5, sizes.z * 0.5)),
			material: this.physics.assetsMaterial
		});

		if (attributes) {
			physicsBody.linearDamping = 0.7;
			physicsBody.angularDamping = 0.8;
			physicsBody.updateMassProperties();
		}

		if (rotation) {
			physicsBody.quaternion.setFromAxisAngle(new CANNON.Vec3(0, 1, 0), rotation);
		}

		if (useSpring) {
			// Create a static sphere
			var sphereShape = new CANNON.Sphere(0.1);
			var sphereBody = new CANNON.Body({mass: 0, position: new CANNON.Vec3(position[0], 0, position[2])});
			sphereBody.addShape(sphereShape);
			this.physics.world.addBody(sphereBody);
			// demo.addVisual(sphereBody);

			// @ts-ignore
			var spring = new CANNON.Spring(sphereBody, physicsBody, {
				localAnchorA: new CANNON.Vec3(sizes.x, 0, sizes.z),
				localAnchorB: new CANNON.Vec3(0, 0, 0),
				restLength: 5, // Lower this to 1 for bouncy spring;
				stiffness: 50,
				damping: 100
			});

			// Compute the force after each step
			this.physics.world.addEventListener('postStep', function(event) {
				spring.applyForce();
			});
		}

		return {physics: physicsBody, visual};
	}

	private updatePhysics = () => {
		this.assets.forEach((asset, index) => {
			if (!asset.physics) return;
			asset.physics.quaternion.x = 0;
			asset.physics.quaternion.z = 0;
			// asset.physics.position.y = 0
		});
	};

	private shadowUpdated = false;

	private updateMaterial(object: THREE.Object3D) {
		object.traverse((mesh: THREE.Mesh) => {
			if (mesh.isMesh) {
				let material = mesh.material as THREE.MeshStandardMaterial;
				if (mesh.name.indexOf('shadow') >= 0) {
					if (!this.shadowUpdated && (material as THREE.MeshStandardMaterial).emissiveMap) {
						this.shadowUpdated = true;
						material.alphaMap = (material as THREE.MeshStandardMaterial).emissiveMap;
						material.emissiveMap = null;
						material.emissiveIntensity = 0;
						material.premultipliedAlpha = true;
						material.fog = true;
						material.color.set(0x000000);
						material.flatShading = true;
						material.transparent = true;
						material.opacity = 0.5;
						material.needsUpdate = true;
						material.depthWrite = false;
					}
					// w.material.visible = false;
				} else {
					material.roughness = 0.5;
					material.metalness = 0;
					material.needsUpdate = true;
				}
			}
		});
	}
}
