import './Experience.scss';
import {OverallMuteButton} from '../../components/OverallMuteButton';
import {WEBGL} from 'three/examples/jsm/WebGL.js';

import {gsap} from 'gsap';
import * as THREE from 'three';

import Dat from 'dat.gui';
import init from 'three-dat.gui'; // Import initialization method
init(Dat); // Init three-dat.gui with Dat

import {emitter, EVENTS} from '../../../utils/Dispatcher';
import {partial} from '../../../utils/partial';
import Sizes from '../../../utils/Sizes';
import {Camera} from '../../../world/Camera';
import {EnvironmentAssets} from '../../../world/EnvironmentAssets';
import Physics from '../../../world/Physics';

import {WorldRenderer} from '../../../world/Renderer';
import {ISituationConfig, SituationManager} from '../../../world/SituationManager';
import {SituationsPaths} from '../../../world/SituationsPaths';
import Trees from '../../../world/Trees';
import {Ufo} from '../../../world/Ufo';
import {UFODock} from '../../../world/UFODock';
import FastTravelUI from '../../components/FastTravelUI/FastTravelUI';
import './Experience.scss';
import Resources from '../../../Resources';
import {Globals} from '../../../utils/Globals';
import Stats from 'three/examples/jsm/libs/stats.module';

export class Experience {
	private domElement: HTMLElement;
	private domApp: HTMLElement;
	private resources: Resources;
	public camera: Camera;
	private debugCamera: Camera;
	private scene: THREE.Scene;
	private ufo: Ufo;
	private physics: Physics;
	private mainCamera;
	private sizes: Sizes;
	private debug: Dat.GUI = null;
	private directionalLight: THREE.DirectionalLight;
	private situations: SituationManager;
	private subDistance = new THREE.Vector3();
	private cameraTargetOffset = new THREE.Vector3(3, 4, 3);
	private situationCopyPosition = new THREE.Vector3();
	private currentTheme = 'light-theme';
	private initialTheme = 'light-theme';
	public renderer: WorldRenderer;
	public DISABLE_RENDERING = true;
	private transitionColors = {
		start: new THREE.Color(0xdcbeb7),
		target: new THREE.Color(0xdcbeb7),
		current: new THREE.Color(0xdcbeb7)
	};
	private registeredInRange = {
		camera: false,
		ufo: false,
		armAnimation: false,
		layerAppear: false,
		themeColor: false,
		backgroundColor: false,
		mobileHideUi: false,
		inDockArea: true
	};
	private fog: THREE.Fog;
	private fastTravelUI: FastTravelUI;
	private allResourcesLoaded: boolean;
	private dots: SituationsPaths;
	private ufoIsTravelling: boolean;
	private targetSituation: ISituationConfig = null;
	private currentSituation: ISituationConfig = null;
	private resizedHappened: Boolean = true;
	private stats: Stats;
	private tlLight = gsap.timeline({paused: true, duration: 1});
	private DOMWebGL: Element;
	private DOMOverallMute: Element;
	private overallMute: OverallMuteButton;
	private dock: UFODock;

	static SELECTORS = {
		ELEMENT: '.experience-container',
		WEBGL_CONTAINER: '.webgl-container',
		OVERALL_MUTE: '.overall-mute',
		ARROW_NAVIGATION_LEFT: '.mobile-left-navigation',
		ARROW_NAVIGATION_RIGHT: '.mobile-right-navigation'
	};
	private DOMArrowNavigationLeft: Element;
	private DOMArrowNavigationRight: Element;

	constructor(app: HTMLElement) {
		const {ELEMENT, WEBGL_CONTAINER, OVERALL_MUTE, ARROW_NAVIGATION_LEFT, ARROW_NAVIGATION_RIGHT} = Experience.SELECTORS;

		this.domApp = app;
		this.domElement = this.domApp.querySelector(ELEMENT);
		this.DOMWebGL = this.domElement.querySelector(WEBGL_CONTAINER);
		this.DOMOverallMute = this.domElement.querySelector(OVERALL_MUTE);
		this.DOMArrowNavigationLeft = this.domApp.querySelector(ARROW_NAVIGATION_LEFT);
		this.DOMArrowNavigationRight = this.domApp.querySelector(ARROW_NAVIGATION_RIGHT);
		this.sizes = new Sizes();
		if (Globals.SHOW_STATS) {
			// @ts-ignore
			this.stats = new Stats();
			this.stats.showPanel(0); // 0: fps, 1: ms, 2: mb, 3+: custom
			document.body.appendChild(this.stats.dom);
		}
		this.init();
		this.bindEvents();
	}

	bindEvents() {
		// emitter.on(EVENTS.tick, this.raf);
		emitter.on(EVENTS.debug, this.toggleDebug);
		emitter.on(EVENTS.resize, this.resize);
		emitter.on(EVENTS.firstMove, this.ufoStartedToMove);
		emitter.on(EVENTS.travelFast, this.travelFast);

		this.DOMArrowNavigationLeft.addEventListener('click', () => {
			this.arrowNavigation('left');
		});

		this.DOMArrowNavigationRight.addEventListener('click', () => {
			this.arrowNavigation('right');
		});
	}

	init() {
		if (Globals.DEBUG) {
			this.debug = new Dat.GUI({width: 420});
		} else {
			this.debug = null;
		}
		// @ts-ignore
		this.debug && this.debug.addVector('Camera target offset', this.cameraTargetOffset);

		this.situations = new SituationManager(this.domApp, this.debug);
		this.fastTravelUI = new FastTravelUI(this.domApp);
		this.overallMute = new OverallMuteButton(this.DOMOverallMute);

		this.createWorld();
		this.createCamera();
		this.createLight();
		this.resize();
	}

	/**
	 * Init renderer & scene
	 */
	public createWorld() {
		if (Globals.IS_ANDROID && WEBGL.isWebGL2Available() === true) {
			var canvas = document.createElement('canvas');
			var context = canvas.getContext('webgl2', {alpha: false});
			this.renderer = new WorldRenderer({
				canvas: canvas,
				context: context,
				antialias: true,
				powerPreference: 'high-performance'
			});
		} else {
			this.renderer = new WorldRenderer({
				antialias: true,
				powerPreference: 'high-performance'
			});
		}
		this.DOMWebGL.appendChild(this.renderer.domElement);

		this.scene = new THREE.Scene();
		this.fog = new THREE.Fog(0xdec3bc, 100, 130);
		// this.scene.overrideMaterial = new THREE.MeshBasicMaterial( { color: 'green' } );

		if (Globals.ENABLE_FOG) {
			this.scene.fog = this.fog;
		}

		// @ts-ignore
		this.debug && this.debug.addFog('Fog', this.fog);

		this.physics = new Physics(this.scene);

		if (this.debug) {
			this.scene.add(new THREE.AxesHelper(10));
		}

		(window as any).THREE = THREE;
		(window as any).scene = this.scene;
	}

	private createCamera() {
		this.camera = new Camera(this.renderer, this.scene, false, this.debug, this.fog);
		this.camera.instance.position.set(0, 100, 0);
		// this.camera.instance.position.set(8.3, 11.5, -10.7);
		// Create debug camera
		this.debugCamera = new Camera(this.renderer, this.scene, !!this.debug, this.debug, this.fog);
		this.debugCamera.instance.position.set(-150, 150, 40);
		this.mainCamera = this.camera;

		(window as any).camera = this.camera;
	}

	/**
	 * Create directional light
	 */
	private createLight() {
		this.directionalLight = new THREE.DirectionalLight();
		this.directionalLight.intensity = 1.1;
		this.directionalLight.position.set(-50, 27.68, 42.24);
		// this.directionalLight.position.set(-50, 1.72, 1.62);
		this.directionalLight.name = 'Direction light';
		this.scene.add(this.directionalLight);

		this.tlLight.fromTo(this.directionalLight, {intensity: 0.5}, {intensity: 1.1}, 0);
		this.tlLight.to(this.directionalLight.position, {y: 1.72, z: 1.62}, 0);
		// @ts-ignore
		this.debug && this.debug.addLight('Directional Light', this.directionalLight);
	}

	/**
	 * Enable/disable camera debug
	 * @param isDebug
	 */
	private toggleDebug = isDebug => {
		this.mainCamera = isDebug ? this.debugCamera : this.camera;
		(window as any).camera = this.mainCamera;
	};

	public getUfoReady = (resources: Resources) => {
		this.ufo = new Ufo(resources, this.scene, this.physics, this.mainCamera, this.situations, this.renderer, this.debug);
		this.scene.add(this.ufo.container);
		this.physics.world.addBody(this.ufo.movementPhysics);
		this.physics.world.addBody(this.ufo.ufoPhysics);
	};

	public populateWorld = (resources: Resources) => {
		this.resources = resources;

		const environment = new EnvironmentAssets(this.resources, this.scene, this.physics, this.debug);
		const trees = new Trees(this.resources, this.scene, this.physics, this.debug);

		this.getUfoReady(resources);

		// this.clouds = new Clouds(this.resources, this.scene, this.physics);
		this.situations.init(this.resources);
		this.scene.add(this.situations.group);
		this.scene.add(this.situations.zones);

		this.dock = new UFODock(this.resources, this.situations, this.scene);
		this.dots = new SituationsPaths(this.resources, this.situations, this.scene, this.debug);

		// Hack to queue the raf function in the good order (Bad)
		emitter.on(EVENTS.tick, this.raf);

		this.preCompile();
		this.allResourcesLoaded = true;
	};

	animateIn = () => {
		const tl = gsap.timeline();
		this.DISABLE_RENDERING = false;
		emitter.emit(EVENTS.tick);

		this.camera.target.copy(this.ufo.container.position);
		this.camera.target.y = 4;
		tl.add(this.ufo.animateIn(), 0);
		tl.add(this.camera.experienceIn(), 0);
		if (!this.sizes.isMobile) {
			tl.add(this.overallMute.animateIn(), 1);
		}

		if (Globals.SKIP_INTRO) {
			tl.timeScale(10);
		}
		tl.add(() => Globals.AUDIO_ENGINE.toggleMute(false, 2), 0.1);
		this.dots.animateIn();
		Globals.AUDIO_ENGINE.start();
		Globals.AUDIO_ENGINE.toggleMute(true, 0);
	};

	ufoStartedToMove = () => {
		if (this.sizes.isMobile) {
			gsap.to([this.DOMArrowNavigationLeft.querySelector('svg'), this.DOMArrowNavigationRight.querySelector('svg')], {autoAlpha: 1, x: 0, duration: 0.6, ease: 'power3.out', delay: 3});
		}
		this.fastTravelUI.animateIn();
	};

	travelTimeline = null;
	ufoDistanceFromSituation = new THREE.Vector3();

	private travelFast = slug => {
		if (this.ufo.isTravelling && this.travelTimeline) {
			this.travelTimeline.kill();
		}

		this.ufo.isTravelling = true;
		this.camera.isTransitioning = true;

		let situation = null;

		this.ufoDistanceFromSituation.copy(this.ufo.container.position);
		this.ufoDistanceFromSituation.y = 0;

		this.ufo.leftParticles.stopEmit();
		this.ufo.rightParticles.stopEmit();

		let distance = 0;
		let target = null;

		if (slug === 'home') {
			target = new THREE.Vector3(23.66, 4, -17.38);
			distance = target.distanceTo(this.ufoDistanceFromSituation);
		} else {
			situation = this.situations.getSituation(slug);
			target = situation.position.clone();
			target.add(situation.currCameraTargetOffset);
			distance = situation.centerPosition.distanceTo(this.ufoDistanceFromSituation);
			this.targetSituation = situation;
		}

		if (this.ufo.beam.isEmitting && this.currentSituation) {
			this.ufo.beam.stopCast();
			const p1 = {v: 1};
			gsap.to(p1, {
				v: 0,
				duration: 0.3,
				ease: 'out',
				onUpdate: () => {
					this.ufo.beam.setHeight(this.currentSituation.loopHeight * p1.v);
				}
			});
		}

		this.travelTimeline = gsap.timeline({
			onStart: () => {
				if (this.currentSituation) {
					this.hideLayer(this.currentSituation);
				}
			},
			onComplete: () => {
				// this.ufo.beam.cast();
				if (situation) {
					this.showLayer(situation);
				}
				if (slug === 'home') {
					this.camera.isTransitioning = false;
				}
				this.targetSituation = null;
				this.ufo.isTravelling = false;
				this.currentSituation = situation;
			}
		});
		this.travelTimeline.to(this.mainCamera.target, {x: target.x, y: target.y, z: target.z, duration: 5, ease: 'power3.inOut'}, 0);

		if (slug === 'home') {
			this.travelTimeline.add(this.ufo.teleportTo(target, 0), 0);
		} else {
			this.travelTimeline.add(this.ufo.teleportTo(situation.centerPosition, situation.loopHeight), 0);
		}

		if (situation) {
			const p = {v: 0};
			this.travelTimeline.to(p, {
				v: 1,
				duration: 0.8,
				ease: 'sine.inOut',
				onStart: () => {
					this.ufo.beam.easeFactor = 1;
					this.ufoBeamCast();
				},
				onUpdate: () => {
					this.ufo.beam.setHeight(situation.loopHeight * p.v);
				},
				onComplete: () => {
					this.ufo.beam.easeFactor = 0.1;
				}
			});
		}

		this.travelTimeline.timeScale(2 - distance / 172);
	};

	private preCompile() {
		this.renderer.compile(this.scene, this.camera.instance);
		this.renderer.render(this.scene, this.mainCamera.instance);
	}

	public resize = () => {
		this.renderer.resize();

		if (this.sizes.isTablet || this.sizes.isMobile) {
			this.cameraTargetOffset.set(-4.6, -1.5, 3);
		} else {
			this.cameraTargetOffset.set(3, 4, 3);
		}
		this.resizedHappened = true;
	};

	arrowNavigation = direction => {
		this.situations.updateSituationsDistancesToUfoArray(this.ufo.container.position);
		const closestSituation = this.situations.situationDistancesToUfo;
		let next = closestSituation[0];

		if (this.registeredInRange.camera) {
			const index = this.situations.getIndex(this.currentSituation.slug);
			if (index < 0) return;
			const length = this.situations.getLength();
			let nextIndex = direction === 'left' ? index - 1 : index + 1;
			if (nextIndex < 0) nextIndex = length - 1;
			else if (nextIndex > length - 1) nextIndex = 0;
			next = this.situations.getSituationByIndex(nextIndex);
		} else {
			for (let i = 0; i < closestSituation.length; i++) {
				let situation = closestSituation[i];
				const copy = this.ufo.container.position.clone();
				const diff = copy.sub(situation.position).negate();

				if (direction === 'left' && diff.z < 0) {
					next = situation;
					break;
				} else if (direction === 'right' && diff.z > 0) {
					next = situation;
					break;
				}
			}
		}

		this.travelFast(next.slug);
	};

	private tCalc = new THREE.Vector3();
	checkUfoRanges = () => {
		this.situations.updateSituationsDistancesToUfoArray(this.ufo.container.position);
		const closestSituation = this.situations.situationDistancesToUfo[0];

		// NOTE: updating angle to ufo for each situation

		let s = this.situations.situationDistancesToUfo;
		let angle;

		s.forEach((w, i) => {
			this.tCalc.copy(w.position);
			this.tCalc.sub(this.ufo.container.position);
			angle = (-Math.atan2(this.tCalc.z, this.tCalc.x) * 180) / Math.PI;
			w.angle = ((angle % 360) + 360) % 360;
		});

		let distanceFormDock = this.dock.getDistanceFromUfo(this.ufo.container.position);

		//@TODO: Reformat and refactor this function
		// Check if the ufo enter the dock area
		if (distanceFormDock > 10 && this.registeredInRange.inDockArea) {
			this.registeredInRange.inDockArea = false;
			this.fastTravelUI.homeIcon.classList.remove('is-active');
		} else if (distanceFormDock < 10 && !this.registeredInRange.inDockArea) {
			this.registeredInRange.inDockArea = true;
			this.fastTravelUI.homeIcon.classList.add('is-active');
		}

		if (closestSituation === this.targetSituation || this.targetSituation == null || closestSituation == this.currentSituation) {
			Globals.AUDIO_ENGINE.updateSituations(this.situations);

			this.currentSituation = closestSituation;

			this.isUfoInRangeOf('backgroundColor', closestSituation, 20, 12, this.updateGlobalColors, null, true);
			if (!this.ufo.isTravelling) {
				this.isUfoInRangeOf('ufo', closestSituation, 12, 2, this.ufo.situationProgress, null, true);
				this.isUfoInRangeOf('layerAppear', closestSituation, 12, 14, this.showLayer, this.hideLayer);
			}
			this.isUfoInRangeOf('camera', closestSituation, 20, 12, this.cameraIn, this.cameraOut, true);
			this.isUfoInRangeOf('themeColor', closestSituation, 18, 18, this.updateTheme, this.backToTheme);
			this.isUfoInRangeOf('armAnimation', closestSituation, 18, 0, this.triggerArmUp);
			if (!this.sizes.isLaptop) {
				this.isUfoInRangeOf('mobileHideUi', closestSituation, 16, 18, this.showUIOnMobile, this.hideUIOnMobile);
			}
		}
	};

	showUIOnMobile = () => {
		this.fastTravelUI.toggle({value: true});
	};

	hideUIOnMobile = () => {
		this.fastTravelUI.toggle({value: false});
	};

	isUfoInRangeOf(
		type: string,
		situation: ISituationConfig,
		startRange: number,
		endRange: number,
		inCallback: (situation: ISituationConfig, percentage?: number) => void = null,
		outCallback: Function = null,
		isProgressive = false
	) {
		if (situation.distance < startRange) {
			if (isProgressive) {
				let perc = gsap.utils.clamp(0, 1, 1 - (situation.distance - endRange) / (startRange - endRange));
				inCallback && inCallback(situation, perc);
			} else if (!this.registeredInRange[type]) {
				inCallback && inCallback(situation);
			}
			this.registeredInRange[type] = true;
		} else if (this.registeredInRange[type] && situation.distance > endRange) {
			this.registeredInRange[type] = false;
			outCallback && outCallback(situation);
		}
	}

	private colorPercentage = 0;
	updateGlobalColors = (situation: ISituationConfig, percentage) => {
		const color = situation.color;
		if (color.equals(this.transitionColors.target) === false) {
			this.transitionColors.target.copy(color);
			this.transitionColors.start.copy(this.transitionColors.current);
		}
		if (this.colorPercentage !== percentage) {
			this.transitionColors.current.copy(this.transitionColors.start);

			Globals.CURR_COLOR.copy(this.transitionColors.current.lerp(this.transitionColors.target, percentage));
			this.renderer.updateColor(Globals.CURR_COLOR);
			this.directionalLight.color.copy(Globals.CURR_COLOR);
			gsap.to(this.tlLight, {duration: 1, overwrite: 'auto', progress: percentage, ease: 'none'});
			this.fog.color.copy(Globals.CURR_COLOR);
			this.colorPercentage = percentage;
		}
	};

	updateTheme = (situation: ISituationConfig) => {
		if (this.currentTheme != situation.themeColor) {
			document.body.classList.add(situation.themeColor);
			this.currentTheme = situation.themeColor;
		}
	};

	backToTheme = () => {
		if (this.initialTheme != this.currentTheme) {
			document.body.classList.remove(this.currentTheme);
			document.body.classList.add(this.initialTheme);
			this.currentTheme = this.initialTheme;
		}
	};

	showLayer = (situation: ISituationConfig) => {
		if (!this.fastTravelUI.introEnded) {
			this.fastTravelUI.hideIintroductionArrow();
		}

		this.situations.showLayer(situation.slug);

		if (this.sizes.isLaptop) {
			this.fastTravelUI.showCurrent(situation.slug);
		}

		if (!this.ufo.isTravelling) {
			this.ufoBeamCast();
		}

		emitter.emit(EVENTS.fadeAssets, true);
	};

	ufoBeamCast = () => {
		this.ufo.beam.cast(this.currentSituation);
		this.ufo.leftParticles.stopEmit();
		this.ufo.rightParticles.stopEmit();
	};

	ufoStopCast = () => {
		this.ufo.beam.stopCast();
		this.ufo.leftParticles.startEmit();
		this.ufo.rightParticles.startEmit();
	};

	hideLayer = (situation: ISituationConfig) => {
		this.situations.hideLayer();
		// this.fastTravelUI.hideCurrent(situation.slug);

		if (this.sizes.isLaptop) {
			this.fastTravelUI.hideCurrent(situation.slug);
		}

		if (!this.ufo.isTravelling) {
			this.ufoStopCast();
		}

		emitter.emit(EVENTS.fadeAssets, false);
	};

	triggerArmUp(situation: ISituationConfig) {
		situation.sceneObject.armUp();

		Globals.AUDIO_ENGINE.triggerHello(situation);
	}

	private currCameraZoomPercentage = 0;
	cameraIn = (situation: ISituationConfig, percentage) => {
		// this.currCameraZoomPercentage += (percentage - this.currCameraZoomPercentage) * 0.04;
		// gsap.to(this.camera.tl, {duration: 2, overwrite: "auto", progress: percentage, ease: 'none'});
		if (this.currCameraZoomPercentage !== percentage || this.resizedHappened) {
			this.currCameraZoomPercentage = percentage;
			// this.camera.tl.progress(this.currCameraZoomPercentage);
			gsap.to(this.camera.tl, {duration: 1, overwrite: true, progress: percentage /*, ease: 'none'*/});
			gsap.to(this.camera.tlFog, {duration: 1, overwrite: true, progress: percentage /*, ease: 'none'*/});
			this.camera.isTransitioning = true;

			if (!this.ufo.isTravelling) {
				this.mainCamera.slowFactor = 0.8;
				this.situationCopyPosition.copy(situation.position).add(situation.currCameraTargetOffset);
				this.subDistance.lerpVectors(this.ufo.container.position, this.situationCopyPosition, this.currCameraZoomPercentage);
				this.camera.target.copy(this.subDistance);
			}
			this.resizedHappened = false;
		}
		// console.log('CAMERA IN');
		// console.log('camera in', percentage, this.currCameraZoomPercentage);
	};

	cameraOut = () => {
		if (!this.ufo.isTravelling) {
			this.mainCamera.isTransitioning = false;
			this.mainCamera.slowFactor = 0.8;
		}
	};

	public raf = () => {
		if (this.DISABLE_RENDERING) {
			return;
		}
		if (this.stats) {
			this.stats.begin();
		}

		// monitored code goes here

		if (this.ufo && !this.camera.isTransitioning) {
			this.camera.target.copy(this.ufo.container.position);
		}

		if (this.ufo && this.allResourcesLoaded) {
			this.checkUfoRanges();
		}

		this.renderer.render(this.scene, this.mainCamera.instance);
		if (this.stats) {
			this.stats.end();
		}
	};

	render() {
		this.domApp.appendChild(this.domElement);
	}
}
