import Tone from 'tone';
import {emitter, EVENTS} from '../utils/Dispatcher';
import {gsap} from 'gsap';
import {clamp} from 'gsap/gsap-core';
import {getRandomInt} from '../utils/helpers';
import {ISituationConfig, SituationManager} from '../world/SituationManager';
import {AudioSituations} from './AudioSituations';
import {Globals} from '../utils/Globals';

export class AudioEngine {
	private _buffers: Tone.Buffers;
	private _sampleUrls;

	private _player: Tone.Player;
	private _engineChannel: Tone.Channel;

	private _mono: Tone.Mono;

	private _engineBreathPlayer: Tone.Player;

	private _engineNoise: Tone.Player;

	private _ambiencePlayer: Tone.Player;
	private _ambienceChannel: Tone.Channel;

	private _engineMasterChannel: Tone.Channel;

	private _hufChannel: Tone.Channel;
	private _hufEffect;

	private _engineWarmupPlayer: Tone.Player;

	private _engineSpeed: number = 0;

	private _effect;
	public muted: boolean = true;

	private _globalParams = {
		volume: -100,
		hufVolume: -22,
		engineVolume: -15,
		voiceVolume: -10,
		ambienceVolume: -20,
		warmup: 0
	};

	private _players = [];

	private _masterChannel: Tone.Channel;
	private _masterReverb;

	private _highpassFilter: Tone.Filter;
	private _lowpassFilter: Tone.Filter;

	private _situations: AudioSituations;

	private _enginePhaser: Tone.Phaser;

	private _warmupActive: boolean = false;
	private _beamActive: boolean = false;

	private _beamPulsePlayer: Tone.Player;

	constructor() {
		if (Globals.IS_ENGLISH) {
			this._sampleUrls = {
				charge_up: require('/assets/audio/ufo/charge_up.mp3'),
				bg_ambience: require('/assets/audio/ambience/bg_ambience_1.mp3'),
				engine_breathe: require('/assets/audio/ufo/engine_breathe.mp3'),
				engine_huf: require('/assets/audio/ufo/engine_huf.mp3'),
				engine_noise: require('/assets/audio/ufo/engine_noise.mp3'),
				engine_warmup: require('/assets/audio/ufo/engine_warmup.mp3'),
				beam_pulse: require('/assets/audio/ufo/beam_pulse_1.mp3'),
				border_impact: require('/assets/audio/general/border_impact.mp3'),

				hello_male_01: require('/assets/audio/voice/en/voice_male_en_01.mp3'),
				hello_male_02: require('/assets/audio/voice/en/voice_male_en_02.mp3'),
				hello_male_03: require('/assets/audio/voice/en/voice_male_en_03.mp3'),
				hello_male_04: require('/assets/audio/voice/en/voice_male_en_04.mp3'),
				hello_male_07: require('/assets/audio/voice/en/voice_male_en_07.mp3'),
				hello_male_08: require('/assets/audio/voice/en/voice_male_en_08.mp3'),
				hello_male_12: require('/assets/audio/voice/en/voice_male_es_01.mp3'),
				hello_male_13: require('/assets/audio/voice/en/voice_male_es_02.mp3'),
				hello_male_14: require('/assets/audio/voice/en/voice_male_es_03.mp3'),
				hello_male_15: require('/assets/audio/voice/en/voice_male_es_04.mp3'),
				hello_male_16: require('/assets/audio/voice/en/voice_male_es_05.mp3'),

				hello_female_01: require('/assets/audio/voice/en/voice_female_en_01.mp3'),
				hello_female_02: require('/assets/audio/voice/en/voice_female_en_02.mp3'),
				hello_female_03: require('/assets/audio/voice/en/voice_female_en_03.mp3'),
				hello_female_04: require('/assets/audio/voice/en/voice_female_en_04.mp3'),
				hello_female_06: require('/assets/audio/voice/en/voice_female_en_06.mp3'),
				hello_female_07: require('/assets/audio/voice/en/voice_female_en_07.mp3'),
				hello_female_08: require('/assets/audio/voice/en/voice_female_en_08.mp3'),
				hello_female_10: require('/assets/audio/voice/en/voice_female_en_10.mp3'),
				hello_female_11: require('/assets/audio/voice/en/voice_female_en_11.mp3'),
				hello_female_12: require('/assets/audio/voice/en/voice_female_en_12.mp3'),
				hello_female_13: require('/assets/audio/voice/en/voice_female_en_13.mp3'),
				hello_female_14: require('/assets/audio/voice/en/voice_female_en_14.mp3'),
				hello_female_15: require('/assets/audio/voice/en/voice_female_en_15.mp3'),

				busstation: require('/assets/audio/ambience/situation_train_1.mp3'),
				desk: require('/assets/audio/ambience/situation_desk_1.mp3'),
				sofa: require('/assets/audio/ambience/situation_sofa_1.mp3'),
				kitchen: require('/assets/audio/ambience/situation_dishwashing2_1.mp3'),
				career: require('/assets/audio/ambience/situation_office_1.mp3'),
				remotefence: require('/assets/audio/ambience/situation_farm_1.mp3'),
				remote: require('/assets/audio/ambience/situation_beach_1.mp3'),
				jogger: require('/assets/audio/ambience/situation_running_1.mp3')
			};
		} else {
			this._sampleUrls = {
				charge_up: require('/assets/audio/ufo/charge_up.mp3'),
				bg_ambience: require('/assets/audio/ambience/bg_ambience_1.mp3'),
				engine_breathe: require('/assets/audio/ufo/engine_breathe.mp3'),
				engine_huf: require('/assets/audio/ufo/engine_huf.mp3'),
				engine_noise: require('/assets/audio/ufo/engine_noise.mp3'),
				engine_warmup: require('/assets/audio/ufo/engine_warmup.mp3'),
				beam_pulse: require('/assets/audio/ufo/beam_pulse_1.mp3'),
				border_impact: require('/assets/audio/general/border_impact.mp3'),

				hello_male_01: require('/assets/audio/voice/voice_male_01_1.mp3'),
				hello_male_02: require('/assets/audio/voice/voice_male_02_1.mp3'),
				hello_male_03: require('/assets/audio/voice/voice_male_03_1.mp3'),
				hello_male_04: require('/assets/audio/voice/voice_male_04_1.mp3'),
				hello_male_07: require('/assets/audio/voice/voice_male_07_1.mp3'),
				hello_male_08: require('/assets/audio/voice/voice_male_08_1.mp3'),
				hello_male_12: require('/assets/audio/voice/voice_male_12_1.mp3'),
				hello_male_15: require('/assets/audio/voice/voice_male_15_1.mp3'),

				hello_female_01: require('/assets/audio/voice/voice_female_01_1.mp3'),
				hello_female_02: require('/assets/audio/voice/voice_female_02_1.mp3'),
				hello_female_03: require('/assets/audio/voice/voice_female_03_1.mp3'),
				hello_female_04: require('/assets/audio/voice/voice_female_04_1.mp3'),
				hello_female_06: require('/assets/audio/voice/voice_female_06_1.mp3'),
				hello_female_07: require('/assets/audio/voice/voice_female_07_1.mp3'),
				hello_female_08: require('/assets/audio/voice/voice_female_08_1.mp3'),
				hello_female_10: require('/assets/audio/voice/voice_female_10_1.mp3'),
				hello_female_11: require('/assets/audio/voice/voice_female_11_1.mp3'),
				hello_female_12: require('/assets/audio/voice/voice_female_12_1.mp3'),
				hello_female_13: require('/assets/audio/voice/voice_female_13_1.mp3'),
				hello_female_14: require('/assets/audio/voice/voice_female_14_1.mp3'),
				hello_female_15: require('/assets/audio/voice/voice_female_15_1.mp3'),

				busstation: require('/assets/audio/ambience/situation_train_1.mp3'),
				desk: require('/assets/audio/ambience/situation_desk_1.mp3'),
				sofa: require('/assets/audio/ambience/situation_sofa_1.mp3'),
				kitchen: require('/assets/audio/ambience/situation_dishwashing2_1.mp3'),
				career: require('/assets/audio/ambience/situation_office_1.mp3'),
				remotefence: require('/assets/audio/ambience/situation_farm_1.mp3'),
				remote: require('/assets/audio/ambience/situation_beach_1.mp3'),
				jogger: require('/assets/audio/ambience/situation_running_1.mp3')
			};
		}
	}

	private handleVisibilityChange = () => {
		if (!document.hidden && !this.muted) {
			gsap.to(this._masterChannel.volume, 1, {value: 0, ease: 'none', overwrite: true});
		} else if (document.hidden) {
			gsap.killTweensOf(this._masterChannel.volume);
			this._masterChannel.volume.value = -80;
		}
	};

	public loadAssets = () => {
		this._buffers = new Tone.Buffers(this._sampleUrls, {
			onload: this.assetsLoaded
		});
	};

	public start = () => {
		this._situations.start();

		this._ambiencePlayer.start();

		this._player.start();
		this._engineBreathPlayer.start();
		this._engineNoise.start();

		this.toggleMute(false, 2);

		document.addEventListener('visibilitychange', this.handleVisibilityChange);
	};

	private assetsLoaded = () => {
		// Master channel
		this._masterReverb = new Tone.JCReverb(0.4).toMaster();
		this._masterReverb.wet.value = 0.08;
		this._masterChannel = new Tone.Channel(-80, 0).connect(this._masterReverb);

		this.setupEngine();

		// Bg ambience
		this._ambienceChannel = new Tone.Channel(this._globalParams.volume, 0);
		this._ambienceChannel.connect(this._masterChannel);

		this._ambiencePlayer = new Tone.Player({
			loop: true,
			loopEnd: 10.054125
		}).connect(this._ambienceChannel);

		this._ambiencePlayer.buffer = this._buffers.get('bg_ambience');

		// Fade in global ambience
		gsap.to(this._globalParams, {volume: -35, duration: 1, onUpdate: this.updateGlobalParams});

		// Init situations audio
		this._situations = new AudioSituations(this._buffers, this._masterChannel);

		emitter.emit(EVENTS.audioAssetsLoaded);
	};

	private setupEngine = () => {
		this._engineMasterChannel = new Tone.Channel(0, 0).connect(this._masterChannel);

		// Huf
		let hufEQ = new Tone.EQ3(-8, 0, 0).connect(this._engineMasterChannel);
		this._hufEffect = new Tone.AutoWah({});
		this._hufChannel = new Tone.Channel(this._globalParams.hufVolume, 0).connect(hufEQ);
		this._hufEffect.connect(this._hufChannel);

		// Ufo Engine
		this._engineChannel = new Tone.Channel(this._globalParams.engineVolume, 0);
		this._mono = new Tone.Mono().connect(this._engineMasterChannel);

		this._enginePhaser = new Tone.Phaser({
			frequency: 15,
			octaves: 5,
			baseFrequency: 1000
		}).connect(this._mono);

		this._engineChannel.connect(this._enginePhaser);

		this._highpassFilter = new Tone.Filter(3000, 'highpass').connect(this._engineChannel);
		this._lowpassFilter = new Tone.Filter(1000, 'lowpass').connect(this._highpassFilter);

		this._effect = new Tone.Vibrato(500, 0.4);
		this._effect.connect(this._lowpassFilter);

		this._player = new Tone.Player({
			loop: true,
			loopStart: 0.63,
			loopEnd: 1
		}).connect(this._effect);
		this._player.volume.value = -5;

		this._player.buffer = this._buffers.get('charge_up');

		this._engineBreathPlayer = new Tone.Player({
			loop: true
		}).connect(this._engineChannel);
		this._engineBreathPlayer.volume.value = -15;

		this._engineBreathPlayer.buffer = this._buffers.get('engine_breathe');

		this._engineNoise = new Tone.Player({
			loop: true,
			loopStart: 3,
			loopEnd: 10
		}).connect(this._engineChannel);
		this._engineNoise.volume.value = -13;

		this._engineNoise.buffer = this._buffers.get('engine_noise');

		this._engineWarmupPlayer = new Tone.Player({
			fadeOut: 0.5
		}).connect(this._engineChannel);
		this._engineWarmupPlayer.volume.value = -6;
		this._engineWarmupPlayer.buffer = this._buffers.get('engine_warmup');

		this.engineSpeed = 0;

		this.initBeam();
	};

	private initBeam = () => {
		this._beamPulsePlayer = new Tone.Player().connect(this._engineMasterChannel);
		this._beamPulsePlayer.volume.value = -36;
		this._beamPulsePlayer.buffer = this._buffers.get('beam_pulse');
	};

	private updateGlobalParams = () => {
		this._ambienceChannel.volume.value = this._globalParams.volume;
	};

	public triggerCollision = () => {
		let player = new Tone.Player();
		player.playbackRate = 1 + 0.2 * Math.random();
		player.buffer = this._buffers.get('border_impact');
		player.volume.value = -20;
		player.connect(this._engineMasterChannel);
		player.start();

		this.addPlayerForGarbageCollection(player);
	};

	public triggerParticle = () => {
		let player = new Tone.Player();
		player.playbackRate = 1 + 0.3 * Math.random();
		player.buffer = this._buffers.get('engine_huf');
		player.connect(this._hufEffect);
		player.start();

		this.addPlayerForGarbageCollection(player);
	};

	public triggerHello = (situation: ISituationConfig) => {
		let player = new Tone.Player();
		let random;

		if (situation.voiceSamples.length > 0) {
			random = '_' + situation.voiceSamples[getRandomInt(0, situation.voiceSamples.length - 1)];
		} else {
			random = '_04';
		}

		player.playbackRate = situation.pitch;
		player.buffer = this._buffers.get('hello_' + situation.greetingGender + random);
		player.volume.value = this._globalParams.voiceVolume;
		player.connect(this._masterChannel);

		gsap.delayedCall(situation.greetingDelay, player.start, null, player);

		this.addPlayerForGarbageCollection(player);
	};

	private addPlayerForGarbageCollection = (player: Tone.Player) => {
		this._players.push(player);

		if (this._players.length > 50) {
			let p = this._players.shift();
			p.dispose();
		}
	};
	private overallMute: boolean = false;

	get buffers() {
		return this._buffers;
	}

	get engineSpeed() {
		return this._engineSpeed;
	}

	set engineSpeed(value: number) {
		this._engineSpeed = clamp(0, 1, value);

		this._engineChannel.volume.value = -30 + this._engineSpeed * 15;
		this._player.playbackRate = 1 + this._engineSpeed * 0.5;

		this._engineBreathPlayer.playbackRate = 1 + this._engineSpeed * 0.2;

		this._engineWarmupPlayer.playbackRate = 1 + this._engineSpeed * 0.5;
		this._engineWarmupPlayer.volume.value = -6 - this._engineSpeed * 7;

		this._engineNoise.playbackRate = 1 + this._engineSpeed * 0.4;

		this._effect.frequency.value = 210 + this._engineSpeed * 200;

		this._hufChannel.volume.value = this._globalParams.hufVolume - this._engineSpeed * 6;

		this._highpassFilter.frequency.value = 3000 - 2500 * this._engineSpeed;
		this._lowpassFilter.frequency.value = 1000 + 1500 * this._engineSpeed;
	}

	public toggleEngineWarmup = (state: boolean) => {
		if (Globals.IS_TOUCH_DEVICE || Globals.I_OS) {
			return;
		}

		if (state && !this._warmupActive) {
			gsap.killTweensOf(this._enginePhaser.frequency);
			gsap.killTweensOf(this.startWarmupLoop);
			this._enginePhaser.frequency.value = 15;
			gsap.to(this._enginePhaser.frequency, 5, {value: 3000, overwrite: true});
			this._engineWarmupPlayer.start();
			gsap.delayedCall(0.3, this.startWarmupLoop);
		} else if (!state && this._warmupActive) {
			gsap.killTweensOf(this.startWarmupLoop);
			this._engineWarmupPlayer.loop = false;
			this._engineWarmupPlayer.loopStart = 0;
			this._engineWarmupPlayer.loopEnd = 0;
			this._engineWarmupPlayer.stop(0.3);
			gsap.to(this._enginePhaser.frequency, 1, {value: 15, overwrite: true});
		}

		this._warmupActive = state;
	};

	public toggleBeam = (state: boolean) => {
		if (state && !this._beamActive) {
			this._beamActive = true;
			gsap.killTweensOf(this.stopBeam);
			this._beamPulsePlayer.start();
			gsap.delayedCall(0.6, this.stopBeam);
		} else if (!state && this._beamActive) {
			this._beamActive = false;

			gsap.killTweensOf(this.stopBeam);
			gsap.killTweensOf(this._engineMasterChannel.volume);
			gsap.to(this._engineMasterChannel.volume, 0.3, {value: 0});

			this._beamPulsePlayer.stop();
			this._beamPulsePlayer.start(null, 2.1);
		}
	};

	private stopBeam = () => {
		this._beamPulsePlayer.stop();
		gsap.to(this._engineMasterChannel.volume, 0.3, {value: -5});
	};

	private startWarmupLoop = () => {
		this._engineWarmupPlayer.loop = true;
		this._engineWarmupPlayer.loopStart = 0.3;
		this._engineWarmupPlayer.loopEnd = 2;
	};

	public updateSituations = (situations: SituationManager) => {
		this._situations.update(situations);
	};

	public toggleMute = (value: boolean, duration = 0.5, overallMute = null) => {
		this.overallMute = overallMute !== null ? overallMute : this.overallMute;
		value = this.overallMute ? this.overallMute : value;
		if (this.muted !== value) {
			this.muted = value;
			gsap.to(this._masterChannel.volume, duration, {value: value ? -80 : 0, ease: 'none', overwrite: true});
			emitter.emit(EVENTS.muteChange);
		}
	};
}
