import {Globals} from '../../utils/Globals';
import {gsap} from 'gsap';
import {emitter, EVENTS} from '../../utils/Dispatcher';
import Sizes from '../../utils/Sizes';

export class OverallMuteButton {
	private element: Element;
	private DOMWaveBars: NodeListOf<Element>;
	private MAX_SCALE = 4;

	static SELECTORS = {
		WAVE_BARS: '.overall-mute__bar'
	};
	private sizes: Sizes;
	private isDisplayed: boolean;

	constructor(element: Element) {
		const {WAVE_BARS} = OverallMuteButton.SELECTORS;

		this.element = element;
		this.sizes = new Sizes();
		this.DOMWaveBars = this.element.querySelectorAll(WAVE_BARS);

		this.bindEvents();
	}

	bindEvents() {
		this.element.addEventListener('click', this.toggle);
		emitter.on(EVENTS.muteChange, this.muteChange);
		emitter.on(EVENTS.resize, this.resize);
	}

	public randomize = () => {
		this.DOMWaveBars.forEach((bar, i) => {
			this.animBar(bar);
		});
	};

	animateIn() {
		this.isDisplayed = true;
		return gsap.fromTo(this.DOMWaveBars, {scaleY: 0}, {duration: 0.3, force3D: true, scaleY: 1, onComplete: this.randomize});
	}

	hide = () => {};

	animBar = elem => {
		gsap.to(elem, {
			duration: gsap.utils.random(0.1, 0.3),
			scaleY: gsap.utils.random(1, this.MAX_SCALE),
			repeat: 1,
			force3D: true,
			ease: 'sine.inOut',
			yoyo: true,
			onComplete: () => {
				this.animBar(elem);
			}
		});
	};

	reset() {
		this.DOMWaveBars.forEach((bar, i) => {
			gsap.killTweensOf(bar);
		});
		gsap.to(this.DOMWaveBars, {duration: 0.3, scaleY: 1, force3D: true});
	}

	toggle = event => {
		event.cancelBubble = true;
		Globals.AUDIO_ENGINE.toggleMute(!Globals.AUDIO_ENGINE.muted, 0.5, !Globals.AUDIO_ENGINE.muted);
	};

	muteChange = () => {
		Globals.AUDIO_ENGINE.muted ? this.reset() : this.randomize();
	};

	resize = () => {
		if (this.isDisplayed && !this.sizes.isLaptop) {
			this.isDisplayed = false;
			this.reset();
		} else if (!this.isDisplayed && this.sizes.isLaptop) {
			this.isDisplayed = true;
			this.randomize();
		}
	};

	//@TODO: stop on mobile
}
