import {emitter, EVENTS} from './utils/Dispatcher';
import {GLTFLoader, GLTF} from 'three/examples/jsm/loaders/GLTFLoader';
import {DRACOLoader} from 'three/examples/jsm/loaders/DRACOLoader.js';
import {Experience} from 'pages/Experience/Experience';
import {Globals} from './utils/Globals';
import * as THREE from 'three';

export interface IResourcesLoaderSource {
	name: string;
	source: string;
}

class ResourcesLoader {
	public toLoad: number = 0;
	public loaded: number = 0;
	public progress: number = 0;
	private loaders: Array<any> = [];
	private _experience: Experience;
	private dracoLoader: DRACOLoader;
	private audioLoadComplete = false;
	private resourcesLoadComplete = false;

	constructor(experience: Experience) {
		this._experience = experience;

		THREE.DefaultLoadingManager.setURLModifier(this.urlModifierCallback);
		const gltfLoader = new GLTFLoader();
		this.dracoLoader = new DRACOLoader();
		this.dracoLoader.setDecoderPath('static/draco/');
		this.dracoLoader.setDecoderConfig({type: 'js'});

		gltfLoader.setDRACOLoader(this.dracoLoader);

		const textureLoader = new THREE.TextureLoader();
		this.loaders.push({
			extensions: ['glb', 'gltf'],
			action: resource => {
				gltfLoader.load(resource.source, _data => {
					this.modelLoaded(resource, _data);
				});
			}
		});

		this.loaders.push({
			extensions: ['png'],
			action: resource => {
				textureLoader.load(resource.source, texture => {
					this.textureLoaded(resource, texture);
				});
			}
		});

		emitter.on(EVENTS.audioAssetsLoaded, this.audioLoaded);
		Globals.AUDIO_ENGINE.loadAssets();
	}

	private loResTextureUrls = [
		'static/merged/10_meshes_Emissive_1_17715950.jpg.jpg',
		'static/merged/10_meshes_Emissive_1_62528844.jpg.jpg',
		'static/merged/34_meshes_Emissive_1_69795080.jpg.jpg',
		'static/merged/24_meshes_Emissive_1_34180720.jpg.jpg',
		'static/merged/8_meshes_Emissive_1_74984048.jpg.jpg',
		'static/merged/7_meshes_Emissive_1_58217508.jpg.jpg'
	];

	private urlModifierCallback = url => {
		let ext = '.jpg.jpg';
		let smallExt = '_2048.jpg';
		const originalUrl = url;
		let finalUrl = url;

		if (Globals.WEBP_IS_SUPPORTED && this.loResTextureUrls.includes(originalUrl)) {
			finalUrl = url.replace(ext, '.webp');
			ext = '.webp';
			smallExt = '_2048.webp';
		}

		if (Globals.USE_LOW_RES_TEXTURES && this.loResTextureUrls.includes(originalUrl)) {
			finalUrl = finalUrl.replace(ext, smallExt);
		}

		return finalUrl;
	};

	public load(resources: Array<IResourcesLoaderSource>) {
		for (const resource of resources) {
			const extensionMatch = resource.source.match(/\.([a-z]+)$/);
			this.toLoad++;

			const extension = extensionMatch[1];
			const loader = this.loaders.find(_loader => _loader.extensions.find(_extension => _extension === extension));
			loader.action(resource);
		}
	}

	private uploadedTextures: THREE.Texture[] = [];
	private uploadedTextureMaterials: THREE.Material[] = [];

	private textureLoaded(resource: IResourcesLoaderSource, texture: THREE.Texture) {
		this.loaded++;
		this.uploadedTextures.push(texture);
		emitter.emit(EVENTS.fileLoaded, [resource, texture]);
		this.checkAllResourcesDone();
	}

	private modelLoaded(resource: IResourcesLoaderSource, data: GLTF) {
		this.loaded++;

		data.scenes.forEach(scene =>
			scene.traverse(obj => {
				// @ts-ignore
				if (obj.isMesh) {
					// @ts-ignore
					if (obj.material && (obj.material as THREE.MeshStandardMaterial).emissiveMap) {
						// @ts-ignore
						let text = obj.material.emissiveMap as THREE.Texture;
						if (!this.uploadedTextures.includes(text)) {
							this.uploadedTextures.push(text);
							// @ts-ignore
							this.uploadedTextureMaterials.push(obj.material);
						}
					}
				}
			})
		);

		emitter.emit(EVENTS.fileLoaded, [resource, data]);
		this.checkAllResourcesDone();
	}

	private checkAllResourcesDone() {
		if (this.loaded === this.toLoad) {
			this.dracoLoader.dispose();
			// console.log('texture count: ', this.uploadedTextures);
			if (!Globals.IS_SAFARI) {
				this.uploadTexture();
			}

			this.resourcesLoadComplete = true;
			this.checkResourcesAndAudioDone();
		}
	}

	public uploadTexture = () => {
		this.uploadedTextures.forEach((tex, index) => {
			this._experience.renderer.initTexture(tex);
		});
	};

	private checkResourcesAndAudioDone() {
		if (this.audioLoadComplete && this.resourcesLoadComplete) {
			emitter.emit(EVENTS.loaded);
		}
	}

	private audioLoaded = () => {
		this.audioLoadComplete = true;
		this.checkResourcesAndAudioDone();
	};
}

export default ResourcesLoader;
