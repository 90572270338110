import {gsap} from 'gsap';
import {Globals} from '../utils/Globals';
import Sizes from '../utils/Sizes';

export class BurgerButton {
	private DOMBurgerBars: NodeListOf<Element>;
	private crossTimeline: gsap.core.Timeline;
	private DOMBurgerBarsSeparators: NodeListOf<Element>;
	private hoverBurgerTimelineIn: gsap.core.Timeline;
	private hoverBurgerTimelineOut: gsap.core.Timeline;
	private hoverCrossTimelineIn: gsap.core.Timeline;
	private hoverCrossTimelineOut: gsap.core.Timeline;
	private burgerTimeline: gsap.core.Timeline;
	private DOMBurger: Element;
	private openCallback: Function;
	private closeCallback: Function;
	private isOpened: boolean;

	static SELECTORS = {
		BURGER_BARS: '.burger-icon__bar'
	};
	private state: string;
	private isTransitioning: boolean;
	private sizes: Sizes;

	constructor(element, openCallback, closeCallback) {
		const {BURGER_BARS} = BurgerButton.SELECTORS;

		this.DOMBurger = element;
		this.DOMBurgerBars = this.DOMBurger.querySelectorAll(BURGER_BARS);
		this.DOMBurgerBarsSeparators = this.DOMBurgerBars[1].querySelectorAll('span');

		this.sizes = new Sizes();
		this.state = 'burger';
		this.openCallback = openCallback;
		this.closeCallback = closeCallback;

		this.bindEvents();
	}

	bindEvents() {
		this.DOMBurger.addEventListener('click', this.onBurgerClick);
		this.DOMBurger.addEventListener('mouseenter', this.onBurgerHoverIn);
		this.DOMBurger.addEventListener('mouseleave', this.onBurgerHoverOut);
	}

	burgerToCross = () => {
		if (this.crossTimeline) {
			this.crossTimeline.kill();
		}

		this.burgerTimeline = gsap.timeline({
			onComplete: () => {
				this.isTransitioning = false;
				this.state = 'cross';
			}
		});
		this.burgerTimeline.to(this.DOMBurgerBars[0], {duration: 0.3, y: 5, force3D: true, ease: 'sine.inOut'}, 0);
		this.burgerTimeline.to(this.DOMBurgerBars[2], {duration: 0.3, y: -5, force3D: true, ease: 'sine.inOut'}, 0);
		this.burgerTimeline.to(this.DOMBurgerBarsSeparators, {duration: 0.1, opacity: 1, ease: 'none'}, 0.6);
		this.burgerTimeline.to(this.DOMBurger, {duration: 0.4, rotationZ: 45, ease: 'Power4.inOut'}, 0.6);
		this.burgerTimeline.to(this.DOMBurgerBars[1], {duration: 0.4, rotationZ: -90, ease: 'Power4.inOut'}, 0.6);
	};

	crossToBurger = () => {
		if (this.burgerTimeline) {
			this.burgerTimeline.kill();
		}

		this.crossTimeline = gsap.timeline({
			onComplete: () => {
				this.isTransitioning = false;
				this.state = 'burger';
			}
		});
		this.crossTimeline.to(this.DOMBurger, {duration: 0.3, rotationZ: 0, ease: 'Power4.inOut'}, 0);
		this.crossTimeline.to(this.DOMBurgerBars[1], {duration: 0.3, rotationZ: 0, ease: 'Power4.inOut'}, 0);
		this.crossTimeline.to(this.DOMBurgerBars, {duration: 0.3, scaleX: 1, force3D: true, ease: 'sine.out'}, 0);
		this.crossTimeline.to(this.DOMBurgerBarsSeparators, {duration: 0.1, opacity: 0, ease: 'none'}, 0.3);
		this.crossTimeline.to(this.DOMBurgerBars[0], {duration: 0.3, y: 0, force3D: true, ease: 'sine.inOut'}, 0.3);
		this.crossTimeline.to(this.DOMBurgerBars[2], {duration: 0.3, y: 0, force3D: true, ease: 'sine.inOut'}, 0.3);
	};

	burgerHoverIn = () => {
		if (this.hoverBurgerTimelineOut) {
			this.hoverBurgerTimelineOut.kill();
		}
		this.hoverBurgerTimelineIn = gsap.timeline({
			// onComplete: this.setBurgerEnd
		});
		this.hoverBurgerTimelineIn.to(this.DOMBurgerBars[0], {duration: 0.3, y: -2, force3D: true, ease: 'none'}, 0);
		this.hoverBurgerTimelineIn.to(this.DOMBurgerBars[2], {duration: 0.3, y: 2, force3D: true, ease: 'none'}, 0.08);

		// this.hoverBurgerTimelineIn.to(this.DOMBurgerBars[0], {duration: 0.3, y: -5, opacity: 0, force3D: true, ease: 'sine.out'}, 0);
		// this.hoverBurgerTimelineIn.to(this.DOMBurgerBars[1], {duration: 0.3, y: -5, force3D: true, ease: 'sine.out'}, 0);
		// this.hoverBurgerTimelineIn.to(this.DOMBurgerBars[2], {duration: 0.3, y: -5, force3D: true, ease: 'sine.out'}, 0);
		// this.hoverBurgerTimelineIn.to(this.DOMBurgerBars[3], {duration: 0.3, y: -5, opacity: 1, force3D: true, ease: 'sine.out'}, 0);
	};

	setBurgerEnd = () => {
		gsap.set(this.DOMBurgerBars[0], {opacity: 1});
		gsap.set(this.DOMBurgerBars[3], {opacity: 0});
		gsap.set(this.DOMBurgerBars, {y: 0});
	};

	burgerHoverOut = () => {
		if (this.hoverBurgerTimelineIn) {
			this.hoverBurgerTimelineIn.kill();
		}
		this.hoverBurgerTimelineOut = gsap.timeline();
		this.hoverBurgerTimelineOut.to([this.DOMBurgerBars[0], this.DOMBurgerBars[2]], {duration: 0.3, y: 0, ease: 'power3.out'}, 0);

		// this.hoverBurgerTimelineOut.to(this.DOMBurgerBars[0], {duration: 0.3, y: 0, opacity: 1, force3D: true, ease: 'sine.out'}, 0);
		// this.hoverBurgerTimelineOut.to(this.DOMBurgerBars[1], {duration: 0.3, y: 0, force3D: true, ease: 'sine.out'}, 0);
		// this.hoverBurgerTimelineOut.to(this.DOMBurgerBars[2], {duration: 0.3, y: 0, force3D: true, ease: 'sine.out'}, 0);
		// this.hoverBurgerTimelineOut.to(this.DOMBurgerBars[3], {duration: 0.3, y: 0, opacity: 0, force3D: true, ease: 'sine.out'}, 0);
	};

	crossHoverIn = () => {
		if (this.hoverCrossTimelineOut) {
			this.hoverCrossTimelineOut.kill();
		}
		this.hoverCrossTimelineIn = gsap.timeline();
		this.hoverCrossTimelineIn.to(this.DOMBurgerBars[1], {duration: 0.3, scaleX: 0.8, force3D: true, ease: 'sine.out'}, 0);
		this.hoverCrossTimelineIn.to([this.DOMBurgerBars[0], this.DOMBurgerBars[2]], {duration: 0.3, scaleX: 0.8, force3D: true, ease: 'sine.out'}, 0.08);
	};

	crossHoverOut = () => {
		if (this.hoverCrossTimelineIn) {
			this.hoverCrossTimelineIn.kill();
		}
		this.hoverCrossTimelineOut = gsap.timeline();
		this.hoverCrossTimelineOut.to(this.DOMBurgerBars, {duration: 0.3, scaleX: 1, force3D: true, ease: 'sine.out'});
	};

	onBurgerClick = () => {
		if (this.isOpened) {
			this.close();
			this.closeCallback && this.closeCallback();
		} else {
			this.open();
			this.openCallback && this.openCallback();
		}
	};

	open = () => {
		this.isTransitioning = true;
		this.isOpened = true;
		this.DOMBurger.classList.add('menu-is-open');
		this.burgerToCross();
	};

	close = () => {
		this.isTransitioning = true;
		this.isOpened = false;
		this.DOMBurger.classList.remove('menu-is-open');
		this.crossToBurger();
	};

	onBurgerHoverIn = () => {
		if (this.isTransitioning || Globals.IS_TOUCH_DEVICE) return;
		if (this.state === 'burger') {
			this.burgerHoverIn();
		} else {
			this.crossHoverIn();
		}
	};
	onBurgerHoverOut = () => {
		if (this.isTransitioning || Globals.IS_TOUCH_DEVICE) return;
		if (this.state === 'burger') {
			this.burgerHoverOut();
		} else {
			this.crossHoverOut();
		}
	};
}
