import * as THREE from 'three';
import {gsap} from 'gsap';
import Resources from '../Resources';
import {weightedRandom} from '../utils/helpers';
import {emitter, EVENTS} from '../utils/Dispatcher';
import {Globals} from '../utils/Globals';
import Time from '../utils/Time';

interface ProxyObject3D extends THREE.Object3D {
	randomOffsets: THREE.Vector3;
	scaleTo: number;
}

export class JoggerGroundAnimation {
	private _container: THREE.Object3D;

	private COLUMNS = 10;
	private ROWS = 10;
	private WIDTH = 2000;
	private LENGTH = 2000;

	private proxyAnimators: ProxyObject3D[];
	private mesh: THREE.InstancedMesh;
	private meshShadow: THREE.InstancedMesh;
	private speed: number = 10.7;
	private moveDistanceGoal = this.WIDTH * 0.5;
	private fadeInCenterRadius = 900;
	private fadeInWidth = 500;
	private fadeInFactor = this.fadeInCenterRadius / this.fadeInWidth;
	private edgeFadeEase = gsap.parseEase('power1.in');

	private randomPositionZ = gsap.utils.random(-50, 50, null, true);
	private randomPositionX = gsap.utils.random(-50, 50, null, true);
	private randomScale: () => number;
	private material: THREE.MeshStandardMaterial;
	private shadowMaterial: THREE.MeshStandardMaterial;
	private centerDistance = new THREE.Vector3(this.WIDTH * 0.5, 0, 0);
	private wrapper: THREE.Group;
	private resources: Resources;
	private time: Time;

	constructor(resources: Resources, container: THREE.Object3D) {
		this.resources = resources;
		this._container = container;
		this.wrapper = new THREE.Group();
		this.wrapper.scale.set(0.01, 0.01, 0.01);
		this._container.add(this.wrapper);
		this.randomScale = weightedRandom(
			[...Array(10).keys()].map(i => 0.3 + i * (0.4 / 10)),
			'circ.in'
		);
		this.time = new Time();
		this.setupAnimation();
		emitter.on(EVENTS.tick, this.updateInstances);
	}

	private setupAnimation() {
		let source = this.resources.items.all_environment_assets.scene.getObjectByName('jogger_rock').children;
		let rock = source[0] as THREE.Mesh;
		let shadow = source[1] as THREE.Mesh;

		rock.geometry.scale(100, 100, 100);
		rock.geometry.rotateY(Math.PI / 8);
		shadow.geometry.scale(100, 1, 100);
		shadow.geometry.rotateY(Math.PI / 8);

		this.material = (rock.material as THREE.MeshStandardMaterial).clone();
		this.material.roughness = 0.9;
		this.material.metalness = 0;

		this.shadowMaterial = (shadow.material as THREE.MeshStandardMaterial).clone();
		this.mesh = new THREE.InstancedMesh(rock.geometry, this.material, this.ROWS * this.COLUMNS);
		this.meshShadow = new THREE.InstancedMesh(shadow.geometry, this.shadowMaterial, this.ROWS * this.COLUMNS);
		this.mesh.name = 'jogger rocks';
		this.meshShadow.name = 'jogger rocks shadow';

		this.proxyAnimators = [];

		let index = 0;

		const distanceWidth = this.WIDTH / this.ROWS;
		const distanceLength = this.LENGTH / this.COLUMNS;
		for (let row = 0; row < this.ROWS; row++) {
			for (let col = 0; col < this.COLUMNS; col++) {
				const dummy = new THREE.Object3D() as ProxyObject3D;
				dummy.randomOffsets = new THREE.Vector3(col * distanceLength, this.randomPositionX(), this.randomPositionZ());
				dummy.position.set(dummy.randomOffsets.x + dummy.randomOffsets.y, 50, row * distanceWidth + dummy.randomOffsets.z);
				dummy.scaleTo = this.randomScale();
				// @ts-ignore
				dummy.scale.set(dummy.scaleTo, dummy.scaleTo, dummy.scaleTo);

				dummy.updateMatrix();
				this.mesh.setMatrixAt(index, dummy.matrix);
				this.meshShadow.setMatrixAt(index, dummy.matrix);
				this.proxyAnimators.push(dummy);
				index++;
			}
		}
		// this.mesh.instanceMatrix.usage = THREE.DynamicDrawUsage;
		// this.mesh.position.set(this.LENGTH * -0.5, -48.26, -this.WIDTH * 0.05);
		this.mesh.position.set(this.LENGTH * -0.5, -60, -this.WIDTH * 0.05);
		this.meshShadow.position.set(this.LENGTH * -0.5, -60, -this.WIDTH * 0.05);
		this.wrapper.add(this.meshShadow);
		this.wrapper.add(this.mesh);
	}

	private rafScale = 1;
	private rafDistance = 1;
	private lagCompensatedSpeed = 0;
	private updateInstances = () => {
		// this.lagCompensatedSpeed = this.speed * this.time.FPSFactor
		this.lagCompensatedSpeed = this.speed;
		this.proxyAnimators.forEach((dummy, index) => {
			dummy.position.z -= this.lagCompensatedSpeed;
			if (dummy.position.z - dummy.randomOffsets.z < -this.moveDistanceGoal) {
				dummy.randomOffsets.z = this.randomPositionZ();
				dummy.position.z = this.moveDistanceGoal + dummy.randomOffsets.z;
				dummy.randomOffsets.y = this.randomPositionX();
				dummy.position.x = dummy.randomOffsets.x + this.randomPositionX();
			}
			this.rafDistance = this.centerDistance.distanceTo(dummy.position);
			this.rafScale = dummy.scaleTo * this.edgeFadeEase(gsap.utils.clamp(0, 1, (1 - this.rafDistance / this.fadeInCenterRadius) * this.fadeInFactor));
			dummy.scale.set(this.rafScale, this.rafScale, this.rafScale);
			dummy.updateMatrix();
			this.mesh.setMatrixAt(index, dummy.matrix);
			this.meshShadow.setMatrixAt(index, dummy.matrix);
		});
		this.material.emissive.set(Globals.CURR_COLOR);
		this.mesh.instanceMatrix.needsUpdate = true;
		this.meshShadow.instanceMatrix.needsUpdate = true;
	};
}
