import * as THREE from 'three';
import Resources from '../Resources';
import {ISituationConfig, SituationManager} from './SituationManager';
import {gsap} from 'gsap';

interface Path {
	anchorStart: THREE.Group;
	anchorEnd: THREE.Group;
	dots: any[];
	situation: ISituationConfig;
	nextSituation: ISituationConfig;
}

//@todo: this class should at some point use instancedMesh instead of a million individual dots :-)
export class SituationsPaths {
	private scene: THREE.Scene;
	private situationsManager: SituationManager;
	private resources: Resources;
	private debug: dat.GUI;
	private anchorPoints: THREE.Vector3[][];
	private dotsGap = 4;
	private dotsScale = 2;
	private paths: Path[];

	constructor(resources: Resources, situationManager: SituationManager, scene: THREE.Scene, debug: dat.GUI) {
		this.situationsManager = situationManager;
		this.resources = resources;
		this.scene = scene;
		this.debug = debug;

		this.anchorPoints = [
			[new THREE.Vector3(-22, 0, -9), new THREE.Vector3(-7, 0, 6)],
			[new THREE.Vector3(28, 0, -46), new THREE.Vector3(-11, 0, 21)],
			[new THREE.Vector3(25, 0, -20), new THREE.Vector3(0, 0, -20)],
			[new THREE.Vector3(0, 0, 30), new THREE.Vector3(0, 0, -20)],
			[new THREE.Vector3(0, 0, 25), new THREE.Vector3(-30, 0, -25)],
			[new THREE.Vector3(7, 0, 38), new THREE.Vector3(0, 0, -20)],
			[new THREE.Vector3(-25, 0, 36), new THREE.Vector3(36, 0, 5)],
			[new THREE.Vector3(-30, 0, -15), new THREE.Vector3(-14, 0, 15)]
		];

		const dotSpline = this.resources.items.dotLines.scene;
		dotSpline.name = 'dots';
		dotSpline.scale.set(100, 1, 100);

		const dots = dotSpline.getObjectByName('Cloner');

		let scale = {value: 0.9};

		const color = new THREE.Color('#ffffff');

		dots.children.forEach((dot: THREE.Mesh) => {
			dot.scale.set(scale.value, 1, scale.value);
			(dot.material as THREE.MeshStandardMaterial).emissive = color;
			(dot.material as THREE.MeshStandardMaterial).transparent = true;
			(dot.material as THREE.MeshStandardMaterial).opacity = 0.6;
			(dot.material as THREE.MeshStandardMaterial).depthWrite = false;
		});

		this.scene.add(dotSpline);

		// this.generateDots();
		// this.generatePaths();

		if (this.debug) {
			// this.initDebug();
			// const folder = this.debug.addFolder('Dot Paths');
			// folder.add(scale, "value").step(0.05).min(0.1).max(1).onChange(() => {
			// 	dots.children.forEach(dot => {
			// 		dot.scale.set(scale.value, 1, scale.value)
			// 	})
			// })
		}
	}

	generateDots() {
		this.paths = [];

		for (let index = 0; index < this.anchorPoints.length; index++) {
			const situation = this.situationsManager.situations[index];
			const nextSituation = this.situationsManager.situations[index + 1] ? this.situationsManager.situations[index + 1] : this.situationsManager.situations[0];

			const dot = this.resources.items.dot.scene;
			const anchorStartObject = this.createDot(dot, 6, '#ff0000', !!this.debug);
			const anchorFinalObject = this.createDot(dot, 6, '#0000ff', !!this.debug);

			const path: Path = {
				anchorStart: anchorStartObject,
				anchorEnd: anchorFinalObject,
				dots: [],
				situation,
				nextSituation
			};

			const distance = situation.centerPosition.distanceTo(nextSituation.centerPosition);
			const dotAmount = Math.round(distance / this.dotsGap);

			this.scene.add(anchorStartObject);
			this.scene.add(anchorFinalObject);

			path.anchorStart = anchorStartObject;
			path.anchorEnd = anchorFinalObject;

			for (let j = 0; j < dotAmount; j++) {
				const isVisible = !(j === 0 || j === dotAmount - 1);
				const copy = this.createDot(dot, this.dotsScale, '#ffffff', isVisible);
				path.dots.push(copy);
				this.scene.add(copy);
			}

			this.paths.push(path);
		}
	}

	initDebug() {
		const folder = this.debug.addFolder('Dot Paths');

		this.paths.forEach((path, i) => {
			const subFolder = folder.addFolder('path ' + i);
			subFolder
				.add(this.anchorPoints[i][0], 'x')
				.name('anchor start x')
				.min(-50)
				.max(50)
				.step(1)
				.onChange(this.generatePaths);
			subFolder
				.add(this.anchorPoints[i][0], 'z')
				.name('anchor start z')
				.min(-50)
				.max(50)
				.step(1)
				.onChange(this.generatePaths);
			subFolder
				.add(this.anchorPoints[i][1], 'x')
				.name('anchor end x')
				.min(-50)
				.max(50)
				.step(1)
				.onChange(this.generatePaths);
			subFolder
				.add(this.anchorPoints[i][1], 'z')
				.name('anchor end z')
				.min(-50)
				.max(50)
				.step(1)
				.onChange(this.generatePaths);
		});
	}

	createDot(model, scale = 4, color = '#ffffff', visible = true) {
		const dot = model.clone();
		dot.scale.set(scale, scale, scale);
		dot.children[0].material = model.children[0].material.clone();
		let material = dot.children[0].material;
		material.transparent = true;
		// material.opacity = 0;
		material.emissive = new THREE.Color(color);
		material.visible = visible;

		return dot;
	}

	generatePaths = () => {
		for (let index = 0; index < this.paths.length; index++) {
			const path = this.paths[index];

			for (let j = 0; j < path.dots.length; j++) {
				const start = path.situation.centerPosition;
				const final = path.nextSituation.centerPosition;

				const startCopy = start.clone();
				const finalCopy = final.clone();

				const anchorStart = startCopy.add(this.anchorPoints[index][0]);
				const anchorFinal = finalCopy.add(this.anchorPoints[index][1]);

				path.anchorStart.position.copy(anchorStart);
				path.anchorEnd.position.copy(anchorFinal);

				const progress = j / path.dots.length;
				const point = this.cubic(start, anchorStart, anchorFinal, final, progress);

				path.dots[j].position.copy(point);
			}
		}
	};

	cubic(p0, p1, p2, p3, t) {
		const point = new THREE.Vector3();

		point.x = Math.pow(1 - t, 3) * p0.x + 3 * Math.pow(1 - t, 2) * t * p1.x + 3 * (1 - t) * Math.pow(t, 2) * p2.x + Math.pow(t, 3) * p3.x;
		point.y = 0.5;
		point.z = Math.pow(1 - t, 3) * p0.z + 3 * Math.pow(1 - t, 2) * t * p1.z + 3 * (1 - t) * Math.pow(t, 2) * p2.z + Math.pow(t, 3) * p3.z;

		return point;
	}

	public animateIn = () => {
		/*		const timeline = gsap.timeline({
			onComplete: this.loop
		});

		this.paths.forEach(path => {
			path.dots.forEach(dot => {
				timeline.to(
					dot.children[0].material,
					{
						opacity: 0.6,
						duration: 1,
						ease: 'sine.out'
					},
					0
				);
			});
		});

		return timeline;*/
		// this.loop();
	};

	private loop = () => {
		this.paths.forEach(path => {
			const timeline = gsap.timeline({repeat: -1});

			path.dots.forEach((dot, i) => {
				timeline.to(
					dot.children[0].material,
					{
						opacity: 1,
						duration: 0.6,
						repeat: 1,
						yoyo: true,
						ease: 'sine.inOut',
						onComplete: () => {
							dot.children[0].material.needsUpdate = true;
						}
					},
					i * 0.2
				);
				timeline.to(
					dot.position,
					{
						y: 0.6,
						duration: 0.3,
						repeat: 1,
						yoyo: true,
						ease: 'sine.inOut'
					},
					i * 0.2
				);
			});
		});
	};
}
