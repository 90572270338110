import {gsap} from 'gsap';
export const CalcShortestRot = (from, to, mode = 'deg') => {
	// If from or to is a negative, we have to recalculate them.
	// For an example, if from = -45 then from(-45) + 360 = 315.

	if (mode === 'rad') {
		from *= 180 / Math.PI;
		to *= 180 / Math.PI;
	}

	if (from < 0) {
		from += 360;
	}

	if (to < 0) {
		to += 360;
	}

	// Do not rotate if from == to.
	if (from == to || (from == 0 && to == 360) || (from == 360 && to == 0)) {
		return 0;
	}

	// Pre-calculate left and right.
	let left = 360 - from + to;
	let right = from - to;
	// If from < to, re-calculate left and right.
	if (from < to) {
		if (to > 0) {
			left = to - from;
			right = 360 - to + from;
		} else {
			left = 360 - to + from;
			right = to - from;
		}
	}

	let result = left <= right ? left : right * -1;

	if (mode === 'rad') {
		result *= Math.PI / 180;
	}

	// Determine the shortest direction.
	return result;
};

export function debounce(func, wait, immediate) {
	var timeout;
	return function() {
		var context = this,
			args = arguments;
		var later = function() {
			timeout = null;
			if (!immediate) func.apply(context, args);
		};
		var callNow = immediate && !timeout;
		clearTimeout(timeout);
		timeout = setTimeout(later, wait);
		if (callNow) func.apply(context, args);
	};
}

// reusable function. Feed in an array and an ease and it'll return
// a function that pulls a random element from that array, weighted
// according to the ease you provide.
// usage:
//https://greensock.com/docs/v3/HelperFunctions
//getRandom = weightedRandom([0, 1, 2, 3], "power4");
export function weightedRandom(collection: any[], ease: string) {
	return gsap.utils.pipe(
		Math.random, //random number between 0 and 1
		gsap.parseEase(ease), //apply the ease
		gsap.utils.mapRange(0, 1, -0.5, collection.length - 0.5), //map to the index range of the array, stretched by 0.5 each direction because we'll round and want to keep distribution (otherwise linear distribution would be center-weighted slightly)
		gsap.utils.snap(1), //snap to the closest integer
		i => collection[i] //return that element from the array
	);
}

export function getRandomInt(min, max) {
	min = Math.ceil(min);
	max = Math.floor(max);
	return Math.floor(Math.random() * (max - min + 1)) + min;
}
