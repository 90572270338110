import {emit} from 'cluster';
import {Power0, TweenMax} from 'gsap';
import {emitter, EVENTS} from '../../../utils/Dispatcher';
import {Globals} from '../../../utils/Globals';
import {partial} from '../../../utils/partial';
import Sizes from '../../../utils/Sizes';
import './FastTravelUI.scss';
import {gsap} from 'gsap';

interface Item {
	index: number;
	elem: HTMLElement;
	textWrapper: Element;
	text: Element;
	slug: any;
}

class FastTravelUI {
	private app: any;
	private element: HTMLElement;

	static SELECTORS = {
		ELEMENT: '.fast-travel',
		LIST: '.fast-travel-list',
		LIST_ITEMS: '.fast-travel-list__item:not(.home-icon)',
		HOME_ICON: '.home-icon',
		LIST_ITEM_TEXT_WRAPPER: '.text',
		LIST_ITEM_TEXT: '.text-inner',
		FAST_TRAVEL_LABELS: '.fast-travel-label',
		FAST_TRAVEL_INTRO_ARROW: '.fast-travel-introduction svg',
		FAST_TRAVEL_INTRO_TEXT: '.fast-travel-introduction p',
		FAST_TRAVEL_INTRO: '.fast-travel-introduction'
	};
	private listItems: NodeListOf<HTMLElement>;
	private items: any[];
	private current: Item;
	public homeIcon: HTMLElement;
	private list: Element;
	private rectList: DOMRect;
	private fastTravelLabels: NodeListOf<Element>;
	private sizes: Sizes;
	private rectHomeIcon: DOMRect;
	private DOMFastTravelIntroText: Element;
	private DOMFastTravelIntroArrow: Element;
	private introductionTimeout: NodeJS.Timeout;
	private loopIntroduction: gsap.core.Timeline;
	private DOMFastTravelIntro: Element;
	public introEnded = false;

	constructor(app: HTMLElement) {
		const {
			ELEMENT,
			LIST,
			LIST_ITEM_TEXT_WRAPPER,
			LIST_ITEM_TEXT,
			LIST_ITEMS,
			HOME_ICON,
			FAST_TRAVEL_LABELS,
			FAST_TRAVEL_INTRO_TEXT,
			FAST_TRAVEL_INTRO_ARROW,
			FAST_TRAVEL_INTRO
		} = FastTravelUI.SELECTORS;

		this.app = app;
		this.element = this.app.querySelector(ELEMENT);
		this.list = this.element.querySelector(LIST);
		this.listItems = this.element.querySelectorAll(LIST_ITEMS);
		this.homeIcon = this.element.querySelector(HOME_ICON);
		this.fastTravelLabels = this.element.querySelectorAll(FAST_TRAVEL_LABELS);
		this.DOMFastTravelIntroText = this.element.querySelector(FAST_TRAVEL_INTRO_TEXT);
		this.DOMFastTravelIntroArrow = this.element.querySelector(FAST_TRAVEL_INTRO_ARROW);
		this.DOMFastTravelIntro = this.element.querySelector(FAST_TRAVEL_INTRO);
		this.items = [];
		this.sizes = new Sizes();

		this.listItems.forEach((item: HTMLElement, index) => {
			this.items.push({
				index,
				elem: item,
				textWrapper: item.querySelector(LIST_ITEM_TEXT_WRAPPER),
				text: item.querySelector(LIST_ITEM_TEXT),
				slug: item.dataset.situation
			} as Item);
		});

		this.bindEvents();
		this.resize();
	}

	bindEvents() {
		this.items.forEach((item: Item, i) => {
			item.elem.addEventListener('click', e => {
				this.onItemClick(item.elem, item.slug, i);
			});

			item.elem.addEventListener('mouseenter', () => {
				this.onMouseEnter(item, i);
			});
			item.elem.addEventListener('mouseleave', () => {
				this.onMouseLeave(item, i);
			});
		});

		this.homeIcon.addEventListener('click', e => {
			this.onItemClick(this.homeIcon, this.homeIcon.dataset.situation, -1);
		});

		this.list.addEventListener('touchstart', event => {
			if (Globals.IS_TOUCH_DEVICE && this.sizes.isLaptop) {
				this.displayLabelOnLargeTouchDevice(event);
			} else {
				this.displayLabelOnMobile(event);
			}
		});
		this.list.addEventListener('touchmove', event => {
			if (!this.introEnded) {
				this.hideIintroductionArrow();
			}

			if (Globals.IS_TOUCH_DEVICE && this.sizes.isLaptop) {
				this.displayLabelOnLargeTouchDevice(event);
			} else {
				this.displayLabelOnMobile(event);
			}
		});
		this.list.addEventListener('touchend', event => {
			if (Globals.IS_TOUCH_DEVICE && this.sizes.isLaptop) {
				this.hideLabelOnLargeTouchDevice(event);
			} else {
				this.onTouchEnd(event);
			}
		});

		emitter.on(EVENTS.fullScreen, this.toggle);
		emitter.on(EVENTS.resize, this.resize);
	}

	currentIndex = -1;
	isNotInteractive = false;

	getIndexOnY = (event, y, height, top, length) => {
		const start = y - top;
		const percentage = gsap.utils.clamp(0, 1, start / height);

		return Math.round(length * percentage);
	};

	displayLabelOnLargeTouchDevice = event => {
		event.cancelBubble = true;

		const x = event.touches[0].pageX;
		const y = event.touches[0].clientY;
		const {height, left, top} = this.rectList;

		const index = this.getIndexOnY(event, y, height - this.rectHomeIcon.height, top + this.rectHomeIcon.height, this.items.length - 1);
		const threshold = 250;

		if (x - left > threshold && !this.isNotInteractive) {
			this.isNotInteractive = true;
		} else if (x - left < threshold && this.isNotInteractive) {
			this.isNotInteractive = false;
		}

		if (this.currentIndex !== index) {
			if (this.currentIndex >= 0) {
				this.onMouseLeave(this.items[this.currentIndex], this.currentIndex);
			}

			this.currentIndex = index;
		}

		this.onMouseEnter(this.items[index], index);
	};

	hideLabelOnLargeTouchDevice = event => {
		if (this.currentIndex < 0) return;

		this.onMouseLeave(this.items[this.currentIndex], this.currentIndex);

		if (!this.isNotInteractive && this.currentActive !== this.currentIndex && this.current !== this.items[this.currentIndex]) {
			if (this.currentActive > 0) {
				this.items[this.currentActive].elem.classList.remove('is-active');
			}

			this.currentActive = this.currentIndex;

			this.items[this.currentIndex].elem.classList.add('is-active');
			emitter.emit(EVENTS.travelFast, this.items[this.currentIndex].slug);
			this.currentIndex = -1;
		}
	};

	displayLabelOnMobile = event => {
		event.cancelBubble = true;
		const x = event.touches[0].pageX;
		const y = event.touches[0].pageY;
		const {width, left, top} = this.rectList;
		const start = x - left;
		const percentage = gsap.utils.clamp(0, 1, start / width);
		const index = Math.round((this.items.length - 1) * percentage);
		const threshold = 100;

		if (top - y > threshold && !this.isNotInteractive) {
			this.isNotInteractive = true;
		} else if (top - y < threshold && this.isNotInteractive) {
			this.isNotInteractive = false;
		}

		if (this.currentIndex !== index) {
			if (this.currentIndex >= 0) {
				gsap.to(this.items[this.currentIndex].elem, {scale: 1, duration: 0.3});
				gsap.to(this.fastTravelLabels[this.currentIndex], {opacity: 0, duration: 0.2, ease: 'none'});
			}
			gsap.to(this.items[index].elem, {scale: 2, duration: 0.3});
			gsap.to(this.fastTravelLabels[index], {opacity: 1, duration: 0.2, ease: 'none'});
			this.currentIndex = index;
		}
	};

	onMouseEnter = (item, index) => {
		if (this.sizes.isLaptop) {
			item.elem.classList.add('is-hovering');
		} else {
			gsap.to(this.items[index].elem, {scale: 2});
			gsap.to(this.fastTravelLabels[index], {opacity: 1});
		}

		if (!this.introEnded) {
			this.hideIintroductionArrow();
		}
		this.currentIndex = index;
	};

	onMouseLeave = (item, index) => {
		if (this.sizes.isLaptop) {
			item.elem.classList.remove('is-hovering');
		} else {
			gsap.to(this.items[index].elem, {scale: 1});
			gsap.to(this.fastTravelLabels[index], {opacity: 0});
		}

		this.currentIndex = -1;
	};

	onTouchEnd = event => {
		if (this.currentIndex < 0) return;

		gsap.to(this.items[this.currentIndex].elem, {scale: 1});
		gsap.to(this.fastTravelLabels[this.currentIndex], {opacity: 0});

		if (!this.isNotInteractive) {
			emitter.emit(EVENTS.travelFast, this.items[this.currentIndex].slug);
		}
	};

	public animateIn() {
		const tl = gsap.timeline({
			onComplete: () => {
				window.addEventListener('mousemove', this.checkMousePosition);
				this.introductionTimeout = setTimeout(() => {
					this.hideIintroductionArrow();
				}, 10 * 1000);
			}
		});
		tl.set(this.element, {autoAlpha: 1});

		if (this.sizes.isLaptop) {
			tl.fromTo(
				[this.homeIcon, this.listItems],
				{
					x: -20,
					autoAlpha: 0
				},
				{
					x: 0,
					autoAlpha: 1,
					ease: 'sine.inOut',
					stagger: 0.05
				},
				0
			);

			tl.fromTo(
				[this.DOMFastTravelIntroArrow, this.DOMFastTravelIntroText],
				{
					x: 20,
					opacity: 0
				},
				{
					x: 0,
					opacity: 1,
					duration: 1.2,
					stagger: 0.05,
					ease: 'power3.out',
					onComplete: () => {
						// this.loopIntroduction = gsap.timeline({delay: 5, repeat: -1, yoyo: true})
						// this.loopIntroduction.to([this.DOMFastTravelIntroArrow], {x: -5, duration: 1, ease: 'power3.out', stagger: 0.05})
					}
				},
				1
			);
		} else {
			tl.fromTo(
				[this.homeIcon, this.listItems],
				{
					y: -20,
					autoAlpha: 0
				},
				{
					y: 0,
					autoAlpha: 1,
					ease: 'sine.inOut',
					stagger: 0.05
				},
				2
			);

			tl.fromTo(
				this.DOMFastTravelIntroText,
				{
					y: -20,
					opacity: 0
				},
				{
					y: 0,
					opacity: 1,
					duration: 1.2,
					ease: 'power3.out',
					onComplete: () => {
						// this.loopIntroduction = gsap.timeline({delay: 5, repeat: -1, yoyo: true})
						// this.loopIntroduction.to([this.DOMFastTravelIntroArrow], {x: 5, duration: 1, ease: 'power3.out', stagger: 0.05})
					}
				},
				2.6
			);

			tl.fromTo(
				this.DOMFastTravelIntroArrow,
				{
					x: 20,
					opacity: 0
				},
				{
					x: 0,
					opacity: 1,
					duration: 1.2,
					ease: 'power3.out'
				},
				2.5
			);
		}
		return tl;
	}

	checkMousePosition = e => {
		const xThreshold = this.rectList.left + this.rectList.width + 80;
		const yThresholdTop = this.rectList.top - 50;
		const yThresholdBottom = this.rectList.top + this.rectList.height + 50;

		if (e.clientX < xThreshold && (e.clientY > yThresholdTop || e.clientY < yThresholdBottom)) {
			this.hideIintroductionArrow();
		}
	};

	hideIintroductionArrow = () => {
		if (this.sizes.isLaptop) {
			gsap.to([this.DOMFastTravelIntroArrow, this.DOMFastTravelIntroText], {
				x: -20,
				duration: 0.4,
				opacity: 0,
				ease: 'power3.out',
				stagger: -0.05,
				onComplete: () => {
					this.DOMFastTravelIntro.remove();
				}
			});
		} else {
			gsap.to(this.DOMFastTravelIntroArrow, {
				x: -10,
				duration: 0.6,
				opacity: 0,
				ease: 'power3.out'
			});
			gsap.to(this.DOMFastTravelIntroText, {
				y: 10,
				duration: 0.6,
				opacity: 0,
				ease: 'power3.out',
				onComplete: () => {
					this.DOMFastTravelIntro.remove();
				}
			});
		}

		this.introductionTimeout && clearTimeout(this.introductionTimeout);
		this.loopIntroduction && this.loopIntroduction.kill();
		window.removeEventListener('mousemove', this.checkMousePosition);
		this.introEnded = true;
	};

	toggle = toggled => {
		TweenMax.to(this.element, 0.3, {autoAlpha: toggled.value ? 0 : 1, ease: Power0.easeNone});
	};

	public showCurrent = slug => {
		const current = this.items.find((item: Item) => item.slug === slug);
		if (this.items[this.currentActive] === current) return;

		current.elem.classList.add('is-active');
		this.current = current;
	};

	public hideCurrent = slug => {
		const current = this.items.find((item: Item) => item.slug === slug);

		if (!current) return;

		current.elem.classList.remove('is-active');
		this.currentActive = -1;
	};

	currentActive = -1;

	onItemClick(elem, slug, index) {
		if (elem.classList.contains('is-active')) return;

		this.items.forEach(item => {
			item.elem.classList.remove('is-active');
		});

		this.currentActive = index;
		elem.classList.add('is-active');
		this.current = this.items[this.currentActive];
		emitter.emit(EVENTS.travelFast, slug);
	}

	resizeHasChanged = false;

	resize = () => {
		this.rectList = this.list.getBoundingClientRect();
		this.rectHomeIcon = this.homeIcon.getBoundingClientRect();

		if (!this.introEnded) return;
		if (this.sizes.isLaptop && !this.resizeHasChanged) {
			this.toggle({value: false});
			this.resizeHasChanged = true;
		} else if (!this.sizes.isLaptop && this.resizeHasChanged) {
			this.resizeHasChanged = false;
		}
	};
}

export default FastTravelUI;
