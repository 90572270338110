import {gsap} from 'gsap';
import {emitter, EVENTS} from './utils/Dispatcher';
import Sizes from './utils/Sizes';
import {Globals} from './utils/Globals';
import Global = WebAssembly.Global;

interface Media {
	element: HTMLElement;
	buttonPlay: Element;
	buttonPause: Element;
	buttonMute: Element;
	isPlaying: Boolean;
}

export class MediaController {
	static SELECTOR = {
		CONTROLLER: '.media-controller',
		MEDIA_BUTTON_PLAY: '.media-controller__button-play',
		MEDIA_BUTTON_PAUSE: '.media-controller__button-pause',
		MEDIA_BUTTON_MUTE: '.media-controller__button-mute',
		MEDIA_BUTTON_FULLSCREEN: '.media-controller__button-fullscreen',
		MEDIA_TIMELINE: '.player-timeline',
		MEDIA_TIMELINE_PROGRESS: '.player-timeline__progress',
		DURATION_CURRENT: '.player-duration__current',
		DURATION_TOTAL: '.player-duration__total',
		CLOSE_BUTTON: '.situation-layer__fullscreen-cross'
	};
	private DOMButtonFullscreen: Element;
	private DOMButtonMute: HTMLButtonElement;
	private DOMButtonPlay: HTMLButtonElement;
	private DOMCloseButton: HTMLElement;
	private DOMDurationCurrent: Element;
	private DOMDurationTotal: Element;
	private DOMMedia: HTMLElement;
	private DOMTimeline: Element;
	private DOMTimelineProgress: Element;
	private isFullscreen: boolean;
	private isPlaying: boolean;
	private layer: HTMLElement;
	private mediaElement: HTMLMediaElement;
	private playedPercentage: number;
	private timelineRects: DOMRect;
	private sizes: Sizes;
	private name: string;
	private mediaType: string;
	private press = false;

	constructor(name, layer) {
		const {
			CLOSE_BUTTON,
			CONTROLLER,
			DURATION_CURRENT,
			DURATION_TOTAL,
			MEDIA_BUTTON_FULLSCREEN,
			MEDIA_BUTTON_PLAY,
			MEDIA_BUTTON_MUTE,
			MEDIA_TIMELINE,
			MEDIA_TIMELINE_PROGRESS
		} = MediaController.SELECTOR;

		this.name = name;
		this.layer = layer;
		this.DOMMedia = layer.querySelector(CONTROLLER);
		this.mediaElement = layer.querySelector('#' + this.DOMMedia.dataset.mediaId) as HTMLAudioElement;
		this.mediaType = layer.dataset.mediaType;
		this.DOMButtonPlay = this.DOMMedia.querySelector(MEDIA_BUTTON_PLAY);
		this.DOMButtonMute = this.DOMMedia.querySelector(MEDIA_BUTTON_MUTE);
		this.DOMButtonFullscreen = this.DOMMedia.querySelector(MEDIA_BUTTON_FULLSCREEN);
		this.DOMTimeline = this.DOMMedia.querySelector(MEDIA_TIMELINE);
		this.DOMTimelineProgress = this.DOMMedia.querySelector(MEDIA_TIMELINE_PROGRESS);
		this.DOMDurationTotal = this.DOMMedia.querySelector(DURATION_TOTAL);
		this.DOMDurationCurrent = this.DOMMedia.querySelector(DURATION_CURRENT);
		this.DOMCloseButton = layer.querySelector(CLOSE_BUTTON);
		this.isPlaying = false;
		this.isFullscreen = false;

		this.sizes = new Sizes();
		this.playedPercentage = 0;

		this.bindEvents();
		this.resize();
	}

	bindEvents() {
		this.DOMButtonPlay.addEventListener('click', this.onPlay);

		let timer = null;
		let delay = 200;
		let prevent = false;

		this.mediaElement.addEventListener('click', e => {
			timer = setTimeout(() => {
				if (!prevent) {
					this.onPlay(e);
				}
				prevent = false;
			}, delay);
		});
		this.mediaElement.addEventListener('dblclick', e => {
			clearTimeout(timer);
			prevent = true;
			this.toggleFullScreen();
		});

		this.DOMTimeline.addEventListener('mousedown', this.timelinePress);
		this.DOMTimeline.addEventListener('touchstart', this.timelinePress);
		this.DOMTimeline.addEventListener('mousemove', this.seek);
		this.DOMTimeline.addEventListener('touchmove', this.seek);

		this.DOMTimeline.addEventListener('click', this.timelineClick);

		this.DOMTimeline.addEventListener('mouseup', this.timelineUnpress);
		this.DOMTimeline.addEventListener('touchend', this.timelineUnpress);
		this.DOMTimeline.addEventListener('mouseleave', this.timelineUnpress);

		this.mediaElement.addEventListener('loadedmetadata', this.setTotalDuration);
		this.mediaElement.addEventListener('timeupdate', this.timeupdate);
		this.mediaElement.addEventListener('ended', this.ended);

		if (this.DOMCloseButton) {
			this.DOMCloseButton.addEventListener('click', this.toggleFullScreen);
		}

		if (this.DOMButtonFullscreen) {
			this.DOMButtonFullscreen.addEventListener('click', this.toggleFullScreen);
		}

		if (this.DOMButtonMute) {
			this.DOMButtonMute.addEventListener('click', this.toggleMute);
		}

		if (!this.sizes.isLaptop) {
			this.layer.querySelector('.situation-module__main').addEventListener('click', this.onPlay);

			this.mediaElement.addEventListener('fullscreenchange', () => {
				this.toggleState();
			});

			this.mediaElement.addEventListener('webkitbeginfullscreen', () => {
				this.toggleState();
			});

			this.mediaElement.addEventListener('webkitendfullscreen', () => {
				this.toggleState();
			});
		}
	}

	timeupdate = () => {
		const formated = this.convertDuration(this.mediaElement.currentTime);

		this.DOMDurationCurrent.innerHTML = formated;
		this.updateProgressBar(this.mediaElement.currentTime / this.mediaElement.duration);
	};

	setTotalDuration = () => {
		const formated = this.convertDuration(this.mediaElement.duration);
		this.DOMDurationTotal.innerHTML = formated;
	};

	convertDuration(duration) {
		const seconds = Math.floor(duration % 60);
		const minutes = Math.floor((duration / 60) % 60);
		return this.formatDuration(seconds, minutes);
	}

	formatDuration(seconds, minutes) {
		return `${minutes}:${seconds > 9 ? seconds : '0' + seconds}`;
	}

	playPromise = null;

	toggleState = () => {
		let shouldMute = Globals.AUDIO_ENGINE.muted;
		if (this.sizes.isLaptop || this.mediaType === 'audio') {
			this.DOMButtonPlay.classList.toggle('is-playing');
		}

		var isPlaying = this.mediaElement.currentTime > 0 && !this.mediaElement.paused && !this.mediaElement.ended && this.mediaElement.readyState > 2;

		if (!isPlaying) {
			this.mediaElement.play();
			this.isPlaying = true;
		} else if (this.isPlaying) {
			this.mediaElement.pause();
			this.isPlaying = false;
		}

		// this.isPlaying ? this.mediaElement.pause() : this.mediaElement.play();
		// this.isPlaying = !this.isPlaying;

		shouldMute = this.isPlaying;
		Globals.AUDIO_ENGINE.toggleMute(shouldMute, shouldMute ? 1 : 0.5);
	};

	onPlay = e => {
		e.stopPropagation();
		if (this.sizes.isMobile || this.sizes.isTablet) {
			if (this.mediaType === 'video') {
				this.requestFullScreen();
			} else {
				this.toggleState();
			}
		} else {
			this.toggleState();
		}
	};

	requestFullScreen() {
		if (this.mediaElement.requestFullscreen) {
			this.mediaElement.requestFullscreen();
		} else {
			// @ts-ignore
			if (this.mediaElement.mozRequestFullScreen) {
				// @ts-ignore
				this.mediaElement.mozRequestFullScreen();
				// @ts-ignore
			} else if (this.mediaElement.webkitRequestFullscreen) {
				// @ts-ignore
				this.mediaElement.webkitRequestFullscreen();
				// @ts-ignore
			} else if (this.mediaElement.msRequestFullscreen) {
				// @ts-ignore
				this.mediaElement.msRequestFullscreen();
			} else {
				// @ts-ignore
				this.mediaElement.webkitEnterFullscreen();
			}
		}
	}

	toggleMute = () => {
		this.DOMButtonMute.classList.toggle('is-muted');
		this.mediaElement.muted = !this.mediaElement.muted;
	};

	timelinePress = e => {
		this.press = true;
	};

	timelineUnpress = () => {
		this.press = false;
	};

	timelineClick = event => {
		this.updateTimeline(event);
	};

	seek = event => {
		if (!this.press) return;
		this.updateTimeline(event);
	};

	updateTimeline = event => {
		const x = event.touches ? event.touches[0].clientX : event.clientX;

		const {width, left} = this.timelineRects;
		const start = x - left;
		const percentage = start / width;
		this.playedPercentage = percentage;
		this.updateProgressBar(percentage);
		this.updateCurrentTime(percentage);
	};

	toggleFullScreen = () => {
		this.isFullscreen = !this.isFullscreen;
		Globals.DISABLE_CONTROLS = this.isFullscreen;

		emitter.emit(EVENTS.fullScreen, {value: this.isFullscreen, layerName: this.name});
		this.DOMButtonFullscreen.classList.toggle('is-fullscreen');
	};

	updateProgressBar(progress) {
		gsap.to(this.DOMTimelineProgress, {duration: 0, x: `-${100 - progress * 100}%`, ease: 'none'});
	}

	updateCurrentTime = progress => {
		this.mediaElement.currentTime = this.mediaElement.duration * progress;
	};

	resize() {
		this.timelineRects = this.DOMTimeline.getBoundingClientRect();
	}

	ended = () => {
		this.toggleState();
	};

	reset = () => {
		if (this.isPlaying) {
			this.toggleState();
		}
	};
}
