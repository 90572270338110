import * as CANNON from 'cannon';
import * as THREE from 'three';
import Resources from '../Resources';
import {emitter, EVENTS} from '../utils/Dispatcher';
import Physics from './Physics';

export default class Trees {
	private resources: Resources;
	private scene: THREE.Scene;
	private debug: any;
	private physics: Physics;
	private trees = [];
	private treePosition = [
		{
			name: 'remotefence',
			physics: true,
			positions: [
				[-36.3, 0, -34.84],
				[-40.92, 0, -37.44] /*,
				[-31, 0, -42],
				[-50, 0, -80],
				[-30, 0, -66]*/
			]
		},
		{
			name: 'jogger',
			physics: false,
			positions: [
				[80.14, 0, -3.59],
				[87.67, 0, 1.91],
				[71.16, 0, -10.36]
			]
		}
	];

	constructor(resources: Resources, scene: THREE.Scene, physics: Physics, debug) {
		this.resources = resources;
		this.scene = scene;
		this.debug = debug;
		this.physics = physics;

		emitter.on(EVENTS.fadeAssets, this.toggleFade);

		this.createTrees();
	}

	createTrees() {
		const tree = this.resources.items.tree.scene;
		tree.scale.set(0.9, 0.9, 0.9);
		this.updateMaterial(tree);

		const p = tree.getObjectByName('tree_stem_MM') as THREE.Mesh;
		const sizes = this.physics.getBounds(p);

		this.treePosition.forEach((trees, i) => {
			trees.positions.forEach((position, j) => {
				const treeClone = this.createTree(tree, sizes, position);
				treeClone.name = `tree ${trees.name}-${j}`;
				this.trees.push(treeClone);
				this.scene.add(treeClone);

				if (trees.physics) {
					const physicsClone = this.createPhysics(sizes, null, position);
					this.physics.world.addBody(physicsClone.physics);
				}
			});
		});
	}

	createTree(model, sizes, position) {
		const clone = model.clone();
		clone.position.x = position[0];
		clone.position.y = sizes.y * 0.25;
		clone.position.z = position[2];
		clone.rotation.y = -1.78;
		return clone;
	}

	createPhysics(sizes, model, position) {
		const treePhysics = new CANNON.Body({
			mass: 0,
			position: new CANNON.Vec3(position[0], sizes.y * 0.5, position[2]),
			shape: new CANNON.Box(new CANNON.Vec3(sizes.x * 0.5, sizes.y * 0.5, sizes.z * 0.5))
		});

		return {physics: treePhysics};
	}

	updateMaterial(model) {
		model.traverse((object: any) => {
			if (object.isMesh) {
				if (object.name === 'shadow') {
					let material = object.material as THREE.MeshStandardMaterial;
					material.alphaMap = (material as THREE.MeshStandardMaterial).emissiveMap;
					material.emissiveMap = null;
					material.emissiveIntensity = 0;
					material.premultipliedAlpha = true;
					material.fog = true;
					material.color.set(0x000000);
					material.flatShading = true;
					material.transparent = true;
					material.opacity = 0.5;
					material.depthWrite = false;
					material.needsUpdate = true;
				} else if (object.name == 'tree_top_MM') {
					(object.material as THREE.MeshStandardMaterial).roughness = 0.5;
					object.material.metalness = 0;
					object.material.emissive = new THREE.Color('#506533');
					object.material.transparent = true;
					object.material.needsUpdate = true;
				} else {
					(object.material as THREE.MeshStandardMaterial).roughness = 0.5;
					object.material.metalness = 0;
					object.material.emissive = new THREE.Color('#A8B178');
					object.material.transparent = true;
					object.material.needsUpdate = true;
				}
			}
		});
	}

	toggleFade = toggled => {
		// this.trees.forEach(tree => {
		// 	tree.traverse(object => {
		// 		if (object.isMesh) {
		// 			gsap.to(object.material, {
		// 				opacity: toggled ? 0 : 1,
		// 				duration: 0.3,
		// 				ease: 'none',
		// 				onUpdate: () => {
		// 					object.material.needsUpdate = true;
		// 				}
		// 			});
		// 		}
		// 	});
		// });
	};
}
