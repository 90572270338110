import * as CANNON from 'cannon';
import {gsap} from 'gsap';
import * as THREE from 'three';

import Controls from '../Controls';
import {emitter, EVENTS} from '../utils/Dispatcher';
import Sizes from '../utils/Sizes';
import Time from '../utils/Time';
// import DropManager from './DropManager';
import SmokeParticles from './SmokeParticles';
import {clamp} from 'gsap/gsap-core';
import {ISituationConfig, SituationManager} from './SituationManager';
import {UFOArrows} from './UFOArrows';
import Physics from './Physics';
import {WorldRenderer} from './Renderer';
import Resources from '../Resources';
import {Camera} from './Camera';
import {Globals} from '../utils/Globals';
import {UFOBeam} from './UFOBeam';

export class Ufo {
	private acceleration = new THREE.Vector3();
	private accelerationMax = new THREE.Vector3();
	private accelerationMin = new THREE.Vector3();
	private ambientMove = 0;
	private ambientMoveTwo = 0;
	private camera: Camera;
	private debug: dat.GUI;
	private debugFolder: any;
	private deltaPosition = new CANNON.Vec3();
	private direction: number = 1;
	private directionalArrows: UFOArrows;
	private dropPoint;
	private euler = new THREE.Euler();
	private fastTravelTween: gsap.core.Tween;
	private inputVelocity = new THREE.Vector3();
	private jet: any;
	private jetAxe: any;
	private jetLeft: THREE.Mesh;
	private jetParticle: THREE.Mesh;
	private jetRight: THREE.Mesh;
	private jetRotation: number = 0;
	private leftParticlePoint: any;
	public leftParticles: SmokeParticles;
	private lerpHight;
	private lerpReactor = 0;
	private lerpRotation = 0;
	private lerpRotationLeft: number = 0;
	private lerpRotationRight: number = 0;
	private material = {
		roughness: 0.5,
		metalness: 0,
		emissive: '#F2D9A6'
	};
	private movementPhysicsFrame: THREE.Mesh;
	private params = {
		mass: 20,
		vertical: {
			firstState: 20,
			initial: 6,
			min: 5,
			max: 100
		},
		physics: {
			movement: {
				size: 0.8,
				mass: 20,
				damping: 0.8,
				acceleration: 0.02,
				accelerationMax: 0.8
			},
			ufo: {
				size: 1.5,
				mass: 1
			}
		}
	};
	private physics: Physics;
	private quat = new THREE.Quaternion();
	private renderer: WorldRenderer;
	private resources: Resources;
	private rightParticlePoint: any;
	public rightParticles: SmokeParticles;
	// private rotation = -Math.PI / 2;
	private rotation = 0;
	private rotationLeft = -Math.PI / 2;
	private rotationRight = -Math.PI / 2;
	private scene: THREE.Scene;
	private shadow: THREE.Mesh;
	private situationHeightLoopOver: number = 0;
	private situations: SituationManager;
	private time: Time;
	private ufoAnchor: CANNON.Body;
	private ufoAnchorVisual: THREE.Mesh;
	private ufoBody;
	private ufoModelOffset = new THREE.Vector3();
	private ufoOldPosition = new CANNON.Vec3();
	private ufoPhysicsFrame: THREE.Mesh;
	private ufoStartPosition = new CANNON.Vec3(23.66, 1.5, -17.38);
	// private ufoStartPosition = new CANNON.Vec3(-25.54, 0, -0.56);
	private ufoStartRotation = 0;
	private velocity = new THREE.Vector3();
	private vertical: number;
	public container: THREE.Group;
	public currSpeed: number = 0;
	public dropper;
	public movementPhysics: CANNON.Body;
	public target;
	public ufoPhysics: CANNON.Body;
	private containerOffset = new THREE.Vector3();

	private ufoReadyToMove = false;
	private _inputActive: boolean = false;
	public beam: UFOBeam;
	private ufoStartedToMove = false;
	private travelTimeline: gsap.core.Timeline;
	public isTravelling = false;
	private sizes: Sizes;

	constructor(resources: Resources, scene: THREE.Scene, physics: Physics, camera: Camera, situations: SituationManager, renderer: WorldRenderer, debug: dat.GUI) {
		this.resources = resources;
		this.scene = scene;
		this.physics = physics;
		this.camera = camera;
		this.situations = situations;
		this.renderer = renderer;
		this.debug = debug;
		this.sizes = new Sizes();

		this.init();
		gsap.to(this, {duration: 2 * 1.5, ambientMove: 1, repeat: -1, yoyo: true, ease: 'sine.inOut'});
		gsap.to(this, {duration: 3 * 1.5, ambientMoveTwo: 1, repeat: -1, yoyo: true, ease: 'sine.inOut'});
	}

	private init() {
		this.vertical = this.params.vertical.initial;
		this.lerpHight = this.vertical;
		this.ufoOldPosition = this.ufoStartPosition.clone();

		this.rotation = this.ufoStartRotation;
		this.lerpReactor = this.rotation;
		this.lerpRotation = this.rotation;

		this.acceleration.set(0, 0, this.params.physics.movement.acceleration);
		this.accelerationMax.set(0, 0, this.params.physics.movement.accelerationMax);
		this.accelerationMin.set(0, 0, -this.params.physics.movement.accelerationMax);

		this.time = new Time();

		this.container = new THREE.Group();
		this.container.name = 'ufoGroup';

		this.initModel();
		this.createPhysics();
		this.bindEvents();

		if (this.debug) {
			this.initDebug();
		}
	}

	private bindEvents() {
		emitter.on(EVENTS.tick, this.raf);
		emitter.on(EVENTS.dropObject, this.drop);
		emitter.on(EVENTS.firstMove, this.firstMove);
	}

	private initModel() {
		const gltf = this.resources.items.ufo;
		const animations = gltf.animations;
		const model = gltf.scene;

		let mat = null;
		model.traverse(function(object: THREE.Mesh) {
			if (object.isMesh) {
				if (mat === null) {
					// mat = new THREE.MeshStandardMaterial({metalness: 0.99, flatShading: true, color: 0xffffff, emissive: 0x000000, roughness: 0.01, emissiveMap: (object.material as THREE.MeshStandardMaterial).emissiveMap})
					/*					(object.material as THREE.MeshStandardMaterial).metalness = 0.99;
					(object.material as THREE.MeshStandardMaterial).roughness = 0.01;
					(object.material as THREE.MeshStandardMaterial).flatShading = true; Globals.I_OS ? true : false;
					// (object.material as THREE.MeshStandardMaterial).emissive = new THREE.Color('#F2D9A6');
					(object.material as THREE.MeshStandardMaterial).needsUpdate = true;*/
				}
				// object.material = mat;
			}
		});

		this.initBody(model, animations);
	}

	private initDebug() {
		this.debugFolder = this.debug.addFolder('ufo');
		this.debugFolder
			.add(this.accelerationMax, 'z')
			.step(0.1)
			.min(0)
			.max(2)
			.name('acceleration max')
			.listen();
		this.debugFolder
			.add(this.acceleration, 'z')
			.step(0.01)
			.min(0)
			.max(2)
			.name('acceleration speed')
			.listen();
		this.debugFolder
			.add(this.params.physics.movement, 'damping')
			.step(0.01)
			.min(0)
			.max(1)
			.name('physics damping')
			.onChange(() => {
				this.movementPhysics.linearDamping = this.params.physics.movement.damping;
			});
	}

	private initBody(model: THREE.Group, animations) {
		const body = model.getObjectByName('ship');
		this.ufoBody = body.getObjectByName('body');
		(this.ufoBody.geometry as THREE.BufferGeometry).computeVertexNormals();
		(this.ufoBody.material as THREE.MeshStandardMaterial).metalness = 1;
		(this.ufoBody.material as THREE.MeshStandardMaterial).roughness = 0.5;
		(this.ufoBody.material as THREE.MeshStandardMaterial).flatShading = false;
		(this.ufoBody.material as THREE.MeshStandardMaterial).color.setHex(0x121212);
		(this.ufoBody.material as THREE.MeshStandardMaterial).needsUpdate = true;
		// (this.ufoBody.material as THREE.MeshStandardMaterial).emissiveIntensity = 1;
		this.dropPoint = body.getObjectByName('drop');

		this.createShadow(body);
		this.initJet(body, model);

		this.ufoBody.getWorldPosition(this.ufoModelOffset);

		let lid = body.getObjectByName('lid') as THREE.Mesh;
		lid.geometry.computeVertexNormals();
		this.beam = new UFOBeam(this.resources, this.container, this.scene, this.camera, lid, this.debug);
	}

	private createShadow(body) {
		this.shadow = body.getObjectByName('shadow_floor') as THREE.Mesh;

		let shadowOverrideMaterial = (this.shadow.material as THREE.MeshStandardMaterial).clone();
		shadowOverrideMaterial.alphaMap = (shadowOverrideMaterial as THREE.MeshStandardMaterial).emissiveMap;
		shadowOverrideMaterial.emissiveMap = null;
		shadowOverrideMaterial.color = new THREE.Color(0x000000);
		shadowOverrideMaterial.emissive = new THREE.Color(0x000000);
		shadowOverrideMaterial.fog = true;
		shadowOverrideMaterial.flatShading = true;
		shadowOverrideMaterial.transparent = true;
		shadowOverrideMaterial.opacity = 0.5;
		shadowOverrideMaterial.depthTest = true;
		shadowOverrideMaterial.depthWrite = false;
		shadowOverrideMaterial.metalness = 0;
		shadowOverrideMaterial.roughness = 0;
		shadowOverrideMaterial.premultipliedAlpha = true;
		shadowOverrideMaterial.needsUpdate = true;
		/*
		shadowOverrideMaterial.alphaMap = shadowOverrideMaterial.emissiveMap;
		shadowOverrideMaterial.emissiveMap = null;
		shadowOverrideMaterial.metalness = 0;
		shadowOverrideMaterial.roughness = 0;
		shadowOverrideMaterial.opacity = 0.5;
		shadowOverrideMaterial.fog = false;
		shadowOverrideMaterial.flatShading = true;
		shadowOverrideMaterial.premultipliedAlpha = true;
		shadowOverrideMaterial.transparent = true;
		shadowOverrideMaterial.depthWrite = false;
		shadowOverrideMaterial.color = new THREE.Color(0x000000);
		shadowOverrideMaterial.needsUpdate = true;*/
		// @ts-ignore
		// this.shadow.material.dispose();
		this.shadow.material = shadowOverrideMaterial;
		this.scene.add(this.shadow);
	}

	private initJet(body: THREE.Object3D, model: THREE.Group) {
		this.jet = body.getObjectByName('jet');
		this.jetParticle = model.getObjectByName('smoke_ring') as THREE.Mesh;
		this.jetParticle.parent.remove(this.jetParticle);
		this.jetAxe = body.getObjectByName('axis');

		let jetMaterial = this.ufoBody.material.clone();
		jetMaterial.color.setHex(0x353535);

		this.jetLeft = this.jetAxe.getObjectByName('jet_left');
		this.leftParticlePoint = this.jetLeft.getObjectByName('particle_point_1');
		this.jetRight = this.jetAxe.getObjectByName('jet_right');
		this.jetLeft.material = this.jetRight.material = jetMaterial;
		(this.jetParticle.geometry as THREE.BufferGeometry).computeVertexNormals();
		(this.jetLeft.geometry as THREE.BufferGeometry).computeVertexNormals();
		(this.jetRight.geometry as THREE.BufferGeometry).computeVertexNormals();
		this.rightParticlePoint = this.jetRight.getObjectByName('particle_point_2');
		this.leftParticles = new SmokeParticles(this.scene, this.jetParticle, this.leftParticlePoint, this);
		this.rightParticles = new SmokeParticles(this.scene, this.jetParticle, this.rightParticlePoint, this);
		this.container.add(model);
	}

	private createPhysics() {
		const boxBounds = 1.5;
		this.movementPhysics = new CANNON.Body({
			mass: this.params.mass,
			shape: new CANNON.Sphere(boxBounds),
			position: this.ufoStartPosition,
			material: this.physics.ufoMaterial
		});
		this.movementPhysics.linearDamping = this.params.physics.movement.damping;
		this.movementPhysics.angularDamping = 0.1;
		// @ts-ignore
		this.movementPhysics.name = 'ufo';

		this.movementPhysicsFrame = new THREE.Mesh(new THREE.SphereBufferGeometry(boxBounds), new THREE.MeshNormalMaterial({wireframe: true, visible: this.physics.showFrame}));

		this.ufoPhysics = new CANNON.Body({
			mass: 5,
			shape: new CANNON.Box(new CANNON.Vec3(1.5, 1.5, 1.5)),
			position: new CANNON.Vec3(0, this.vertical + 4.5, 0),
			material: this.physics.ufoMaterial
		});

		this.ufoPhysicsFrame = new THREE.Mesh(new THREE.BoxBufferGeometry(3, 3, 3), new THREE.MeshNormalMaterial({wireframe: true, visible: this.physics.showFrame}));
		this.movementPhysics.addEventListener('collide', this.collisionManager);

		this.scene.add(this.movementPhysicsFrame);

		// this.createUfoConstrains();
	}

	private collisionManager(e) {}

	private createUfoConstrains() {
		this.ufoAnchor = new CANNON.Body({
			mass: 0,
			shape: new CANNON.Box(new CANNON.Vec3(1.5, 0.2, 1.5)),
			position: new CANNON.Vec3(0, this.vertical + 8, 0)
		});

		this.ufoAnchorVisual = new THREE.Mesh(new THREE.BoxBufferGeometry(3, 0.4, 3), new THREE.MeshNormalMaterial({wireframe: true, visible: this.physics.showFrame}));

		this.physics.registerObject(this.ufoAnchorVisual, this.ufoAnchor);
		this.physics.registerObject(this.ufoPhysicsFrame, this.ufoPhysics);

		this.physics.world.addBody(this.ufoAnchor);
		this.physics.world.addBody(this.ufoPhysics);

		this.scene.add(this.ufoAnchorVisual);
		this.scene.add(this.ufoPhysicsFrame);

		let ufoConstrain1 = new CANNON.ConeTwistConstraint(this.ufoPhysics, this.ufoAnchor, {pivotA: new CANNON.Vec3(1.5, 1.8, 1.5), pivotB: new CANNON.Vec3(1.5, 0, 1.5)});
		let ufoConstrain2 = new CANNON.ConeTwistConstraint(this.ufoPhysics, this.ufoAnchor, {pivotA: new CANNON.Vec3(-1.5, 1.8, -1.5), pivotB: new CANNON.Vec3(-1.5, 0, -1.5)});
		let ufoConstrain3 = new CANNON.ConeTwistConstraint(this.ufoPhysics, this.ufoAnchor, {pivotA: new CANNON.Vec3(-1.5, 1.8, 1.5), pivotB: new CANNON.Vec3(-1.5, 0, 1.5)});
		let ufoConstrain4 = new CANNON.ConeTwistConstraint(this.ufoPhysics, this.ufoAnchor, {pivotA: new CANNON.Vec3(1.5, 1.8, -1.5), pivotB: new CANNON.Vec3(1.5, 0, -1.5)});
		let ufoConstrain5 = new CANNON.ConeTwistConstraint(this.ufoPhysics, this.ufoAnchor, {pivotA: new CANNON.Vec3(0, 1.8, 0), pivotB: new CANNON.Vec3(0, 0, 0)});

		this.physics.world.addConstraint(ufoConstrain1);
		this.physics.world.addConstraint(ufoConstrain2);
		this.physics.world.addConstraint(ufoConstrain3);
		this.physics.world.addConstraint(ufoConstrain4);
		this.physics.world.addConstraint(ufoConstrain5);

		this.scene.add(this.ufoPhysicsFrame);
	}

	private drop = code => {
		/*		let v = new THREE.Vector3();
		this.dropPoint.getWorldPosition(v);
		this.dropper.drop(code, v);*/
	};

	// @Todo: find a way to make the condition work when ufo fast travel
	// currently the condition doesnt allow to cast beam on demand
	private currSitPercentage = 0;
	public situationProgress = (situation: ISituationConfig, percentage?: number) => {
		// if (this.currSitPercentage !== percentage) {
		gsap.to(this, {duration: 0.2, situationHeightLoopOver: percentage * situation.loopHeight, ease: 'none', overwrite: 'auto'});
		this.beam.setHeight(percentage * situation.loopHeight);
		this.currSitPercentage = percentage;
		// }
		// this.situationHeightLoopOver = percentage * 4;
	};

	private moveUpAndDown() {
		const step = 0.1;
		const min = this.params.vertical.min;
		const max = this.params.vertical.max;

		if (Controls.actions.upwards) {
			this.vertical += step;
		} else if (Controls.actions.downwards) {
			this.vertical -= step;
		}

		this.vertical = clamp(min, max, this.vertical);
	}

	firstMove = () => {
		this.ufoStartedToMove = true;
	};

	private movementLogic() {
		this.velocity.set(0, 0, 0);

		if (Controls.actions.forward) {
			if (this.fastTravelTween) {
				this.fastTravelTween.kill();
			}

			this.inputVelocity.add(this.acceleration /*.multiplyScalar(this.time.FPSFactor)*/);
			this.direction = 1;
		} else if (Controls.actions.backward) {
			if (this.fastTravelTween) {
				this.fastTravelTween.kill();
			}
			this.inputVelocity.sub(this.acceleration /*.multiplyScalar(this.time.FPSFactor)*/);
			this.direction = -1;
		} else {
			this.inputVelocity.set(0, 0, 0);
		}

		this.inputVelocity.clamp(this.accelerationMin, this.accelerationMax);
		const slowFactor = Controls.isPressing ? Controls.speed : 1;
		this.velocity.z = this.inputVelocity.z * slowFactor;

		if (Controls.actions.forward || Controls.actions.backward) {
			this._inputActive = true;
			Globals.AUDIO_ENGINE.toggleEngineWarmup(true);
			gsap.to(Globals.AUDIO_ENGINE, {engineSpeed: Math.abs(this.inputVelocity.z) / this.accelerationMax.z, duration: 0.3, overwrite: true});
		} else if (this._inputActive) {
			this._inputActive = false;
			Globals.AUDIO_ENGINE.toggleEngineWarmup(false);
			gsap.killTweensOf(Globals.AUDIO_ENGINE);
			gsap.to(Globals.AUDIO_ENGINE, {engineSpeed: 0, duration: 1.5});
		}

		this.euler.y = this.rotation;
		this.quat.setFromEuler(this.euler);
		this.velocity.applyQuaternion(this.quat);
	}

	private rotationLogic() {
		const rotationFactor = 0.05 * this.time.FPSFactor;

		if (!Controls.isTouching && !Controls.isPressing) {
			if (Controls.actions.right) {
				this.rotation -= rotationFactor;
				this.rotationLeft -= rotationFactor;
				this.rotationRight += rotationFactor;
			} else if (Controls.actions.left) {
				this.rotationLeft += rotationFactor;
				this.rotationRight -= rotationFactor;
				this.rotation += rotationFactor;
			}
			Controls.setAngleTest(-this.rotation);
		} else {
			this.rotation = -Controls.angle;
		}

		this.lerpRotation += (this.rotation - this.lerpRotation) * this.lerpRotationFactor;
		this.lerpReactor += (this.rotation - this.lerpReactor) * this.lerpReactorFactor;

		if (this.jet) {
			this.currSpeed = this.getSpeed();

			const jetRotation = gsap.utils.clamp(0, this.isTravelling ? 0.5 : 5, (Math.PI / 2) * this.currSpeed * 1.5) * this.direction;

			gsap.to(this, {jetRotation: jetRotation, duration: 0.3});
		}
	}

	lerpRotationFactor = 0.15;
	lerpReactorFactor = 0.1;

	/**
	 * Apply velocity to physics body
	 */
	private updatePhysics() {
		this.movementPhysics.velocity.x += this.velocity.x;
		this.movementPhysics.velocity.z += this.velocity.z;
		this.movementPhysics.position.y = 1.5;
		this.movementPhysics.quaternion.setFromAxisAngle(new CANNON.Vec3(0, 1, 0), this.lerpRotation);

		// this.ufoAnchor.position.x = this.movementPhysics.position.x;
		// this.ufoAnchor.position.z = this.movementPhysics.position.z;
		// this.ufoAnchor.position.y = this.vertical + this.ambientMove * (0.3 - this.currSpeed) * 0.6 + this.situationHeightLoopOver;

		// this.ufoAnchor.quaternion.setFromAxisAngle(new CANNON.Vec3(0, 1, 0), this.lerpRotation);
	}

	/**
	 * Update three js object based on physics position/rotation
	 */
	private updateVisual() {
		const currSpeedOffset = 0.3 - this.currSpeed;

		// const posY = this.ufoPhysics.position.y
		const posY = this.vertical + this.ambientMove * (0.3 - this.currSpeed) * 0.6 + this.situationHeightLoopOver;

		this.containerOffset.y = posY - this.ufoModelOffset.y - 1.5;
		this.container.position.copy(this.movementPhysics.position as any).add(this.containerOffset);

		this.ufoBody.quaternion.copy(this.movementPhysics.quaternion as any);

		const ambientRotation = (Math.PI * -0.25 + Math.PI * 0.45 * (0.5 + this.ambientMove * 0.25)) * currSpeedOffset;

		//Tilt ufo forward on movement:

		this.ufoBody.rotateX(this.jetRotation * 0.5 + ambientRotation);
		// this.ufoBody.position.y += this.ambientMove * 0.2;
		// this.ufoBody.position.translateY(this.ambientMove * 0.2);

		//Floor shadow plane:
		this.shadow.quaternion.copy(this.movementPhysics.quaternion as any);
		this.shadow.position.copy(this.movementPhysics.position as any);
		this.shadow.position.setY(0.02);
		const shadowScale = 1 + (this.container.position.y - 3) * 0.1;
		this.shadow.scale.set(shadowScale, shadowScale, shadowScale);
		//

		this.rotationLeft = this.rotationLeft * 0.9;
		this.rotationRight = this.rotationRight * 0.9;

		//Jets movement:
		this.lerpRotationLeft += (this.rotationLeft - this.lerpRotationLeft) * 0.2;
		this.lerpRotationRight += (this.rotationRight - this.lerpRotationRight) * 0.2;
		if (this.jet) {
			this.jet.rotation.y = this.lerpReactor;
			this.jetAxe.rotation.x = this.jetRotation;
		}

		if (!this.isTravelling) {
			this.jetLeft.rotation.x = this.lerpRotationLeft * 0.75 + ambientRotation;
			this.jetLeft.position.setY(-0.5 * this.currSpeed + this.ambientMove * -0.4 * currSpeedOffset + ambientRotation);
			this.jetRight.position.setY(-0.5 * this.currSpeed + this.ambientMove * -0.4 * currSpeedOffset + ambientRotation);
			this.jetLeft.position.setZ(-0.5 * this.currSpeed);
			this.jetRight.position.setZ(-0.5 * this.currSpeed);
			this.jetRight.rotation.x = this.lerpRotationRight * 0.75 + ambientRotation;
			this.jetLeft.rotation.z = this.lerpRotationLeft * 0.2 + -ambientRotation * 0.5;
			this.jetRight.rotation.z = this.lerpRotationLeft * 0.2 + ambientRotation * 0.4;
		}

		this.movementPhysicsFrame.rotation.y = this.rotation;
		this.movementPhysicsFrame.position.copy(this.movementPhysics.position as any);
	}

	private getSpeed(): number {
		this.deltaPosition = this.deltaPosition.copy(this.movementPhysics.position);
		this.deltaPosition = this.deltaPosition.vsub(this.ufoOldPosition);
		this.ufoOldPosition.copy(this.movementPhysics.position);
		return (this.deltaPosition as any).length();
	}

	public teleportTo = (position, height) => {
		this.camera.slowFactor = 0.8;

		gsap.killTweensOf(Globals.AUDIO_ENGINE);
		gsap.to(Globals.AUDIO_ENGINE, {engineSpeed: 1, duration: 1});
		gsap.to(Globals.AUDIO_ENGINE, {delay: 2, engineSpeed: 0, duration: 1});
		Globals.AUDIO_ENGINE.toggleEngineWarmup(true);

		this.travelTimeline = gsap.timeline({
			onStart: () => {
				// NOTE: Useful if there is physics on the way between the ufo and the fast travel target
				// Issue: Keep the current velocity in memory and restore it when it is back to DYNAMIC
				// TODO: FIX it
				// this.movementPhysics.type = CANNON.Body.KINEMATIC;
			},
			onComplete: () => {
				// this.movementPhysics.type = CANNON.Body.DYNAMIC;
				this.fastTravelTween = null;
				Globals.AUDIO_ENGINE.toggleEngineWarmup(false);

				if (!this.sizes.isLaptop) {
					this.rotation = -Math.PI / 2 + 0.28;
					this.lerpRotation = this.rotation;
					this.lerpReactor = this.rotation;
					Controls.setAngle(this.rotation * -1);
				}
			}
		});
		this.travelTimeline.to(this.movementPhysics.position, {duration: 4, x: position.x, z: position.z, ease: 'power3.inOut', overwrite: true}, 0);
		this.travelTimeline.to(this, {situationHeightLoopOver: 35, duration: 1.5, ease: 'power3.in'}, 0.3);

		const diff = this.rotation % Math.PI;

		const rotationOut = -Math.PI / 2 - Math.PI * 3;
		const rotationIn = -Math.PI / 2 + 0.28 - Math.PI * 4;

		this.travelTimeline.to(this, {rotation: rotationOut, duration: 2.5, ease: 'power1.in'}, 0);
		this.travelTimeline.to(this, {situationHeightLoopOver: height, duration: 1.2, ease: 'power3.out'}, 3);
		this.travelTimeline.to(this, {rotation: rotationIn, duration: 1, ease: 'none'}, 2.7);

		return this.travelTimeline;
	};

	public animateIn() {
		// emitter.emit(EVENTS.firstMove);

		let tl = gsap.timeline();
		tl.to(
			this,
			{
				rotation: -Math.PI / 2 + 0.28 + Math.PI * 4,
				duration: 4,
				ease: 'power2.inOut',
				onUpdate: () => this.rotationLogic(),
				onComplete: () => {
					this.rotation = -Math.PI / 2 + 0.28;
					this.lerpRotation = this.rotation;
					this.lerpReactor = this.rotation;
					this.rotationLogic();
					this.ufoReadyToMove = true;
				}
			},
			0
		);

		this.ufoModelOffset.y += 3.5;
		tl.to(
			this.ufoModelOffset,
			{
				duration: 3,
				y: this.ufoModelOffset.y - 3.5,
				ease: 'power3.inOut',
				onComplete: () => {
					this.leftParticles.startEmit();
					this.rightParticles.startEmit();
				}
			},
			0
		);
		return tl;
	}

	/**
	 * Frame function
	 */
	public raf = () => {
		if (this.ufoReadyToMove) {
			this.movementLogic();
			this.rotationLogic();

			this.directionalArrows && this.directionalArrows.updateArrowDirections();
		}

		this.moveUpAndDown();

		this.updatePhysics();
		this.updateVisual();

		this.leftParticles.updateRaf();
		this.rightParticles.updateRaf();
	};
}
