import {SituationManager} from '../world/SituationManager';
import Tone from 'tone';
import {clamp} from 'gsap/gsap-core';
import {gsap} from 'gsap';

interface IAudioSituation {
	name: string;
	player: Tone.Player;
	panner: Tone.Panner;
}

export class AudioSituations {
	private _names: Array<string> = ['busstation', 'desk', 'sofa', 'kitchen', 'career', 'remotefence', 'remote', 'jogger'];

	private _loops = {
		busstation: {
			volume: -5,
			loopStart: 0.2,
			loopEnd: 0.05
		},
		desk: {
			volume: 0,
			loopStart: 0,
			loopEnd: 0
		},
		sofa: {
			volume: -9,
			loopStart: 0.03,
			loopEnd: 0.05
		},
		kitchen: {
			volume: 0,
			loopStart: 0,
			loopEnd: 0
		},
		career: {
			volume: -7,
			loopStart: 0.1,
			loopEnd: 0.05
		},
		remotefence: {
			volume: 0,
			loopStart: 0.1,
			loopEnd: 0.05
		},
		remote: {
			volume: 0,
			loopStart: 0,
			loopEnd: 0
		},
		jogger: {
			volume: 0,
			loopStart: 0,
			loopEnd: 0
		}
	};

	private _situations: Array<IAudioSituation> = [];

	private _master: Tone.Channel;

	constructor(buffers: Tone.Buffers, master: Tone.Channel) {
		this._master = new Tone.Channel(-80).connect(master);
		let l = this._names.length;

		for (let i = 0; i < l; i++) {
			let panner = new Tone.Panner(0);
			panner.connect(this._master);

			let buffer = buffers.get(this._names[i]);

			let player = new Tone.Player({
				loop: true,
				loopStart: this._loops[this._names[i]].loopStart,
				loopEnd: buffer.duration - this._loops[this._names[i]].loopEnd
			});

			player.buffer = buffer;
			player.volume.value = -80;
			player._volumeOffset = this._loops[this._names[i]].volume;
			player.connect(panner);
			player.start();

			let situation = {name: this._names[i], player: player, panner: panner};
			this._situations.push(situation);
		}
	}

	public start = () => {
		gsap.to(this._master.volume, 2, {value: -10});
	};

	public update = (situations: SituationManager) => {
		let l = situations.situationDistancesToUfo.length;

		let situation;
		let volume;
		let pan;

		for (let i = 0; i < l; i++) {
			situation = this.getSituation(situations.situationDistancesToUfo[i].name);

			/**
			// PAN
			// Upper right
			if (situations.situationDistancesToUfo[i].angle <= 180 && situations.situationDistancesToUfo[i].angle >= 90) {
				pan = clamp(-1, 0, ((180 - situations.situationDistancesToUfo[i].angle) / 90) * -1);
			} // Lower right
			else if (situations.situationDistancesToUfo[i].angle >= 0 && situations.situationDistancesToUfo[i].angle < 90) {
				pan = clamp(-1, 0, (situations.situationDistancesToUfo[i].angle / 90) * -1);
			} // Lower left
			else if (situations.situationDistancesToUfo[i].angle <= 360 && situations.situationDistancesToUfo[i].angle >= 270) {
				pan = (360 - situations.situationDistancesToUfo[i].angle) / 90;
			} // Upper left
			else if (situations.situationDistancesToUfo[i].angle < 270 && situations.situationDistancesToUfo[i].angle > 180) {
				pan = (situations.situationDistancesToUfo[i].angle - 180) / 90;
			}
			situation.panner.pan.value = pan;
			 **/

			// VOLUME
			volume = clamp(0, 1, 1 - situations.situationDistancesToUfo[i].distance / 30);
			situation.player.volume.value = clamp(-80, -10, -80 + 120 * volume) + situation.player._volumeOffset;
		}
	};

	private getSituation = (name: string) => {
		let l = this._situations.length;

		for (let i = 0; i < l; i++) {
			if (this._situations[i].name === name) {
				return this._situations[i];
			}
		}
	};
}
