import {emitter, EVENTS} from './Dispatcher';
import {gsap} from 'gsap';
import * as THREE from 'three';
import {ActiveStateController} from './ActiveStateController';
import Global = WebAssembly.Global;
import {Globals} from './Globals';

class Time {
	public delta;
	private start;
	private current;
	public elapsed;
	private ticker;
	private clock = new THREE.Clock();
	public clockDelta = 0;

	static instance;
	private deltaTarget: number;
	public FPSFactor: number;
	private activeStateController: ActiveStateController;

	constructor() {
		if (Time.instance) {
			return Time.instance;
		}

		Time.instance = this;

		this.start = 0;
		this.current = this.start;
		this.elapsed = 0;
		this.delta = 16;
		this.deltaTarget = 1000 / 60;
		// console.log('INIT TIME');

		// gsap.ticker.lagSmoothing(1000, 16);
		// gsap.ticker.add(this.tick);
		// @ts-ignore
		if (Globals.DEBUG_PAUSE_RENDER) {
			this.activeStateController = new ActiveStateController();
		}
		// @ts-ignore
		this.ticker = window.requestAnimationFrame(this.tick);
	}

	tick = (time, deltaTime /*, frame*/) => {
		// @ts-ignore
		this.ticker = window.requestAnimationFrame(this.tick);

		// this.delta = deltaTime;
		this.delta = this.current !== 0 ? time - this.current : 0;
		this.elapsed = time - this.start;
		this.current = time;
		this.FPSFactor = this.delta / this.deltaTarget;
		this.clockDelta = this.clock.getDelta();

		/*		if (this.delta > 60) {
			this.delta = 60;
		}*/

		if (!this.activeStateController || this.activeStateController.pageVisible) {
			emitter.emit(EVENTS.tick);
		}
	};

	stop() {
		window.cancelAnimationFrame(this.ticker);
	}
}

export default Time;
