import {emitter, EVENTS} from './Dispatcher';

export default class Sizes {
	static instance;
	public $sizeViewport: HTMLDivElement;
	public viewport: any;
	public width: number;
	public height: number;
	static BREAKPOINTS = {
		SMALL: 600,
		MEDIUM: 1024,
		LARGE: 1440,
		LARGE_DESKTOP: 1367
	};
	public isMobile: boolean;
	public isTablet: boolean;
	public isLaptop: boolean;
	public isLargeDesktop: boolean;
	public isPortrait: boolean;
	public isIOS: boolean;
	public isAndroid: boolean;
	public currentBreakpoint = 320;

	/**
	 * Constructor
	 */
	constructor() {
		if (Sizes.instance) {
			return Sizes.instance;
		}

		Sizes.instance = this;

		this.viewport = {};
		this.$sizeViewport = document.createElement('div');
		this.$sizeViewport.style.width = '100vw';
		this.$sizeViewport.style.height = '100vh';
		this.$sizeViewport.style.position = 'absolute';
		this.$sizeViewport.style.top = '0';
		this.$sizeViewport.style.left = '0';
		this.$sizeViewport.style.pointerEvents = 'none';

		window.addEventListener('resize', this.resize);

		this.resize();
	}

	/**
	 * Resize
	 */
	resize = () => {
		document.body.appendChild(this.$sizeViewport);
		this.viewport.width = this.$sizeViewport.offsetWidth;
		this.viewport.height = this.$sizeViewport.offsetHeight;
		document.body.removeChild(this.$sizeViewport);

		this.width = window.innerWidth;
		this.height = window.innerHeight;

		this.isIOS = !!navigator.platform && /iPad|iPhone|iPod/.test(navigator.platform);
		this.isAndroid = /Android/i.test(navigator.userAgent);
		this.isMobile = this.width < Sizes.BREAKPOINTS.SMALL;
		this.isTablet = this.width < Sizes.BREAKPOINTS.MEDIUM && this.width >= Sizes.BREAKPOINTS.SMALL;
		// this.isLaptop = this.width < Sizes.BREAKPOINTS.LARGE && this.width >= Sizes.BREAKPOINTS.MEDIUM;
		this.isLaptop = this.width >= Sizes.BREAKPOINTS.MEDIUM;
		this.isLargeDesktop = this.width >= Sizes.BREAKPOINTS.LARGE_DESKTOP;
		this.isPortrait = this.width < this.height;

		emitter.emit(EVENTS.resize);
	};
}
