import Resources from '../Resources';
import * as THREE from 'three';
import {emitter, EVENTS} from '../utils/Dispatcher';
import {Camera} from './Camera';
import {gsap} from 'gsap';
import {Globals} from '../utils/Globals';
import Time from '../utils/Time';
import {Group, Object3D, Scene} from 'three';
import {GUI} from 'dat.gui';
import {ISituationConfig} from './SituationManager';

interface Uniforms {
	u_texture: {type: string; value: THREE.Texture};
	u_textureAlpha: {type: string; value: THREE.Texture};
	textureRepeatX: {type: string; value: number};
	textureRepeatY: {type: string; value: number};
	textOffset: {type: string; value: number};
	globalAlpha: {type: string; value: number};
}

interface GlowUniforms {
	c: {type: string; value: number};
	p: {type: string; value: number};
	textureAlpha: {type: string; value: THREE.Texture};
	glowColor: {type: string; value: THREE.Color};
	viewVector: {type: string; value: THREE.Vector3};
}

export class UFOBeam {
	public beam: THREE.Mesh;
	private container: THREE.Group;
	private geometry: THREE.CylinderBufferGeometry;
	private material: THREE.ShaderMaterial;
	private debug: dat.GUI;
	private texture: THREE.Texture;
	public isEmitting = true;
	private scene: THREE.Scene;
	private canvasTexture: THREE.Texture;
	private uniforms: Uniforms;
	private canvas: HTMLCanvasElement;
	private ctx: CanvasRenderingContext2D;
	private alphaHeight: number;
	private alphaCanvasSize = 128;
	private group: THREE.Group;
	private resources: Resources;
	private isActive = false;
	private camera: Camera;
	private beamGlowMaterial: THREE.ShaderMaterial;
	private beamGlow: THREE.Mesh;
	private icon: THREE.Mesh;
	private glowUniforms: GlowUniforms;
	private beamHeight = 9;
	public easeFactor = 0.1;
	private timelineCastOut: gsap.core.Timeline;
	private timelineCastIn: gsap.core.Timeline;
	private time: Time;
	private ufoLid: Object3D;
	private icons: THREE.Mesh[] = [];

	constructor(resources: Resources, container: Group, scene: Scene, camera: Camera, ufoLid: Object3D, debug: GUI) {
		this.ufoLid = ufoLid;
		this.resources = resources;
		this.container = container;
		this.debug = debug;
		this.camera = camera;
		this.scene = scene;
		this.alphaHeight = 0;
		this.group = new THREE.Group();
		this.time = new Time();
		this.createBeam(this.resources.items['BeamTexture']);

		emitter.on(EVENTS.tick, this.raf);
	}

	createBeam = texture => {
		this.texture = texture;
		this.texture.wrapS = this.texture.wrapT = THREE.RepeatWrapping;
		this.texture.minFilter = THREE.NearestFilter;
		this.texture.needsUpdate = true;

		this.canvas = document.createElement('canvas');
		this.ctx = this.canvas.getContext('2d');

		this.canvas.width = this.alphaCanvasSize;
		this.canvas.height = this.alphaCanvasSize;
		this.canvas.style.position = 'absolute';
		this.canvas.style.top = '0px';
		this.canvas.style.left = '0px';

		if (this.debug) {
			document.body.appendChild(this.canvas);
		}

		this.canvasTexture = new THREE.Texture(this.canvas);
		this.canvasTexture.minFilter = THREE.LinearFilter;
		this.canvasTexture.premultiplyAlpha = true;

		const vertexShader = `
			varying vec2 vUv;

			void main() {
				vUv = uv;

				vec4 mvPosition = modelViewMatrix * vec4( position, 1.0 );
				gl_Position = projectionMatrix * mvPosition;
			}
		`;

		const fragmentShader = `
			uniform sampler2D u_texture;
			uniform sampler2D u_textureAlpha;
			varying vec2 vUv;
			uniform float textureRepeatY;
			uniform float textureRepeatX;
			uniform float textOffset;
			uniform float globalAlpha;

			void main() {
				vec2 uvs = vUv;
				uvs.y = uvs.y * textureRepeatY;
				vec4 beam = texture2D( u_texture, uvs + textOffset);
				float alpha = texture2D( u_textureAlpha, vUv ).r;
				gl_FragColor = vec4(beam.rgb, globalAlpha * alpha);
			}
		`;

		this.uniforms = {
			u_texture: {type: 't', value: this.texture},
			u_textureAlpha: {type: 't', value: this.canvasTexture},
			textureRepeatX: {type: 'f', value: 1},
			textureRepeatY: {type: 'f', value: 10},
			textOffset: {type: 'f', value: 0.0},
			globalAlpha: {type: 'f', value: 0.16}
		} as Uniforms;

		this.geometry = new THREE.CylinderBufferGeometry(0.3, 2, this.beamHeight, 32, 1, true);
		this.material = new THREE.ShaderMaterial({
			// @ts-ignore
			uniforms: this.uniforms,
			fragmentShader,
			vertexShader,
			transparent: true,
			side: THREE.FrontSide,
			depthWrite: false
		});

		this.beam = new THREE.Mesh(this.geometry, this.material);
		this.beam.name = 'beam';
		this.beam.position.y = -3.1;

		this.group.add(this.beam);

		const vs = `
			uniform vec3 viewVector;
			uniform float c;
			uniform float p;
			varying float intensity;
			varying vec2 vUv;

			void main()
			{
				vUv = uv;
				vec3 vNormal = normalize( normalMatrix * normal );
				vec3 vNormel = normalize( normalMatrix * viewVector );
				intensity = pow( c - dot(vNormal, vNormel), p );

				gl_Position = projectionMatrix * modelViewMatrix * vec4( position, 1.0 );
			}
		`;

		const fs = `
			uniform vec3 glowColor;
			varying float intensity;
			uniform sampler2D textureAlpha;
			varying vec2 vUv;

			void main()
			{
				float alpha = texture2D( textureAlpha, vUv ).r;
				vec3 glow = glowColor * intensity;
				gl_FragColor = vec4( glow, alpha * 0.1);
			}
		`;

		this.glowUniforms = {
			c: {type: 'f', value: 0.0},
			p: {type: 'f', value: 0.0},
			textureAlpha: {type: 't', value: this.canvasTexture},
			glowColor: {type: 'c', value: new THREE.Color(0x2c6276)},
			viewVector: {type: 'v3', value: new THREE.Vector3(0, 0, 0)}
		} as GlowUniforms;

		this.beamGlowMaterial = new THREE.ShaderMaterial({
			// @ts-ignore
			uniforms: this.glowUniforms,
			vertexShader: vs,
			fragmentShader: fs,
			side: THREE.FrontSide,
			blending: THREE.AdditiveBlending,
			transparent: true,
			depthWrite: false,
			wireframe: true
		});

		this.beamGlow = new THREE.Mesh(new THREE.CylinderBufferGeometry(0.3 * 1.04, 2 * 1.05, this.beamHeight, 20, 1, true), this.beamGlowMaterial);
		this.beamGlow.position.y = -3.1;
		// this.beamGlow.scale.multiplyScalar(1.04);

		this.group.add(this.beamGlow);

		let icons = ['icon_podcast', 'icon_video', 'icon_questions', 'icon_interview'];
		let mat = new THREE.MeshStandardMaterial({emissive: 0xffffff, transparent: true, opacity: 0.8, flatShading: true});
		icons.forEach(icon => {
			let iconModel = this.resources.items.ufo.scene.getObjectByName(icon) as THREE.Mesh;
			this.icon = iconModel;
			this.icons[icon] = iconModel;
			iconModel.parent.remove(iconModel);
			iconModel.position.y = 3;
			// @ts-ignore
			iconModel.material = mat;
			iconModel.scale.set(0, 0, 0);
			this.group.add(iconModel);
		});
		this.container.add(this.group);

		this.group.visible = false;
		if (this.debug) {
			this.initDebug();
		}
	};

	public cast = (currentSituation: ISituationConfig) => {
		gsap.delayedCall(0.4, Globals.AUDIO_ENGINE.toggleBeam, [true]);

		if (this.timelineCastOut) {
			this.timelineCastOut.kill();
		}

		this.isActive = true;
		this.isEmitting = true;
		this.group.visible = true;
		this.icon = this.icons[currentSituation.icon];
		this.timelineCastIn = gsap.timeline();
		this.timelineCastIn.to(this, {delay: 0.4, introTweenedAlphaHeight: 0.5, duration: 0.8, ease: 'sine.inOut'}, 0);
		this.timelineCastIn.to(this.ufoLid.position, {y: 0.72, duration: 0.4, ease: 'sine.inOut'}, 0);
		this.timelineCastIn.to(this.icon.position, {y: 4, duration: 0.6, ease: 'power2.out'}, 0.5);
		this.timelineCastIn.to(this.icon.scale, {x: 0.5, y: 0.5, z: 0.5, duration: 0.6}, 0.5);
		gsap.killTweensOf(this.beamGlowMaterial.uniforms.glowColor.value);
		gsap.killTweensOf(this.uniforms.globalAlpha);
		gsap.fromTo(this.uniforms.globalAlpha, {overwrite: true, value: 0.04}, {value: 0.11, repeat: -1, yoyo: true, duration: 1, ease: 'sine.inOut'});
		gsap.fromTo(this.beamGlowMaterial.uniforms.glowColor.value, {overwrite: true, r: 44, g: 98, b: 118}, {r: 94, g: 211, b: 255, repeat: -1, yoyo: true, duration: 1, ease: 'sine.inOut'});
	};

	public stopCast = () => {
		gsap.killTweensOf(Globals.AUDIO_ENGINE.toggleBeam);
		Globals.AUDIO_ENGINE.toggleBeam(false);

		if (this.timelineCastIn) {
			this.timelineCastIn.kill();
		}

		this.timelineCastOut = gsap.timeline({
			onComplete: () => {
				this.isEmitting = false;
			}
		});
		this.timelineCastOut.to(this, {introTweenedAlphaHeight: 0, alphaHeight: 0, duration: 0.4, ease: 'sine.inOut'}, 0);
		this.timelineCastOut.to(this.icon.position, {y: 3, duration: 0.6, ease: 'power2.out'}, 0);
		this.timelineCastOut.to(this.ufoLid.position, {y: 0.951653003692627, duration: 0.6, ease: 'sine.inOut'}, 0.4);
		this.timelineCastOut.to(this.icon.scale, {x: 0, y: 0, z: 0, duration: 0.4}, 0);
		this.timelineCastOut.set(this.group, {visible: false});
		gsap.killTweensOf(this.beamGlowMaterial.uniforms.glowColor.value);
		gsap.killTweensOf(this.uniforms.globalAlpha);
		gsap.to(this.beamGlowMaterial.uniforms.glowColor.value, {overwrite: true, r: 44, g: 98, b: 118, duration: 0.4, ease: 'sine.out'});
		gsap.to(this.uniforms.globalAlpha, {overwrite: true, value: 0.04, duration: 0.4, ease: 'sine.inOut'});
	};

	initDebug() {
		const c = {
			v: '#00b1d7',
			s: 1.04
		};

		const debugFolder = this.debug.addFolder('beam');
		debugFolder
			.add(this, 'alphaHeight')
			.min(0)
			.max(1)
			.step(0.01);
		debugFolder
			.add(this.uniforms.globalAlpha, 'value')
			.name('Global alpha')
			.min(0)
			.max(1)
			.step(0.01);
		debugFolder
			.add(this.uniforms.textureRepeatY, 'value')
			.name('repeat y')
			.min(0)
			.max(10)
			.step(0.01);
		debugFolder
			.addColor(c, 'v')
			.name('glow color')
			.onChange(() => {
				this.glowUniforms.glowColor.value = new THREE.Color(c.v);
			});
		debugFolder
			.add(c, 's')
			.name('glow scale')
			.min(1)
			.max(2)
			.step(0.01)
			.onChange(() => {
				this.beamGlow.scale.set(c.s, c.s, c.s);
			});
		debugFolder
			.add(this.glowUniforms.c, 'value')
			.name('c')
			.min(0)
			.max(1)
			.step(0.01);
		debugFolder
			.add(this.glowUniforms.p, 'value')
			.name('p')
			.min(0)
			.max(10)
			.step(0.01);
		debugFolder
			.add(this.beamGlowMaterial, 'opacity')
			.name('opacity')
			.min(0)
			.max(1)
			.step(0.01)
			.onChange(() => {
				this.beamGlowMaterial.needsUpdate = true;
			});

		debugFolder.open();
	}

	drawCanvas = () => {
		this.ctx.clearRect(0, 0, this.alphaCanvasSize, this.alphaCanvasSize);
		this.ctx.fillStyle = '#000';
		this.ctx.fillRect(0, 0, this.alphaCanvasSize, this.alphaCanvasSize);

		// console.log(this.alphaHeight)

		const grd = this.ctx.createLinearGradient(0, 0, 0, this.alphaCanvasSize * this.alphaHeight);
		grd.addColorStop(0, 'white');
		grd.addColorStop(1, 'black');

		this.ctx.fillStyle = grd;
		this.ctx.fillRect(0, 0, 256, 256 * this.alphaHeight);
	};

	updateMaterial = () => {
		this.material.needsUpdate = true;
	};

	raf = () => {
		if (!this.beam || !this.isEmitting) return;

		if (this.uniforms) {
			this.uniforms.textOffset.value += 0.01 * this.time.FPSFactor;
			if (this.isActive && this.ctx) {
				this.drawCanvas();
				this.canvasTexture.needsUpdate = true;
			}
			this.material.needsUpdate = true;
			this.beamGlowMaterial.needsUpdate = true;

			this.icon.rotation.y += 0.01 * this.time.FPSFactor;

			if (this.camera) {
				this.glowUniforms.viewVector.value = this.camera.instance.position;
			}
		}
	};

	private tweenedAlphaHeight = 0;
	private introTweenedAlphaHeight = 0;

	public setHeight = (number: number) => {
		if (!this.beam || !this.isEmitting) return;

		let newHeight = gsap.utils.clamp(0, 0.5, number / 6 - 0.5);
		this.tweenedAlphaHeight += (newHeight - this.tweenedAlphaHeight) * this.easeFactor;
		this.alphaHeight = this.introTweenedAlphaHeight + this.tweenedAlphaHeight;
		this.beamGlow.rotation.y += 0.001 * this.time.FPSFactor;
		this.beamGlow.scale.x = this.beamGlow.scale.z = 1 + this.tweenedAlphaHeight * 0.22;
		this.beam.scale.x = this.beam.scale.z = 1 + this.tweenedAlphaHeight * 0.25;
	};
}
