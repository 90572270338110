import {gsap} from 'gsap';
import {emitter, EVENTS} from '../../../utils/Dispatcher';

import './Intro.scss';
import Tone from 'tone/tone';
import {Globals} from '../../../utils/Globals';
import Sizes from '../../../utils/Sizes';

import IntroLowResPortrait from '../../../../assets/videos/intro-lowres-portrait.mp4';
import IntroLowResLandscape from '../../../../assets/videos/intro-lowres-landscape.mp4';
import IntroHighRes from '../../../../assets/videos/intro-highres.mp4';

import IntroLowResPortraitEnglish from '../../../../assets/videos/intro-lowres-portrait-eng.mp4';
import IntroLowResLandscapeEnglish from '../../../../assets/videos/intro-lowres-landscape-eng.mp4';
import IntroHighResEnglish from '../../../../assets/videos/intro-highres-eng.mp4';

export class Intro {
	private element: HTMLElement;
	private app;

	static SELECTORS = {
		INTRO_TITLE: '.intro-title',
		CTA: '.intro__cta',
		DISCLAIMER: '.intro__disclaimer',
		SKIP_INTRO: '.skip-intro',
		HEADSET_ICON: '.headset-icon'
	};
	private DOMCta: Element;
	private DOMDisclaimer: Element;
	private DOMIntroTitle: Element;
	private DOMGoToExperience: Element;
	private DOMHeadsetIcon: Element;
	private DOMVideo: HTMLVideoElement;
	private subtileTimeline: gsap.core.Timeline;
	private domCues: HTMLDivElement[];
	private domCuesWrapper: HTMLDivElement;
	private sizes: Sizes;

	private cues = [
		{
			text: 'Vi har hele tiden brug for ny viden og nye færdigheder på et arbejdsmarked, der konstant ændrer sig.',
			startTime: 0,
			endTime: 5.48
		},
		{
			text: 'Og det stiller nye krav til den måde, vi uddanner – og efteruddanner - os på.',
			startTime: 6.26,
			endTime: 9.28
		},
		{text: 'Men hvordan sikrer vi, at alle kan arbejde på deltid eller fuldtid', startTime: 10.28, endTime: 13.56},
		{
			text: 'og samtidig uddanne sig på videregående niveau?',
			startTime: 13.98,
			endTime: 16.08
		},
		{
			text: 'Det prøver SDU nu at finde løsninger på som led i et stort projekt, vi kalder Fremtidens Uddannelser.',
			startTime: 16.92,
			endTime: 22.6
		},
		{
			text: 'Her afprøver journalistuddannelsens overbygning, cand.public., en hyperfleksibel kandidatuddannelse.',
			startTime: 23.64,
			endTime: 29.52
		},
		{
			text: 'Eksperimentet tager afsæt i principperne anyone, anywhere og anytime.',
			startTime: 30.18,
			endTime: 34.82
		},
		{
			text: 'Det er et radikalt uddannelseseksperiment, hvor undervisning, vejledning, pensum, eksaminer',
			startTime: 35.64,
			endTime: 41.5
		},
		{text: 'og sociale og individuelle studieformer sættes i spil på helt nye måder.', startTime: 41.5, endTime: 46.38},
		{
			text: 'Vil du vide mere om dette store uddannelsesprojekt,',
			startTime: 47.24,
			endTime: 49.66
		},
		{
			text: 'så bliv her og få en smagsprøve på et anderledes pensum bygget op af nanomoduler.',
			startTime: 49.82,
			endTime: 54.44
		},
		{text: 'Vi vil også meget gerne høre, hvad der skal til for, at du videreuddanner dig i fremtiden?', startTime: 54.9, endTime: 58.9}
	];

	private cuesEnglish = [
		{
			text: 'We are in constant need of new knowledge and skills in an everchanging labour market.',
			startTime: 0,
			endTime: 5.06
		},
		{
			text: 'This places new demands on the way we educate students and retrain ourselves to have a life-long learning perspective.',
			startTime: 5.26,
			endTime: 12.26
		},
		{
			text: 'How do we ensure that everyone can work part or full-time while also part taking in a higher education?',
			startTime: 12.5,
			endTime: 18.24
		},
		{
			text: 'SDU is in the process of finding solutions as part of the large scale project Future Education,',
			startTime: 18.62,
			endTime: 24.48
		},
		{
			text: 'where we are experimenting with creating a hyper flexible education structure for the Master of Journalism School.',
			startTime: 24.76,
			endTime: 30.64
		},
		{
			text: 'The experiment is based on the principles anyone, anywhere and anytime and is radical because everything',
			startTime: 30.84,
			endTime: 36.84
		},
		{
			text: 'from teaching, tutorials, exams, curriculum to social and individual approaches to studying are challenged in entirely new ways.',
			startTime: 36.84,
			endTime: 45.16
		},
		{
			text: 'Want to know more about this grand educational project?',
			startTime: 45.4,
			endTime: 48.2
		},
		{
			text: 'Then explore the site and get a sneak peek into a new curriculum approach based on nano modules.',
			startTime: 48.72,
			endTime: 54.56
		},
		{text: 'We are also curious to hear what it would take for you to keep studying in the future.', startTime: 54.74, endTime: 59.4}
	];

	constructor(app) {
		const {CTA, INTRO_TITLE, DISCLAIMER, SKIP_INTRO, HEADSET_ICON} = Intro.SELECTORS;

		this.element = document.getElementById('intro');
		this.app = app;
		this.sizes = new Sizes();

		this.DOMIntroTitle = this.element.querySelector(INTRO_TITLE);
		this.DOMDisclaimer = this.element.querySelector(DISCLAIMER);
		this.DOMCta = this.element.querySelector(CTA);
		this.DOMHeadsetIcon = this.element.querySelector(HEADSET_ICON);
		this.DOMGoToExperience = this.element.querySelector(SKIP_INTRO);
		this.DOMVideo = this.element.querySelector('video');

		this.setIntroVideo();

		setTimeout(() => {
			this.setupSubtitles();
		}, 3000);

		this.bindEvents();
	}

	private setIntroVideo() {
		if (this.sizes.isTablet && !this.sizes.isPortrait) {
			this.DOMVideo.src = !Globals.IS_ENGLISH ? IntroLowResLandscape : IntroLowResLandscapeEnglish;
		} else if (!this.sizes.isLaptop && this.sizes.isPortrait) {
			this.DOMVideo.src = !Globals.IS_ENGLISH ? IntroLowResPortrait : IntroLowResPortraitEnglish;
		} else if (this.sizes.isLaptop) {
			this.DOMVideo.src = !Globals.IS_ENGLISH ? IntroHighRes : IntroHighResEnglish;
		}
	}

	private setupSubtitles() {
		// if (this.DOMVideo.textTracks.length > 0) {
		// 	const cues = this.DOMVideo.textTracks[0].cues;
		this.subtileTimeline = gsap.timeline({paused: true});
		this.domCues = [];
		this.domCuesWrapper = document.createElement('div');
		this.domCuesWrapper.classList.add('VideoSubtitles');

		// console.log(cues);
		//
		// // @ts-ignore
		// let jsonSubs = [...cues].map((cue) => {
		// 	return {text: cue.text, startTime: cue.startTime, endTime: cue.endTime};
		// });
		// console.log(jsonSubs);

		const cuesCurrent = Globals.IS_ENGLISH ? this.cuesEnglish : this.cues;

		cuesCurrent.forEach(cue => {
			let sub = document.createElement('div');
			sub.innerHTML = `<p>${cue.text}</p>`;
			gsap.set(sub, {opacity: 0});
			this.domCuesWrapper.appendChild(sub);
			this.subtileTimeline.to(sub, {opacity: 1, duration: 0}, cue.startTime);
			this.subtileTimeline.to(sub, {opacity: 0, duration: 0}, cue.endTime);
		});
		/*		this.DOMVideo.textTracks[0].mode = 'disabled';
			this.DOMVideo.removeChild(this.DOMVideo.children[0]);*/
		this.element.appendChild(this.domCuesWrapper);
		this.DOMVideo.addEventListener('timeupdate', () => {
			this.subtileTimeline.time(this.DOMVideo.currentTime);
		});
		// }
	}

	bindEvents() {
		this.DOMCta.addEventListener('click', this.startVideo);
		this.DOMGoToExperience.addEventListener('click', this.skipIntro);
	}

	public animateIn = () => {
		gsap.set(this.DOMCta, {opacity: 1, pointerEvents: ''});
	};

	public animateOut = () => {
		gsap.to(this.element, {
			duration: 0.5,
			opacity: 0,
			onComplete: () => {
				this.element.parentNode.removeChild(this.element);
				// emitter.emit(EVENTS.introEnded);
			}
		});
	};

	private startVideo = event => {
		Tone.context.resume();

		this.DOMCta.removeEventListener('click', this.startVideo);
		this.DOMVideo.addEventListener('ended', this.skipIntro);
		const startIntroTimeline = gsap.timeline();
		startIntroTimeline.set(this.DOMVideo, {transition: 'opacity 1s', opacity: 1}, 0.3);
		gsap.set(this.DOMCta, {transition: 'opacity 0.15s', opacity: 0, cursor: 'default', pointerEvents: 'none'});
		startIntroTimeline.to(this.DOMIntroTitle, {duration: 0.5, autoAlpha: 0}, 0);
		startIntroTimeline.to(this.DOMIntroTitle, {duration: 0.6, scale: 0.7, ease: 'power3.inOut'}, 0);
		startIntroTimeline.to([this.DOMHeadsetIcon, this.DOMDisclaimer], {autoAlpha: 0, duration: 0.3}, 0);
		startIntroTimeline.to(this.DOMHeadsetIcon, {duration: 0.6, y: 100, ease: 'power3.inOut'}, 0);
		startIntroTimeline.to(this.DOMDisclaimer, {duration: 0.6, y: 100, ease: 'power3.inOut'}, 0);
		startIntroTimeline.to(this.DOMGoToExperience, {autoAlpha: 1, duration: 1}, 0);
		this.DOMVideo.play();
		emitter.emit(EVENTS.enterIntro);
	};

	skipIntro = () => {
		this.DOMGoToExperience.removeEventListener('click', this.skipIntro);
		this.DOMVideo.removeEventListener('ended', this.skipIntro);
		gsap.to(this.DOMVideo, {
			volume: 0,
			duration: 0.5,
			ease: 'power3.out',
			onComplete: () => {
				// this.element.parentNode.removeChild(this.element);
				emitter.emit(EVENTS.introEnded);
			}
		});
		gsap.set(this.DOMVideo, {transition: 'opacity 0.5s', opacity: 0});
		gsap.set(this.domCuesWrapper, {opacity: 0});
		gsap.set(this.DOMGoToExperience, {transition: 'none', cursor: 'default'});
		gsap.to(this.DOMGoToExperience, {
			autoAlpha: 0,
			duration: 0.15
		});
	};
}
