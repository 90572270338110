import {gsap} from 'gsap';
import * as THREE from 'three';
import {OrbitControls} from 'three/examples/jsm/controls/OrbitControls';

import {emitter, EVENTS} from '../utils/Dispatcher';
import {Globals} from '../utils/Globals';
import Sizes from '../utils/Sizes';
import {WorldRenderer} from './Renderer';
import {Fog, Scene} from 'three';
import {GUI} from 'dat.gui';

export class Camera {
	public instance: THREE.PerspectiveCamera;
	public target = new THREE.Vector3();
	lerp = new THREE.Vector3();
	lookAt = new THREE.Vector3();
	private targetEased = new THREE.Vector3();
	private angle = {
		value: new THREE.Vector3(),
		items: {
			// intro: new THREE.Vector3(0.198, 0.071, 0.233),
			// intro: new THREE.Vector3(-0.434, 0.02, 1.207),
			// intro: new THREE.Vector3(0.803, 0.046, -0.813),
			intro: new THREE.Vector3(0.45, 0.601, 1.889),
			default: new THREE.Vector3(-1.39, 1.09, 0.41),
			zoomIn: new THREE.Vector3(-1.39, 0.4, 0.41),
			zoomInPortrait: new THREE.Vector3(-1.39, 0.8, 0.41)
			// zoomIn: new THREE.Vector3(-1.39, 0.047, 0.41)
			// zoomIn: new THREE.Vector3(-0.126, 0.047, 0.147)
		}
	};
	public zoom = 25;
	public controls;
	public useControls;
	public debug;
	private sizes = new Sizes();
	private renderer: WorldRenderer;
	private debugFolder: any;
	public tl: gsap.core.Timeline;
	public tlFog: gsap.core.Timeline;
	public percentageTimelines: gsap.core.Timeline[] = [];
	private progress = 0;
	public isTransitioning = true;
	private targetObject: THREE.Mesh;
	private scene: THREE.Scene;
	public slowFactor = 1;
	private lerpFactor = 1;
	private follow = true;
	private positionOffset = new THREE.Vector3(0, 20, 0);
	private far: number;
	private fog: Fog;
	private DOMControlsIntroduction: HTMLElement;

	constructor(renderer: WorldRenderer, scene: Scene, useControls = false, debug: GUI = null, fog: Fog) {
		this.debug = debug;
		this.useControls = useControls;
		this.renderer = renderer;
		this.scene = scene;
		this.fog = fog;
		this.DOMControlsIntroduction = document.getElementById('ControlsIntroduction');
		if (Globals.IS_TOUCH_DEVICE) {
			this.DOMControlsIntroduction.classList.add('touch');
		}

		if (!this.useControls && this.debug) {
			this.debugFolder = this.debug.addFolder('Camera');
		}
		this.angle.value.copy(this.angle.items.intro);

		// Debug
		if (!this.useControls && this.debug) {
			const outFolder = this.debugFolder.addFolder('Zoom out');
			outFolder
				.add(this.angle.value, 'x')
				.step(0.001)
				.min(-2)
				.max(2)
				.name('angle X')
				.listen();
			outFolder
				.add(this.angle.value, 'y')
				.step(0.001)
				.min(-2)
				.max(2)
				.name('angle Y')
				.listen();
			outFolder
				.add(this.angle.value, 'z')
				.step(0.001)
				.min(-2)
				.max(2)
				.name('angle Z')
				.listen();
			outFolder
				.add(this, 'zoom')
				.step(1)
				.min(10)
				.max(300)
				.name('zoom')
				.listen();
		}

		this.init();

		this.tl = gsap.timeline({paused: true});
		this.tlFog = gsap.timeline({paused: true});
		if (!Globals.DEBUG) {
			this.tlFog.fromTo(this.fog, {far: 130}, {far: 110, duration: 1, ease: 'none'}, 0);
			this.tlFog.fromTo(
				this.instance,
				{far: 130},
				{
					far: 110,
					duration: 1,
					ease: 'none',
					onUpdate: () => {
						this.instance.updateProjectionMatrix();
					}
				},
				0
			);
		}
		this.percentageTimelines[0] = this.tlFog;
		this.percentageTimelines[1] = this.tl;
		this.bindEvents();
		this.raf();
		this.resize();
	}

	initHerlper() {
		let helper = new THREE.CameraHelper(this.instance);
		this.scene.add(helper);
	}

	bindEvents() {
		emitter.on(EVENTS.resize, this.resize);
		emitter.on(EVENTS.tick, this.raf);

		if (this.debug && !this.useControls) {
			window.addEventListener('mousewheel', e => {
				// @ts-ignore
				this.zoom += e.deltaY * 0.1;
				this.zoom = Math.min(Math.max(this.zoom, 30), 500);
			});
		}
	}

	/**
	 * Init Camera and controls
	 */
	init() {
		this.far = Globals.ENABLE_FOG ? 130 : 1300;
		this.instance = new THREE.PerspectiveCamera(50, this.sizes.viewport.width / this.sizes.viewport.height, 0.1, this.far);
		this.instance.name = 'Camera';
		this.instance.setFocalLength(80);
		this.scene.add(this.instance);

		if (this.useControls) {
			this.controls = new OrbitControls(this.instance, this.renderer.domElement);
			this.instance.name = 'CameraDebug';
		}

		if (!this.controls) {
			this.initTarget();
		}

		if (this.debug && !this.useControls) {
			// this.initHerlper();
		}
	}

	public experienceIn() {
		this.lerp.copy(this.target);
		const tl = gsap.timeline({
			onComplete: () => {
				this.lerpFactor = 0;
				// this.slowFactor = 0.8;
				gsap.to(this, {duration: 3, lerpFactor: 0.2});
				this.isTransitioning = false;
				Globals.DISABLE_CONTROLS = false;
				gsap.to(this.DOMControlsIntroduction, {duration: 0.5, autoAlpha: 1});
				gsap.fromTo(this.DOMControlsIntroduction, {scale: 0.7}, {duration: 0.6, scale: 1, ease: 'power3.inOut'});
				emitter.on(EVENTS.firstMove, this.firstMoveHandler);
			}
		});
		tl.fromTo(this.fog, {far: 300}, {far: 110, duration: 3, ease: 'sine.inOut'}, 0);
		tl.fromTo(
			this.instance,
			{far: 300},
			{
				far: 110,
				duration: 3,
				ease: 'sine.inOut',
				onUpdate: () => {
					this.instance.updateProjectionMatrix();
				}
			},
			0
		);
		this.angle.value.copy(this.angle.items.intro);
		tl.to(this.positionOffset, {y: -3, duration: 3, ease: 'power3.inOut'}, 0);
		tl.to(this.angle.value, {x: this.angle.items.default.x, y: this.angle.items.default.y, z: this.angle.items.default.z, overwrite: 'auto', duration: 3, ease: 'power2.inOut'}, 0);
		tl.to(this, {zoom: 60, duration: 2, ease: 'sine.inOut'}, 0);
		// tl.pause(0);
		return tl;
	}

	private firstMoveHandler = () => {
		emitter.off(EVENTS.firstMove, this.firstMoveHandler);
		const tl = gsap.timeline();
		tl.to(this.DOMControlsIntroduction, {duration: 0.5, autoAlpha: 0, overwrite: 'auto'}, 2);
		tl.to(
			this.DOMControlsIntroduction,
			{
				duration: 0.6,
				scale: 0.7,
				ease: 'power3.inOut',
				overwrite: 'auto',
				onComplete: () => {
					this.DOMControlsIntroduction.parentNode.removeChild(this.DOMControlsIntroduction);
				}
			},
			2
		);
		tl.to(this.fog, {far: 130, duration: 2, ease: 'sine.inOut'}, 0);
		tl.to(
			this.instance,
			{
				far: 130,
				duration: 2,
				ease: 'sine.inOut',
				onUpdate: () => {
					this.instance.updateProjectionMatrix();
				}
			},
			0
		);
		tl.to(this.positionOffset, {y: 2, duration: 2, ease: 'sine.inOut'}, 0);
		tl.to(this, {zoom: 100, duration: 2, ease: 'sine.inOut'}, 0);
	};

	initTarget() {
		this.targetObject = new THREE.Mesh(new THREE.SphereBufferGeometry(0.1), new THREE.MeshBasicMaterial({visible: Globals.DEBUG}));
		this.scene.add(this.targetObject);
	}

	/**
	 * Resize callback
	 */
	private resize = () => {
		this.instance.aspect = this.sizes.viewport.width / this.sizes.viewport.height;
		this.instance.setFocalLength(80);
		this.instance.updateProjectionMatrix();
		let progress = this.tl.progress();
		this.tl.kill();
		this.tl = gsap.timeline({paused: true});
		if (this.sizes.isPortrait) {
			this.tl.fromTo(
				this.angle.value,
				{x: this.angle.items.default.x, y: this.angle.items.default.y, z: this.angle.items.default.z},
				{
					x: this.angle.items.zoomInPortrait.x,
					y: this.angle.items.zoomInPortrait.y,
					z: this.angle.items.zoomInPortrait.z,
					duration: 1,
					ease: 'none'
				},
				0
			);
		} else {
			this.tl.fromTo(
				this.angle.value,
				{x: this.angle.items.default.x, y: this.angle.items.default.y, z: this.angle.items.default.z},
				{
					x: this.angle.items.zoomIn.x,
					y: this.angle.items.zoomIn.y,
					z: this.angle.items.zoomIn.z,
					duration: 1,
					ease: 'none'
				},
				0
			);
		}
		this.tl.fromTo(this, {zoom: 100}, {zoom: 80, duration: 1, overwrite: 'auto', ease: 'sine.inOut'}, 0);
		this.tl.progress(progress);
		this.percentageTimelines[1] = this.tl;
	};

	private angleValueCopy = new THREE.Vector3();
	/**
	 * RAF callback
	 */
	private raf = () => {
		if (!this.controls) {
			this.lerp.x += (this.target.x - this.lerp.x) * this.lerpFactor * this.slowFactor;
			this.lerp.y += (this.target.y - this.lerp.y) * this.lerpFactor * this.slowFactor;
			this.lerp.z += (this.target.z - this.lerp.z) * this.lerpFactor * this.slowFactor;

			// console.log(this.target);
			this.targetObject.position.copy(this.lerp).add(this.positionOffset);
			this.lookAt.copy(this.targetObject.position);

			this.angleValueCopy
				.copy(this.angle.value)
				.normalize()
				.multiplyScalar(this.zoom);

			if (this.follow) {
				this.instance.position.copy(this.targetObject.position).add(this.angleValueCopy);
			}

			this.instance.lookAt(this.lookAt);
		}
	};
}
