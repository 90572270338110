import {gsap} from 'gsap';
import * as THREE from 'three';
import {Mesh, Object3D, Scene} from 'three';
import Time from '../utils/Time';
import Particle from './Particle';
import {Ufo} from './Ufo';
import {clamp} from 'gsap/gsap-core';
import {Globals} from '../utils/Globals';

class SmokeParticles {
	private emitterPoint: THREE.Object3D;
	private scene: THREE.Scene;
	private time: Time;
	private particles: Particle[] = [];
	public isActive: Boolean;

	private params = {
		count: 20,
		duration: 1,
		delay: 1
	};
	private _jetParticle: Mesh;
	private worldPosition = new THREE.Vector3();

	private poolPosition = 0;
	private emitterPointRotation = new THREE.Quaternion();
	private _ufo: Ufo;
	private currParticleSpeed = 0;
	private smokeMaterial = new THREE.MeshBasicMaterial({color: 0xc7dfff, transparent: true, depthWrite: false});

	private dummyObjects: THREE.Object3D[] = [];
	private mesh: THREE.InstancedMesh;
	private opacityAttribute: THREE.InstancedBufferAttribute;

	constructor(scene: Scene, jetParticle: Mesh, emitterPoint: Object3D, ufo: Ufo) {
		this.emitterPoint = emitterPoint;
		this.scene = scene;
		this._jetParticle = jetParticle;
		this._ufo = ufo;
		this.time = new Time();
		this.isActive = false;

		var colorParsChunk = ['attribute float instanceOpacity;', 'varying float vInstanceOpacity;', '#include <common>'].join('\n');

		var instanceOpacityChunk = ['#include <begin_vertex>', '\tvInstanceOpacity = instanceOpacity;'].join('\n');

		var fragmentParsChunk = ['varying float vInstanceOpacity;', '#include <common>'].join('\n');

		var colorChunk = ['vec4 diffuseColor = vec4( diffuse, opacity * vInstanceOpacity );'].join('\n');

		this.smokeMaterial.onBeforeCompile = function(shader) {
			shader.vertexShader = shader.vertexShader.replace('#include <common>', colorParsChunk).replace('#include <begin_vertex>', instanceOpacityChunk);

			shader.fragmentShader = shader.fragmentShader.replace('#include <common>', fragmentParsChunk).replace('vec4 diffuseColor = vec4( diffuse, opacity );', colorChunk);

			//console.log( shader.uniforms );
			//console.log( shader.vertexShader );
			//console.log( shader.fragmentShader );
		};
		//@ts-ignore
		// this.smokeMaterial.extensions = {fragDepth: true, drawBuffers: true};
		// this.smokeMaterial.precision = 'mediump'; // Fixes smoke color on ios, but fucks up everything else.

		this.generate();
		this.emit();
	}

	private generate() {
		let instanceOpacities: number[] = [];
		for (let i = 0; i < this.params.count; i++) {
			let dummyObject = new THREE.Object3D();
			dummyObject.position.copy(this.worldPosition);
			dummyObject.scale.copy(this._jetParticle.scale);
			this.dummyObjects.push(dummyObject);
			let particle = new Particle(dummyObject);
			instanceOpacities.push(particle.opacity);
			this.particles.push(particle);
		}
		this.opacityAttribute = new THREE.InstancedBufferAttribute(new Float32Array(instanceOpacities), 1);
		(this._jetParticle.geometry as THREE.BufferGeometry).setAttribute('instanceOpacity', this.opacityAttribute);

		this.mesh = new THREE.InstancedMesh(this._jetParticle.geometry, this.smokeMaterial, this.params.count);
		this.mesh.instanceMatrix.setUsage(THREE.DynamicDrawUsage); // will be updated every frame
		this.scene.add(this.mesh);
	}

	private rRange = (min, max) => {
		return Math.random() * (max - min) + min;
	};

	public startEmit = () => {
		this.isActive = true;
		this.emit();
	};

	public stopEmit = () => {
		this.isActive = false;
	};

	private updateItem: Particle = null;
	public updateRaf = () => {
		for (let i = 0; i < this.particles.length; i++) {
			this.updateItem = this.particles[i];
			if (!this.updateItem.isDead) {
				this.updateItem.object.updateMatrix();
				this.opacityAttribute.setX(i, this.updateItem.opacity);
				this.mesh.setMatrixAt(i, this.updateItem.object.matrix);
			}
		}
		this.opacityAttribute.needsUpdate = true;
		this.mesh.instanceMatrix.needsUpdate = true;
	};

	public emit = () => {
		if (!this.isActive) return;
		Globals.AUDIO_ENGINE.triggerParticle();

		// if(!this.isActive) return

		// let index = Math.floor(Math.random() * this.params.count);
		this.emitterPoint.getWorldPosition(this.worldPosition);
		this.emitterPoint.getWorldQuaternion(this.emitterPointRotation);

		this.particles[this.poolPosition].emit(
			this.emitterPointRotation,
			this.poolPosition,
			this.worldPosition,
			this.worldPosition.x + this.rRange(-0.5, 0.6),
			this.worldPosition.z + this.rRange(-0.5, 0.6)
		);
		this.currParticleSpeed = clamp(0.05, 1, 0.3 - this._ufo.currSpeed) * this.params.delay;
		// console.log(speed);
		gsap.delayedCall(this.currParticleSpeed, this.emit);
		this.poolPosition++;
		if (this.poolPosition >= this.params.count) {
			this.poolPosition = 0;
		}
	};
}

export default SmokeParticles;
